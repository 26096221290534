import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import { Container } from "../const";
import { QuestionCheckbox, QuestionTextField } from "../../components";
import { AdaptabilityType, SpouseRelativeType } from "../../../../common/types";
import { getErrorProps } from "../../utils";

const SpouseRelativeForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<AdaptabilityType>) => {
  const value = rawValue as SpouseRelativeType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.haveRelativeInCanada]);

  const haveRelative = value?.haveRelativeInCanada ?? null;

  return (
    <Container>
      <QuestionCheckbox<SpouseRelativeType>
        value={value?.haveRelativeInCanada ?? null}
        setValue={(haveRelativeInCanada) =>
          setValue({ ...value, haveRelativeInCanada })
        }
        question={{
          display: "Do you or your spouse have a relative in Canada?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.haveRelativeInCanada,
        })}
      />
      {haveRelative === "Yes" && (
        <>
          <QuestionCheckbox<SpouseRelativeType>
            value={value?.isRelativePr ?? null}
            setValue={(isRelativePr) => setValue({ ...value, isRelativePr })}
            question={{
              display:
                "Is your relative a permanent resident or citizen in Canada?",
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.isRelativePr,
            })}
          />
          <QuestionTextField<SpouseRelativeType>
            value={value?.relationshipWithTheRelative ?? null}
            setValue={(relationshipWithTheRelative) =>
              setValue({ ...value, relationshipWithTheRelative })
            }
            question={{
              display: "What is your relationship with the relative?",
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.relationshipWithTheRelative,
            })}
          />
        </>
      )}
    </Container>
  );

  function isFormValid(value: SpouseRelativeType) {
    if (!haveRelative) {
      return false;
    }

    if (haveRelative === "Yes") {
      const isRelativePR = value?.isRelativePr ?? null;
      const relationShipWithRelative = value?.relationshipWithTheRelative ?? "";
      return isRelativePR !== null && relationShipWithRelative?.trim() != "";
    } else {
      return true;
    }
  }

  function reformatForm(form: SpouseRelativeType) {
    if (form && haveRelative) {
      if (haveRelative === "Yes") {
        return form;
      } else {
        const { haveRelativeInCanada } = form;
        return { haveRelativeInCanada } as SpouseRelativeType;
      }
    }

    return form;
  }
};

export default SpouseRelativeForm;
