import { useEffect } from "react";

export function useScreenResize({
  handleResize,
  dependencies = [],
}: {
  handleResize: () => void;
  dependencies?: number[];
}) {
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, dependencies);
}
