import styled from "@emotion/styled";
import "./User.scss";
import { Box, TextField, Typography } from "@mui/material";

interface prop {
  children?: React.ReactNode;
  list: string; // Can be change to list once there is already data from BE
  type?: string;
}

const UserDetails = (props: prop) => {
  const { children, list, type, ...other } = props;

  return (
    <div className="detail-container">
      <div className="content">
        <Typography className="info-title" variant="subtitle1">
          {props.list}
        </Typography>
        {type === "personal" && (
          <>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography variant="subtitle1">First name</Typography>
              <TextField
                className="firstName"
                value={"Maria Christina"}
              ></TextField>

              <Typography variant="subtitle1">Last name </Typography>
              <TextField className="lastName" value={"Rondina"}></TextField>

              <Typography variant="subtitle1">Citizenship</Typography>
              <TextField className="citizenship" value={"Filipino"}></TextField>

              <Typography variant="subtitle1">Contact Number</Typography>
              <TextField className="number" value={"0987654321"}></TextField>

              <Typography variant="subtitle1">Email</Typography>
              <TextField
                className="email"
                value={"cristinamariarondina@gmail.com"}
              ></TextField>

              <Typography variant="subtitle1">Birthday</Typography>
              <TextField
                className="birthday"
                value={"March 20, 1993"}
              ></TextField>

              <Typography variant="subtitle1">Complete Home Address</Typography>
              <TextField
                className="home"
                value={"Compostela Cebu Philippines"}
              ></TextField>

              <Typography variant="subtitle1">Marital Status</Typography>
              <TextField className="marital" value={"Single"}></TextField>
            </Box>
          </>
        )}

        {type === "education" && (
          <>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography variant="subtitle1">
                Highest Educational attainment
              </Typography>
              <TextField
                className="degree"
                value={"Bachelor's Degree"}
              ></TextField>

              <Typography variant="subtitle1">
                Program/Course graduated
              </Typography>
              <TextField
                className="program"
                value={"Bachelor in Science Major in Information Technology"}
              ></TextField>

              <Typography variant="subtitle1">Year</Typography>
              <TextField
                className="year-taken"
                value={"2014 -2018"}
              ></TextField>
            </Box>
          </>
        )}

        {type === "work" && (
          <>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography variant="subtitle1">Employer</Typography>
              <TextField className="employer" value={"Fasttrack"}></TextField>

              <Typography variant="subtitle1">
                Program/Course graduated
              </Typography>
              <TextField
                className="program"
                value={"Software Engineer"}
              ></TextField>

              <Typography variant="subtitle1">Year</Typography>
              <TextField
                className="year-taken"
                value={"2014 -2018"}
              ></TextField>

              <Typography variant="subtitle1">
                Duties and responsibilities
              </Typography>
              <TextField
                className="year-taken"
                value={"Developing and Testing"}
              ></TextField>
            </Box>
          </>
        )}

        {type === "trade-cert" && (
          <>
            <Typography variant="subtitle1">Coming Soon...</Typography>
          </>
        )}

        {type === "employment-cert" && (
          <>
            <Typography variant="subtitle1">Coming Soon...</Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
