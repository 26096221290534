import { Typography } from "@mui/material";
import { IMAGES, OfficeType, UserSideMenu } from "../../../common";
import "./OfficeInformation.scss";
import { officeList } from "../../../common/const/offices";
import { useEffect, useState } from "react";

interface Props {
  selectedMenu?: UserSideMenu;
}

const OfficeInformation = ({ selectedMenu }: Props) => {
  const [selectedItem, setSelectedItem] = useState<OfficeType | undefined>();

  const findItemById = (id: UserSideMenu) => {
    const item = officeList.find((item) => item.label === id);
    setSelectedItem(item);
  };

  const checkImage = (selectedMenu?: UserSideMenu) => {
    switch (selectedMenu) {
      case UserSideMenu.CEBU:
        return IMAGES.cebu;
      case UserSideMenu.MAKATI:
        return IMAGES.makati;
      case UserSideMenu.DUMAGUETE:
        return IMAGES.dumaguete;
      case UserSideMenu.ILOILO:
        return IMAGES.iloilo;
      case UserSideMenu.THAILAND:
        return IMAGES.thailand;
      case UserSideMenu.CANADA:
        return IMAGES.canada;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (selectedMenu) {
      findItemById(selectedMenu);
    }
  }, [selectedMenu]);

  return (
    <div className="office-center-container">
      <div className="office-flex-content">
        {selectedItem && (
          <>
            <div className="office-title">
              <Typography className="title" variant="h6">
                Contact Us
              </Typography>
              <Typography className="sub-title" variant="h4">
                How can we help you?
              </Typography>
            </div>
            <div className="image-content">
              <img
                className="officeImage"
                src={checkImage(selectedMenu)}
                alt="about"
              />
            </div>
            <div className="info-content">
              <Typography className="title" variant="h6">
                Address
              </Typography>
              <Typography className="subtitle" variant="body2">
                {selectedItem.name}
              </Typography>
              <Typography className="subtitle" variant="body2">
                {selectedItem.address}
              </Typography>

              {selectedItem.telephoneNumber !== "" && (
                <>
                  <Typography className="title" variant="h6">
                    Telephone Number
                  </Typography>
                  <Typography className="subtitle" variant="body2">
                    {selectedItem.telephoneNumber}
                  </Typography>
                </>
              )}

              <Typography className="title" variant="h6">
                Contact Number
              </Typography>
              <Typography className="subtitle" variant="body2">
                {selectedItem.contactNumber}
              </Typography>

              <Typography className="title" variant="h6">
                Email
              </Typography>
              <Typography className="subtitle" variant="body2">
                {selectedItem.email}
              </Typography>

              <Typography className="title" variant="h6">
                Office Hours
              </Typography>
              <Typography className="subtitle" variant="body2">
                {selectedItem.officeHours}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OfficeInformation;
