import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserDetails from "./UserDetails";
import CustomTabPanel from "./CustomTabPanel";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserBoard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Personal" {...a11yProps(0)} />
          <Tab label="Education" {...a11yProps(1)} />
          <Tab label="Work Experience" {...a11yProps(2)} />
          <Tab label="Upload Trade Certificates" {...a11yProps(3)} />
          <Tab label="Upload Certificate of Employments" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserDetails type="personal" list="Personal Details" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserDetails type="education" list="Education Details" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UserDetails type="work" list="Work Experience" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <UserDetails type="trade-cert" list=" Upload Trade Certificates" />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <UserDetails
          type="employment-cert"
          list="Upload Certificate of Employments"
        />
      </CustomTabPanel>
    </Box>
  );
}
