import { ComponentProps } from "../../../components/types";
import React, { useEffect } from "react";
import { QuestionDropdown } from "../../../components";
import styled from "@emotion/styled";
import DegreeProgram from "./DegreeProgram";
import DegreesDisplay from "./DegreesDisplay";
import {
  EducationType,
  ProgramGraduatedType,
  ProgramType,
} from "../../../../../common/types";
import { getErrorProps } from "../../../utils";

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

interface Props {
  options: string[];
  showVocational: ((educationAttainment: string | null) => boolean) | null;
  showBachelor: (educationAttainment: string | null) => boolean;
  showMasters: (educationAttainment: string | null) => boolean;
  showDoctoral: (educationAttainment: string | null) => boolean;
}

const DegreePrograms = ({
  value: rawValue,
  setValue,
  setIsInvalid,
  options,
  showVocational,
  showBachelor,
  showMasters,
  showDoctoral,
}: ComponentProps<EducationType> & Props) => {
  const value = rawValue as ProgramGraduatedType;
  const educationAttainment = value?.educationAttainment ?? null;

  const isShowVocational = showVocational
    ? showVocational(educationAttainment)
    : false;
  const isShowBachelor = showBachelor(educationAttainment);
  const isShowMasters = showMasters(educationAttainment);
  const isShowDoctoral = showDoctoral(educationAttainment);

  const degreeOptions = getDegreeOptions();

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);
      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.educationAttainment]);

  return (
    <Container>
      <QuestionDropdown<ProgramGraduatedType>
        value={value?.educationAttainment}
        setValue={(educationAttainment) =>
          educationAttainment &&
          setValue({
            ...value,
            educationAttainment: educationAttainment.toString(),
          })
        }
        question={{
          display: "Highest Educational Attainment",
          meta: {
            options: options.map((option) => ({
              label: option,
              value: option,
            })),
          },
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.educationAttainment,
        })}
      />
      {degreeOptions && (
        <>
          <DegreeProgram
            degreeOptions={degreeOptions}
            value={value?.degrees ?? []}
            setValue={(degrees) => setValue({ ...value, degrees })}
          />
          {value?.degrees && value?.degrees?.length !== 0 && (
            <DegreesDisplay
              degrees={value.degrees}
              removeDegree={removeDegree}
              updateDegreeProgram={updateDegreeProgram}
              degreeOptions={degreeOptions}
            />
          )}
        </>
      )}
    </Container>
  );

  function isFormValid(value: ProgramGraduatedType) {
    if (!educationAttainment) {
      return false;
    }

    const degrees = value?.degrees ?? [];

    if (isShowDoctoral) {
      return degrees.some(({ degree }) => degree === `Doctoral's Degree`);
    }

    if (isShowMasters) {
      return degrees.some(({ degree }) => degree === `Master's Degree`);
    }

    if (isShowBachelor) {
      return degrees.some(({ degree }) => degree === `Bachelor's Degree`);
    }

    if (isShowVocational) {
      return degrees.some(({ degree }) => degree === "Vocational");
    }

    return true;
  }

  function reformatForm(form: ProgramGraduatedType): ProgramGraduatedType {
    if (form && educationAttainment) {
      const updatedDegrees = updateDegreesBySelectedDegree();

      return { ...form, degrees: updatedDegrees };
    }

    return form;
  }

  function getDegreeOptions() {
    if (isShowVocational) {
      return ["Vocational"];
    } else if (isShowBachelor) {
      return [`Bachelor's Degree`];
    } else if (isShowMasters) {
      return [`Bachelor's Degree`, `Master's Degree`];
    } else if (isShowDoctoral) {
      return [`Bachelor's Degree`, `Master's Degree`, `Doctoral's Degree`];
    } else {
      return null;
    }
  }

  function updateDegreesBySelectedDegree() {
    const degrees = value?.degrees ?? [];

    if (degrees.length === 0) {
      return null;
    }

    const options = getDegreeOptions() ?? [];

    return degrees.filter(({ degree }) => options.includes(degree ?? ""));
  }

  function removeDegree(toRemove: ProgramType) {
    const degrees = value?.degrees ?? [];

    setValue({
      ...value,
      degrees: degrees.filter((degree) => degree !== toRemove),
    });
  }

  function updateDegreeProgram(updated: ProgramType, index: number) {
    const degrees = value?.degrees ?? [];
    degrees[index] = updated;

    setValue({
      ...value,
      degrees,
    });
  }
};

export default DegreePrograms;
