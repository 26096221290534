export enum SideMenu {
  SYSTEM_USERS = "System Users",
  APPLICANTS = "Applicants",
  STATES = "States",
  TRADE_SKILLS = "Trade Skills",
}

export enum UserSideMenu {
  PROFILE = "Profile",
  SERVICES = "Services",
  OFFICES = "Offices",
  CEBU = "Cebu",
  MAKATI = "Makati",
  DUMAGUETE = "Dumaguete",
  ILOILO = "Iloilo",
  THAILAND = "Thailand",
  CANADA = "Canada",
}
