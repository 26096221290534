import React from "react";
import "./style/common.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Home, Login } from "./pages";
import { Dashboard } from "./pages/dashboard";
import { UserProfile } from "./pages/UserProfile";

import {
  AlertProvider,
  ErrorBoundary,
  LoadingProvider,
  StatesProvider,
  SurveyFormProvider,
} from "./common";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Survey, { ThankYouPage } from "./pages/survey";
import { AllProfessionals } from "./pages/allProfessionals";
import Overview from "./pages/survey/groups/Overview";

const materialTheme = materialExtendTheme();

function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider>
        <CssBaseline enableColorScheme />
        <ErrorBoundary>
          <LoadingProvider>
            <AlertProvider>
              <StatesProvider>
                <BrowserRouter>
                  <Routes>
                    <Route index element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/allProfessionals"
                      element={<AllProfessionals />}
                    />
                    <Route path="/survey/overview" element={<Overview />} />
                    <Route
                      path="/survey"
                      element={
                        <SurveyFormProvider>
                          <Survey />
                        </SurveyFormProvider>
                      }
                    />
                    <Route path="/thank-you-page" element={<ThankYouPage />} />
                    <Route path="/dashboard/:menu" element={<Dashboard />} />
                    <Route path="/UserProfile" element={<UserProfile />} />
                    <Route
                      path="/UserProfile/:menu"
                      element={<UserProfile />}
                    />
                    <Route
                      path="/dashboard/"
                      element={
                        <Navigate to="/dashboard/System%20Users" replace />
                      }
                    />
                    <Route
                      path="/not-found"
                      element={<div>Add not found page</div>}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/not-found" replace />}
                    />
                    {/* Add future routes here */}
                  </Routes>
                </BrowserRouter>
              </StatesProvider>
            </AlertProvider>
          </LoadingProvider>
        </ErrorBoundary>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
