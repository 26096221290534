import { ComponentProps } from "./types";
import TextField from "@mui/material/TextField";
import { Container } from "./const";
import { TextFieldProps, Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledContainer = styled(Container)`
  .MuiInputBase-input {
    font-size: 20px;
  }
`;

const QuestionTextField = <T,>({
  value,
  setValue,
  question,
  componentVariant = "individual",
  ...props
}: ComponentProps<T> & TextFieldProps) => {
  const { display } = question;

  return (
    <StyledContainer variant={componentVariant}>
      <Typography variant="h5">{display}</Typography>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value as T[keyof T])}
        fullWidth
        {...props}
      />
    </StyledContainer>
  );
};

export default QuestionTextField;
