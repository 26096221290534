import { ComponentProps } from "../../../components/types";
import {
  PersonalType,
  TradeSkillType,
  TradeStateType,
} from "../../../../../common/types";
import Typography from "@mui/material/Typography";
import { Container } from "../../const";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import TradeSkillModal from "./TradeSkillModal";
import Chip from "@mui/material/Chip";
import { ErrorText, useSurveyForm } from "../../../../../common";
import styled from "@emotion/styled";
import groupBy from "lodash.groupby";
import Paper from "@mui/material/Paper";
import OtherTradeSkillModal from "./OtherTradeSkillModal";
import uniq from "lodash.uniq";
import uniqBy from "lodash.uniqby";

const TradeSkillsContainer = styled.div`
  display: flex;
  column-gap: calc(2 * var(--unit));
  row-gap: var(--unit);
  flex-wrap: wrap;

  .MuiChip-root {
    height: unset;
  }

  .MuiChip-label {
    overflow: unset;
    text-overflow: unset;
    text-wrap: wrap;
  }
`;

const StyledChip = styled(Chip)`
  font-size: 20px;
`;

const StyledPaper = styled(Paper)`
  padding: var(--unit);
  margin-top: calc(2 * var(--unit));
`;

const TitleText = styled(Typography)`
  color: #2d98da;
`;

const AddButton = styled(Button)`
  align-self: center;
  margin-top: calc(2 * var(--unit));
`;

const initialValue: TradeStateType = {
  stateId: -1,
  tradeSkillId: -1,
};

const TradeSkillForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
  question,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as TradeSkillType;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openOtherSkillModal, setOpenOtherSkillModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  const skills = value?.skills ?? [];
  const groupSkillsByState = groupBy(skills, "stateId");

  const { states: allStates, tradeSkills: allTradeSkills } = useSurveyForm();

  return (
    <Container>
      <Typography variant="h5">{question.display}</Typography>
      <AddButton
        onClick={() => setOpenModal(true)}
        size="large"
        variant="contained"
      >
        Add Trade Skill
      </AddButton>
      {value?.skills?.length === 0 && value?.otherSkills?.length === 0 && (
        <ErrorText helperText="Add atleast one trade skill." />
      )}
      {openModal && (
        <TradeSkillModal
          onSave={(skill) => {
            const skills = value?.skills ?? [];
            skills.push(skill);
            setValue({ ...value, skills: uniqBy(skills, "tradeSkillId") });
          }}
          handleCloseModal={() => setOpenModal(false)}
          variant="Add"
          initialValue={initialValue}
          openOtherSkills={() => {
            setOpenModal(false);
            setOpenOtherSkillModal(true);
          }}
        />
      )}
      {openOtherSkillModal && (
        <OtherTradeSkillModal
          handleCloseModal={() => setOpenOtherSkillModal(false)}
          onSave={(otherSkill) => {
            const otherSkills = value?.otherSkills ?? [];
            otherSkills.push(otherSkill);
            setValue({ ...value, otherSkills: uniq(otherSkills) });
          }}
          variant="Add"
          initialValue=""
        />
      )}
      {Object.entries(groupSkillsByState).map(([stateGiven, tradeSkills]) => {
        const stateName =
          allStates.find((state) => {
            return state.id === Number(stateGiven);
          })?.name ?? "";

        return (
          <StyledPaper variant="outlined">
            <TitleText fontWeight={700}>{stateName}</TitleText>
            <TradeSkillsContainer>
              {tradeSkills.map((trade) => {
                const tradeSkill = allTradeSkills.find(
                  (tradeSkill) => tradeSkill.id === trade.tradeSkillId,
                );

                return tradeSkill ? (
                  <StyledChip
                    size="medium"
                    label={tradeSkill.name}
                    variant="outlined"
                    onDelete={() => removeSkill(trade)}
                  />
                ) : null;
              })}
            </TradeSkillsContainer>
          </StyledPaper>
        );
      })}
      {value?.otherSkills && value?.otherSkills.length !== 0 && (
        <StyledPaper variant="outlined">
          <TitleText fontWeight={700}>Others</TitleText>
          <TradeSkillsContainer>
            {value.otherSkills.map((skill) => (
              <StyledChip
                size="medium"
                label={skill}
                variant="outlined"
                onDelete={() => removeOtherSkill(skill)}
              />
            ))}
          </TradeSkillsContainer>
        </StyledPaper>
      )}
    </Container>
  );

  function isFormValid(value: TradeSkillType) {
    const { skills, otherSkills } = value;

    return (
      (skills && skills?.length !== 0) ||
      (otherSkills && otherSkills?.length !== 0)
    );
  }

  function removeSkill(toRemove: TradeStateType) {
    const skills = value?.skills ?? [];

    setValue({
      ...value,
      skills: skills.filter((skill) => skill !== toRemove),
    });
  }

  function removeOtherSkill(toRemove: string) {
    const otherSkills = value?.otherSkills ?? [];

    setValue({
      ...value,
      otherSkills: otherSkills.filter((skill) => skill !== toRemove),
    });
  }
};

export default TradeSkillForm;
