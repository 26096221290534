import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Container } from "../../groups/AccountInformation/const";
import { useEffect } from "react";
import { ComponentProps } from "../../components/types";
import { AccountInformationType } from "../../../../common/types";
import { useSurveyForm } from "../../../../common";
import { Typography } from "@mui/material";
import { getErrorProps, isFieldEmpty } from "../../utils";

const ConfirmEmailForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<AccountInformationType>) => {
  const value = rawValue as string;
  const { form } = useSurveyForm();

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  return (
    <Container>
      <Grid container display="flex" flexDirection="column" rowGap={2}>
        <Typography variant="h6">
          Please input the code sent to{" "}
          <b>{form.accountInformation.information?.confirmEmail}</b>
        </Typography>
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...getErrorProps({ value })}
        />
      </Grid>
    </Container>
  );

  function isFormValid(value: string) {
    return !isFieldEmpty(value);
  }
};

export default ConfirmEmailForm;
