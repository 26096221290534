import { CardType } from "../types/types";
import IMAGES from "../../common/const/images";

export const goalList: CardType[] = [
  {
    id: 1,
    name: "Professional Licensing",
    subName: "L",
    description:
      "We process Canada licensing of nurses, engineers, and teachers in any Canadian province of your choice. Our program is designed to guide and assist you in getting registered with the appropriate regulatory body, ensuring that you are qualified to practice your profession in Canada.",
    image: IMAGES.certificate,
    url: "",
  },
  {
    id: 2,
    name: "Permanent Residency Assessment",
    subName: "PR",
    description:
      "We evaluate your qualification for an immigrant visa to Canada. Factors considered for the assessment include your age, language proficiency, education credentials, adaptability or ties in Canada, and work experience",
    image: IMAGES.paper,
    url: "",
  },
  {
    id: 3,
    name: "Manpower Pooling",
    subName: "TS",
    description:
      "We give opportunities to skilled tradespeople across the globe to match with a Canadian employer and work in a skilled trades profession.",
    image: IMAGES.skills,
    url: "",
  },
];
