import { useState } from "react";
import MainView from "./MainView";
import { WorkExperienceType } from "../../../../common/types";
import { useSurveyForm } from "../../../../common";

interface Props {
  initForm: WorkExperienceType;
  onPrevGroup: () => void;
  onNextGroup: () => void;
  prevGroupName: string;
}

const WorkExperience = ({
  initForm,
  onPrevGroup,
  onNextGroup,
  prevGroupName,
}: Props) => {
  const { form: surveyForm, setForm: setSurveyForm } = useSurveyForm();

  const [workExperienceForm, setWorkExperienceForm] =
    useState<WorkExperienceType>(initForm);

  const addToWorkExperienceForm = ({
    key,
    value,
  }: {
    key: string;
    value: WorkExperienceType[keyof WorkExperienceType];
  }) => {
    const updatedForm = value
      ? {
          ...workExperienceForm,
          [key]: value,
        }
      : { ...workExperienceForm };

    setWorkExperienceForm(updatedForm);
    setSurveyForm({
      ...surveyForm,
      workExperience: updatedForm,
    });
  };

  return (
    <MainView
      addToForm={addToWorkExperienceForm}
      initForm={workExperienceForm}
      onPrevView={onPrevGroup}
      onNextView={onNextGroup}
      prevGroupName={prevGroupName}
    />
  );
};

export default WorkExperience;
