import { QuestionType } from "../../types";
import { QuestionComponentType } from "../../enum";
import {
  CommonLawEducationType,
  EducationalCredentialType,
  EducationType,
  PartialRecord,
  ProgramGraduatedType,
  ProgramType,
} from "../../../../common/types";
import {
  CommonLawEducationForm,
  EducationalCredentialForm,
  HighestEducationAttainmentForm,
  ProgramGraduatedForm,
} from "../../forms";
import { Typography } from "@mui/material";
import { formatDate } from "../../utils";
import styled from "@emotion/styled";

type EducationQuestionType = {
  inTrades: PartialRecord<keyof EducationType, QuestionType>;
  notInTradesAndInCanada: PartialRecord<keyof EducationType, QuestionType>;
  notInTradesAndNotInCanada: PartialRecord<keyof EducationType, QuestionType>;
};

const DegreeContainer = styled.div`
  margin-top: calc(2 * var(--unit));
`;

export const educationQuestions: EducationQuestionType = {
  inTrades: {
    programGraduated: {
      display: "Program(s) Graduated",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: ProgramGraduatedForm,
        displayAnswer: (form: ProgramGraduatedType) => {
          const { educationAttainment, degrees } = form;

          return (
            <div>
              <Typography>
                Highest Educational Attainment: {educationAttainment}
              </Typography>
              {degrees &&
                degrees.map((degree, i) => (
                  <DisplayDegree degreeProgram={degree} num={i} />
                ))}
            </div>
          );
        },
      },
    },
  },
  notInTradesAndInCanada: {
    highestEducationAttainment: {
      display: "Educational Attainment",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: HighestEducationAttainmentForm,
        displayAnswer: (form: ProgramGraduatedType) => {
          const { educationAttainment, degrees } = form;

          return (
            <>
              <Typography>
                Highest Educational Attainment: {educationAttainment}
              </Typography>
              {degrees &&
                degrees.map((degree, i) => (
                  <DisplayDegree degreeProgram={degree} num={i} />
                ))}
            </>
          );
        },
      },
    },
    cwpStudy: {
      display: "Common-law Study",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: CommonLawEducationForm,
        displayAnswer: (form: CommonLawEducationType) => {
          const {
            studiedInCanada,
            currentProvince,
            canadianSchool,
            programGraduated,
            durationOfStudy,
            levelOfStudy,
            completionDateOfProgram,
          } = form;

          return (
            <>
              <Typography>Studied in canada: {studiedInCanada}</Typography>
              {studiedInCanada === "Yes" && (
                <>
                  <Typography>Current Province: {currentProvince}</Typography>
                  <Typography>
                    Canadian School Name: {canadianSchool}
                  </Typography>
                  <Typography>
                    Program/Course graduated: {programGraduated}
                  </Typography>
                  <Typography>Duration of Study: {durationOfStudy}</Typography>
                  <Typography>Level of study: {levelOfStudy}</Typography>
                  <Typography>
                    Completion Date of Program: {completionDateOfProgram}
                  </Typography>
                </>
              )}
            </>
          );
        },
      },
    },
    requestedEducationalCredential: {
      display: "Educational Credential",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: EducationalCredentialForm,
        displayAnswer: (form: EducationalCredentialType) => {
          const { isAccredited, equivalent } = form;

          return (
            <>
              <Typography>
                Have you requested for an Educational Credential Assessment from
                an accredited organization such as WES and ICAS?: {isAccredited}
              </Typography>
              {isAccredited === "Yes" && (
                <Typography>
                  What is your education's equivalent in Canada per the
                  Educational Credential Assessment Report?: {equivalent}
                </Typography>
              )}
            </>
          );
        },
      },
    },
  },
  notInTradesAndNotInCanada: {
    programGraduated: {
      display: "Program(s) Graduated",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: ProgramGraduatedForm,
        displayAnswer: (form: ProgramGraduatedType) => {
          const { educationAttainment, degrees } = form;

          return (
            <div>
              <Typography>
                Highest Educational Attainment: {educationAttainment}
              </Typography>
              {degrees &&
                degrees.map((degree, i) => (
                  <DisplayDegree degreeProgram={degree} num={i + 1} />
                ))}
            </div>
          );
        },
      },
    },
  },
};

const DisplayDegree = ({
  degreeProgram,
  num,
}: {
  degreeProgram: ProgramType;
  num: number;
}) => {
  const { degree, program, school, dateStarted, dateCompleted } = degreeProgram;
  return (
    <DegreeContainer>
      <Typography fontWeight={700}>
        <em>Degree {num + 1}</em>
      </Typography>
      <Typography>Degree: {degree}</Typography>
      <Typography>Program: {program}</Typography>
      <Typography>School: {school}</Typography>
      <Typography>Started: {formatDate(dateStarted)}</Typography>
      <Typography>
        Completed: {dateCompleted ? formatDate(dateCompleted) : "Ongoing"}
      </Typography>
    </DegreeContainer>
  );
};
