import { ComponentProps } from "./types";
import { DropdownMeta } from "../types";
import Typography from "@mui/material/Typography";
import { MenuItem, Select } from "@mui/material";
import { Container } from "./const";
import styled from "@emotion/styled";
import { ErrorText } from "../../../common";

const StyledContainer = styled(Container)`
  .MuiSelect-select {
    font-size: 20px;
  }
`;

interface Props {
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const QuestionDropdown = <T,>({
  value: rawValue,
  setValue,
  question,
  componentVariant = "individual",
  error,
  helperText,
}: ComponentProps<T> & Props) => {
  const value = rawValue === "" ? null : rawValue;
  const { display, meta } = question;
  const { options = [] } = meta as DropdownMeta;

  return (
    <StyledContainer variant={componentVariant}>
      <Typography variant="h5">{display}</Typography>
      {options ? (
        <Select
          variant="outlined"
          style={{
            textWrap: "wrap",
          }}
          value={value ?? -1}
          fullWidth
          onChange={(e: any) => {
            const value = e.target.value;
            if (value !== -1) {
              setValue(value?.toString() ?? "");
            }
          }}
          error={error}
        >
          <MenuItem value={-1} disabled>
            Select Option
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ) : null}
      {error && <ErrorText helperText={helperText} />}
    </StyledContainer>
  );
};

export default QuestionDropdown;
