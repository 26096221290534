import { QuestionCheckbox, QuestionTextField } from "../../components";
import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import { Container } from "../const";
import { PersonalType, TradeCertificationType } from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const TradeCertificationForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as TradeCertificationType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.haveCertification]);

  const haveCertification = value?.haveCertification ?? null;

  return (
    <Container>
      <QuestionCheckbox<TradeCertificationType>
        value={value?.haveCertification ?? ""}
        setValue={(haveCertification) =>
          setValue({
            ...value,
            haveCertification: haveCertification as string,
          })
        }
        question={{
          display: "Do you have any trades certifications?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.haveCertification,
        })}
      />
      {haveCertification === "Yes" && (
        <QuestionTextField<TradeCertificationType>
          value={value?.certification}
          setValue={(certification) => setValue({ ...value, certification })}
          question={{
            display: "Identify your certification",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.certification,
          })}
        />
      )}
    </Container>
  );

  function isFormValid(value: TradeCertificationType) {
    if (!haveCertification) {
      return false;
    }

    if (haveCertification === "Yes") {
      return !isFieldEmpty(value?.certification);
    } else {
      return true;
    }
  }

  function reformatForm(form: TradeCertificationType) {
    if (form && haveCertification) {
      if (haveCertification === "Yes") {
        return form;
      } else {
        return {
          haveCertification,
        } as TradeCertificationType;
      }
    }

    return form;
  }
};

export default TradeCertificationForm;
