import { educationQuestions } from "./const";
import { useState } from "react";
import QuestionContainer from "../QuestionContainer";
import { CommonProps } from "../types";
import { EducationType } from "../../../../common/types";
import { useSurveyForm } from "../../../../common";
import { GroupType } from "../../enum";

interface Props {
  prevGroupName: string;
}

const MainView = ({
  initForm,
  addToForm,
  onNextView,
  onPrevView,
  prevGroupName,
}: CommonProps<EducationType> & Props) => {
  const { form: surveyForm } = useSurveyForm();
  const { inTradeSkill, currentCountry, statusInCanada } = surveyForm.personal;

  const isSkilled = inTradeSkill === "Yes";
  const isInCanada = currentCountry === "Canada";
  const isCwpHolder = statusInCanada === "Closed Work Permit Holder";

  const questions = getQuestions({ isSkilled, isInCanada, isCwpHolder });
  const [questionIndex, setQuestionIndex] = useState(0);

  const currentQuestionKey = Object.keys(questions)[
    questionIndex
  ] as keyof EducationType;
  const currentQuestion = questions[currentQuestionKey];

  const onPrevQuestion = (value: EducationType[keyof EducationType]) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex === 0) {
      onPrevView && onPrevView();
    } else {
      setQuestionIndex(0);
    }
  };

  const onNextQuestion = (value: EducationType[keyof EducationType]) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex !== Object.keys(questions).length - 1) {
      setQuestionIndex((prevState) => prevState + 1);
    } else {
      onNextView();
    }
  };

  return currentQuestion ? (
    <QuestionContainer<EducationType>
      currentQuestion={currentQuestion}
      initValue={initForm?.[currentQuestionKey]}
      onNextQuestion={onNextQuestion}
      onPrevQuestion={onPrevQuestion}
      prevButtonText={
        questionIndex === 0 ? `Back to ${prevGroupName}` : undefined
      }
      title={GroupType.EDUCATION}
    />
  ) : (
    <></>
  );
};

function getQuestions({
  isSkilled,
  isInCanada,
  isCwpHolder,
}: {
  isSkilled: boolean;
  isInCanada: boolean;
  isCwpHolder: boolean;
}) {
  const { inTrades, notInTradesAndInCanada, notInTradesAndNotInCanada } =
    educationQuestions;

  if (!isSkilled && isInCanada) {
    if (!isCwpHolder) {
      const { highestEducationAttainment, requestedEducationalCredential } =
        notInTradesAndInCanada;
      return { highestEducationAttainment, requestedEducationalCredential };
    } else {
      return notInTradesAndInCanada;
    }
  } else if (!isSkilled && !isInCanada) {
    return notInTradesAndNotInCanada;
  } else {
    return inTrades;
  }
}

export default MainView;
