import { QuestionCheckbox } from "../../components";
import { Container } from "../const";
import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import {
  PersonalType,
  ProfessionalLicensingType,
} from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const ProfessionalLicensingForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as ProfessionalLicensingType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.isProfessional]);

  const isProfessional = value?.isProfessional ?? null;

  return (
    <Container>
      <QuestionCheckbox<ProfessionalLicensingType>
        value={value?.isProfessional ?? null}
        setValue={(isProfessional) =>
          isProfessional && setValue({ ...value, isProfessional })
        }
        question={{
          display: "Are you an Engineer, Nurse, or Teacher?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.isProfessional,
        })}
      />
      {isProfessional === "Yes" && (
        <QuestionCheckbox<ProfessionalLicensingType>
          value={value?.wantToBeLicensed}
          setValue={(wantToBeLicensed) =>
            setValue({ ...value, wantToBeLicensed })
          }
          question={{
            display: "Do you want your profession to be licensed in Canada?",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.wantToBeLicensed,
          })}
        />
      )}
      {isProfessional === "No" && (
        <QuestionCheckbox<ProfessionalLicensingType>
          value={value?.isCareGiver ?? null}
          setValue={(isCareGiver) => setValue({ ...value, isCareGiver })}
          question={{
            display: "Are you a caregiver?",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.isCareGiver,
          })}
        />
      )}
    </Container>
  );

  function isFormValid(value: ProfessionalLicensingType) {
    if (!isProfessional) {
      return false;
    }

    if (isProfessional === "Yes") {
      return !isFieldEmpty(value?.wantToBeLicensed);
    } else {
      return !isFieldEmpty(value?.isCareGiver);
    }
  }

  function reformatForm(
    form: ProfessionalLicensingType,
  ): ProfessionalLicensingType {
    if (form && isProfessional) {
      const { isProfessional, isCareGiver, wantToBeLicensed } = form;
      if (isProfessional === "Yes") {
        return {
          isProfessional,
          wantToBeLicensed,
        } as ProfessionalLicensingType;
      } else {
        return { isProfessional, isCareGiver } as ProfessionalLicensingType;
      }
    }

    return form;
  }
};

export default ProfessionalLicensingForm;
