import { FormMeta, QuestionType } from "../../types";
import { QuestionComponentType } from "../../enum";
import { JobOfferForm, SpouseRelativeForm } from "../../forms";
import {
  AdaptabilityType,
  JobOfferLmiaType,
  PartialRecord,
  SpouseRelativeType,
} from "../../../../common/types";
import { Typography } from "@mui/material";

const jobOfferLmiaMeta: FormMeta = {
  component: JobOfferForm,
  displayAnswer: (form: JobOfferLmiaType) => {
    const { haveOffer, isCurrentEmployment } = form;
    return (
      <div>
        <Typography>
          Do you have a valid job offer with an LMIA?: {haveOffer}
        </Typography>
        <Typography>
          Is this your current employment?: {isCurrentEmployment}
        </Typography>
      </div>
    );
  },
};

export const spOrPGWPQuestions: PartialRecord<
  keyof AdaptabilityType,
  QuestionType
> = {
  jobOfferLmia: {
    display: "Job offer with an LMIA",
    componentType: QuestionComponentType.FORM,
    meta: jobOfferLmiaMeta,
  },
};

export const marriedQuestions: PartialRecord<
  keyof AdaptabilityType,
  QuestionType
> = {
  spouseJobOfferLmia: {
    display: "Spouse job offer with an LMIA",
    componentType: QuestionComponentType.FORM,
    meta: jobOfferLmiaMeta,
  },
  spouseHaveRelativeInCanada: {
    display: "Do you or your spouse have a relative in Canada?",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: SpouseRelativeForm,
      displayAnswer: (form: SpouseRelativeType) => {
        const {
          haveRelativeInCanada,
          isRelativePr,
          relationshipWithTheRelative,
        } = form;
        return (
          <div>
            <Typography>
              Do you or your spouse have a relative in Canada?:{" "}
              {haveRelativeInCanada}
            </Typography>
            {haveRelativeInCanada === "Yes" && (
              <>
                <Typography>
                  Is your relative a permanent resident or citizen in Canada?:{" "}
                  {isRelativePr}
                </Typography>
                <Typography>
                  What is your relationship with the relative?:{" "}
                  {relationshipWithTheRelative}
                </Typography>
              </>
            )}
          </div>
        );
      },
    },
  },
};
