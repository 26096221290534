import { useEffect } from "react";
import {
  QuestionCheckbox,
  QuestionDropdown,
  QuestionTextField,
} from "../../components";
import { ComponentProps } from "../../components/types";
import { Container } from "../const";
import { MaritalStatusType, PersonalType } from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const OPTIONS = [
  "Single",
  "Married",
  "Common law",
  "Annulled",
  "Divorced",
  "Widowed",
];

const MaritalStatusForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as MaritalStatusType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.status]);

  const maritalStatus = value?.status ?? null;

  const separated = isSeparated(maritalStatus);
  const withPartner = isWithPartner(maritalStatus);

  return (
    <Container>
      <QuestionDropdown<MaritalStatusType>
        question={{
          display: "Marital Status",
          meta: {
            options: OPTIONS.map((option) => ({
              label: option,
              value: option,
            })),
          },
        }}
        value={value?.status}
        setValue={(status) => status && setValue({ ...value, status })}
        componentVariant="form"
        {...getErrorProps({
          value: value?.status,
        })}
      />
      {separated && (
        <QuestionTextField<MaritalStatusType>
          question={{ display: "Proof of separation" }}
          value={value?.separationProof}
          setValue={(separationProof) =>
            setValue({ ...value, separationProof })
          }
          componentVariant="form"
          {...getErrorProps({
            value: value?.separationProof,
          })}
        />
      )}
      {withPartner && (
        <QuestionCheckbox<MaritalStatusType>
          question={{
            display:
              "Will your spouse or common-law partner come with you to Canada?",
          }}
          value={value?.isPartnerInCanada}
          setValue={(isPartnerInCanada) =>
            setValue({ ...value, isPartnerInCanada })
          }
          componentVariant="form"
          {...getErrorProps({
            value: value?.isPartnerInCanada,
          })}
        />
      )}
    </Container>
  );

  function isSeparated(maritalStatus: string | null) {
    return maritalStatus === "Annulled" || maritalStatus === "Divorced";
  }

  function isWithPartner(maritalStatus: string | null) {
    return maritalStatus === "Married" || maritalStatus === "Common law";
  }

  function isFormValid(value: MaritalStatusType) {
    if (!maritalStatus) {
      return false;
    }

    if (separated) {
      return !isFieldEmpty(value?.separationProof);
    } else if (withPartner) {
      return !isFieldEmpty(value?.isPartnerInCanada);
    }

    return true;
  }

  function reformatForm(form: MaritalStatusType): MaritalStatusType {
    if (form && maritalStatus) {
      const { status, separationProof, isPartnerInCanada } = form;
      if (separated) {
        return { status, separationProof } as MaritalStatusType;
      } else if (withPartner) {
        return { status, isPartnerInCanada } as MaritalStatusType;
      } else {
        return { status } as MaritalStatusType;
      }
    }

    return form;
  }
};

export default MaritalStatusForm;
