import { UserSideMenu } from "./menu";
import { OfficeType } from "../types";

export const officeList: OfficeType[] = [
  {
    id: 0,
    label: UserSideMenu.CEBU,
    name: "Cebu, Philippines",
    address:
      "1st & 2nd Floor Two Mango Mall, General Maxilom Ave.Cebu City, Philippines 6000 (Near Chow King – Mango)",
    telephoneNumber: "(032) 253 3404",
    contactNumber: "+63 917 555 9027",
    email: "info.skillsev@gmail.com",
    officeHours: "9:00 am - 6:00 pm Monday - Friday",
  },
  {
    id: 1,
    label: UserSideMenu.MAKATI,
    name: "Makati, Philippines",
    address:
      "15th Floor Pacific Star Building Cor. Makati Ave. &, Sen. Gil J. Puyat Ave, Makati, 1209 Metro Manila",
    telephoneNumber: "",
    contactNumber: "+63 917 580 0635",
    email: "info.skillsev@gmail.com",
    officeHours: "9:00 am - 6:00 pm Monday - Friday",
  },
  {
    id: 2,
    label: UserSideMenu.DUMAGUETE,
    name: "Dumaguete, Philippines",
    address:
      "G/F Leq Tak Bldg. Sta. Catalina St., Dumaguete City, Negros Oriental 6200 (Besides Foodnet)",
    telephoneNumber: "(035) 226 2181",
    contactNumber: "+63 917 799 2233",
    email: "info.skillsev@gmail.com",
    officeHours: "9:00 am - 6:00 pm Monday - Friday",
  },
  {
    id: 3,
    label: UserSideMenu.ILOILO,
    name: "Ilolo, Philippines",
    address:
      "G/29 Plazuela de Iloilo Uno Benigno Aquino Ave., Mandurriao Iloilo City 5000",
    telephoneNumber: "(033) 327 6821",
    contactNumber: "+63 917 725 6336",
    email: "info.skillsev@gmail.com",
    officeHours: "9:00 am - 6:00 pm Monday - Friday",
  },
  {
    id: 3,
    label: UserSideMenu.THAILAND,
    name: "Thailand",
    address:
      "12A Floor, Unit B1-B2, Office 1238 989 Siam Piwat Tower, Rama I Road, Patumwan, Patumwan, Bangkok 10330",
    telephoneNumber: "",
    contactNumber: "+66 82 624 6974",
    email: "info.skillsev@gmail.com",
    officeHours: "9:00 am - 6:00 pm Monday - Friday",
  },
  {
    id: 3,
    label: UserSideMenu.CANADA,
    name: "Canada",
    address: "138 King street West, Hamilton, Ontario L8G 1J6",
    telephoneNumber: "",
    contactNumber: "+1 (365) 888 5076",
    email: "info.skillsev@gmail.com",
    officeHours: "9:00 am - 6:00 pm Monday - Friday",
  },
];
