import {
  TradeSkill,
  useAlert,
  useLoading,
  useStates,
} from "../../../../common";
import React, { useState } from "react";
import ItemDetails from "../../common/ItemDetails";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import { setDateToCurrentTimezone } from "../../../../common/utils";
import { tradeSkillService } from "../../../../services";

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

interface Props {
  tradeSkill: TradeSkill;
  closeModal: () => void;
  fetch: () => void;
}

const TradeSkillDetail = ({ tradeSkill, closeModal, fetch }: Props) => {
  const {
    id,
    name: initName,
    classification: initClassification,
    exam: initExam,
    redSeal: initRedSeal,
    state: initState,
    createdAt,
    updatedAt,
  } = tradeSkill;

  const [name, setName] = useState<string>(initName);
  const [classification, setClassification] =
    useState<string>(initClassification);
  const [isExam, setIsExam] = useState<boolean>(initExam);
  const [isRedSeal, setIsRedSeal] = useState<boolean>(initRedSeal);
  const [state, setState] = useState<number | undefined>(initState.id);

  const { setShowLoading } = useLoading();
  const { setAlert } = useAlert();
  const { states } = useStates();

  return (
    <ItemDetails
      modalTitle="Trade Skill Details"
      closeModal={closeModal}
      onSave={onSave}
      disableButton={!isValid()}
    >
      <FormContent>
        <FormControl>
          <Typography>Name</Typography>
          <TextField
            size="small"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Typography>Classification</Typography>
          <Select
            value={classification}
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setClassification(e.target.value)}
          >
            <MenuItem value="Compulsory">Compulsory</MenuItem>
            <MenuItem value="Non-compulsory">Non-compulsory</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isExam}
              onChange={(e) => setIsExam(e.target.checked)}
            />
          }
          label="Examination"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isRedSeal}
              onChange={(e) => setIsRedSeal(e.target.checked)}
            />
          }
          label="Red Seal"
        />
        <FormControl>
          <Typography>States</Typography>
          <Select
            value={state}
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setState(Number(e.target.value))}
          >
            {states?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Typography>Created at</Typography>
          <Typography>
            {createdAt ? setDateToCurrentTimezone(createdAt) : "-"}
          </Typography>
        </FormControl>
        <FormControl>
          <Typography>Updated at</Typography>
          <Typography>
            {updatedAt ? setDateToCurrentTimezone(updatedAt) : "-"}
          </Typography>
        </FormControl>
      </FormContent>
    </ItemDetails>
  );

  function isValid() {
    return name !== "" && classification !== "" && state !== undefined;
  }

  function clearAll() {
    setName("");
    setClassification("");
    setIsExam(false);
    setIsRedSeal(false);
    setState(undefined);
  }

  function onSave() {
    setShowLoading(true);
    const tradeSkill = {
      id,
      name: name.trim(),
      classification: classification.trim(),
      exam: isExam,
      redSeal: isRedSeal,
      stateId: state ?? 0,
    };

    tradeSkillService
      .updateTradeSkill(tradeSkill)
      .then(() => {
        fetch();
        clearAll();
        setAlert({ message: "Trade Skill Updated!", type: "success" });
      })
      .catch(() => setAlert({ message: "Error occurred!", type: "error" }))
      .finally(() => setShowLoading(false));
  }
};

export default TradeSkillDetail;
