import { breakpoints, HeaderComponent } from "../../../../common";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useScreenResize } from "../../../../common/hooks";

const HEADER_HEIGHT = 56;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media (min-width: ${breakpoints.mobile}) {
    background-color: #e3f2fd;
  }
`;

const Content = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
  overflow-y: auto;
`;

const InnerContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(5 * var(--unit));
  height: 100%;
  padding: calc(5 * var(--unit)) var(--unit);
  overflow-y: auto;

  @media (min-width: ${breakpoints.mobile}) {
    margin: calc(5 * var(--unit)) auto;
    padding: calc(10 * var(--unit));
    max-width: calc(100 * var(--unit));
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
  }
`;

const BackButton = styled(Button)`
  height: calc(10 * var(--unit));
  font-size: 24px;
  margin-top: calc(3 * var(--unit));
`;

const ThankYouPage = () => {
  const [height, setHeight] = useState<number>(
    window.innerHeight - HEADER_HEIGHT,
  );

  const navigate = useNavigate();
  const isSkilled = localStorage.getItem("isSkilled") === "Yes";

  useScreenResize({
    handleResize: () => setHeight(window.innerHeight - HEADER_HEIGHT),
  });

  useEffect(() => {
    return () => {
      localStorage.removeItem("isSkilled");
    };
  }, []);

  return (
    <Container>
      <HeaderComponent pageType="survey" />
      <Content height={height}>
        <InnerContainer variant="outlined">
          <Typography
            fontWeight={700}
            variant="h3"
            textAlign="center"
            color="#1b3b6f"
          >
            Thank you for your time in filling out the form.
          </Typography>
          {!isSkilled && (
            <>
              <Typography textAlign="center" variant="h5" color="primary">
                Please give us 3-5 days to get back to you regarding the result
                of your permanent residency assessment.
              </Typography>
              <Typography textAlign="center" variant="h5" color="primary">
                If you are under the following professions: Nurse, Engineer,
                Teacher and you tick YES for licensing, an email for further
                instructions will be sent to your registered email address.
              </Typography>
            </>
          )}
          {isSkilled && (
            <Typography textAlign="center" variant="h5" color="primary">
              Please wait 24-48 hours for your qualifications to be initially
              screened. You will receive confirmation of your profile through
              the email address you registered.
            </Typography>
          )}
          <BackButton
            className="button"
            onClick={() => navigate("/")}
            variant="contained"
            size="large"
          >
            Go back to home
          </BackButton>
        </InnerContainer>
      </Content>
    </Container>
  );
};

export default ThankYouPage;
