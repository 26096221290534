import { ComponentProps } from "./types";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "./const";
import styled from "@emotion/styled";
import { ErrorText } from "../../../common";

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: calc(2 * var(--unit));

  button {
    flex-grow: 1;
    height: calc(8 * var(--unit));
    font-size: 20px;
  }
`;

interface Props {
  error?: boolean;
  helperText?: string;
}

const QuestionCheckbox = <T,>({
  value,
  setValue,
  question,
  componentVariant = "individual",
  helperText,
  error,
}: ComponentProps<T> & Props) => {
  const { display } = question;
  const options = ["No", "Yes"];

  return (
    <Container variant={componentVariant}>
      <Typography variant="h5">{display}</Typography>
      <ButtonsContainer>
        {options.map((option) => {
          const isSelected = option === value;

          return (
            <Button
              key={option}
              size="large"
              variant={isSelected ? "contained" : "outlined"}
              onClick={() => setValue(option as T[keyof T])}
              sx={{
                width: "150px",
                height: "60px",
              }}
            >
              {option}
            </Button>
          );
        })}
      </ButtonsContainer>
      {error && <ErrorText helperText={helperText} />}
    </Container>
  );
};

export default QuestionCheckbox;
