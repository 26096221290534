import React, { useEffect, useState } from "react";
import {
  AppModal,
  ItemOption,
  TradeSkill,
  useSurveyForm,
} from "../../../../../common";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { TradeStateType } from "../../../../../common/types";
import { QuestionAutoComplete, QuestionDropdown } from "../../../components";
import { getErrorProps } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(2 * var(--unit));
`;

const AddButton = styled(Button)`
  align-self: center;
  margin-top: calc(2 * var(--unit));
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

const TradeSkillModal = ({
  handleCloseModal,
  onSave,
  variant,
  initialValue,
  openOtherSkills,
}: {
  handleCloseModal: () => void;
  onSave: (value: TradeStateType) => void;
  variant: "Add" | "Edit";
  initialValue: TradeStateType;
  openOtherSkills: () => void;
}) => {
  const [value, setValue] = useState<TradeStateType>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [tradeSkills, setTradeSkills] = useState<TradeSkill[]>([]);

  const { states, tradeSkills: allTradeSkills } = useSurveyForm();

  useEffect(() => {
    if (value) {
      const isValid = isFormValid(value);

      setIsValid(isValid);
    }
  }, [value]);

  return (
    <AppModal
      open={true}
      handleClose={handleCloseModal}
      title={`${variant} Trade Skill`}
    >
      <Content>
        <QuestionDropdown<TradeStateType>
          value={value?.stateId ?? -1}
          setValue={(province) => {
            const stateId = Number(province);
            setValue({ ...value, stateId, tradeSkillId: -1 });
            setTradeSkills(
              allTradeSkills.filter(
                (tradeSkill) => tradeSkill.state.id === stateId,
              ),
            );
          }}
          question={{
            display: "Canada Province",
            meta: {
              options: states.map(
                (state) =>
                  ({
                    label: state.name,
                    value: state.id ?? -1,
                  }) as ItemOption,
              ),
            },
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.stateId,
          })}
        />
        <QuestionAutoComplete<TradeStateType>
          value={value?.tradeSkillId ?? -1}
          setValue={(trade) => {
            const tradeSkillId = Number(trade);
            setValue({ ...value, tradeSkillId });
          }}
          question={{
            display: "Trade Skill",
            meta: {
              options: tradeSkills.map(
                (trade) =>
                  ({
                    label: trade.name,
                    value: trade.id ?? -1,
                  }) as ItemOption,
              ),
            },
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.tradeSkillId,
          })}
        />
        <AddButton onClick={openOtherSkills} variant="contained" size="large">
          Not on the list?
        </AddButton>
      </Content>
      <Footer>
        <Button variant="outlined" onClick={handleCloseModal} size="large">
          Close
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={!isValid}
          onClick={() => {
            onSave(value);
            handleCloseModal();
            setValue(initialValue);
          }}
          size="large"
        >
          {variant}
        </Button>
      </Footer>
    </AppModal>
  );

  function isFormValid(value: TradeStateType) {
    const { stateId, tradeSkillId } = value;

    return stateId !== -1 && tradeSkillId !== -1;
  }
};

export default TradeSkillModal;
