import React, { useEffect, useRef, useState } from "react";
import HeaderComponent from "../../common/components/HeaderComponent";
import styled from "@emotion/styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeFooter from "../../common/components/HomeFooter/HomeFooter";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import { HomeSection, GoalSection, ContactSection, AboutSection} from "./groups";

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
`;

const Home = () => {
  const [showScroll, setShowScroll] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      console.log("Scroll event triggered"); // Log every scroll event
      console.log("window.scrollY:", window.scrollY); // Log current scroll position
      if (window.scrollY > 300) {
        setShowScroll(true);
        console.log("showScroll", showScroll);
      } else {
        setShowScroll(false);
        console.log("showScroll", showScroll);
      }
    };

    console.log("Scroll event listener attached"); // Confirm that the event listener is attached
    window.addEventListener("scroll", handleScroll);
    return () => {
      console.log("Scroll event listener removed"); // Confirm that the event listener is removed
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const homeRef = useRef(null);
  const goalRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const scrollRef = useRef(null);
  
  return (
    <>
      <Container>
        <HeaderComponent onMenuClicked={goToMenu} pageType="home" />
        <HomeSection divRef={homeRef} />
        <GoalSection divRef={goalRef} />
        <AboutSection divRef={aboutRef} />
        <ContactSection divRef={contactRef} />
        <HomeFooter />
        <div className="scroll" ref={scrollRef}>
          {showScroll && (
            <ArrowCircleUpRoundedIcon
              onClick={() => scrollToDiv(homeRef)}
              style={{
                fontSize: "3rem",
                position: "fixed",
                bottom: "20px",
                right: "20px",
                cursor: "pointer",
                color: "#3F96C4",
              }}
            />
          )}
        </div>
      </Container>
    </>
  );

  function goToMenu(menu: string) {
    switch (menu) {
      case "Home":
        scrollToDiv(homeRef);
        break;
      case "Goals":
        scrollToDiv(goalRef);
        break;
      case "About Us":
        scrollToDiv(aboutRef);
        break;
      case "Contact":
        scrollToDiv(contactRef);
        break;
    }
  }

  function scrollToDiv(ref: React.RefObject<HTMLDivElement>) {
    if (ref?.current) {
      ref.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }
};

export default Home;
