import { PartialRecord, SurveyType } from "../../../../common/types";
import { FormMeta, QuestionType } from "../../types";
import { Typography } from "@mui/material";
import { QuestionComponentType } from "../../enum";
import styled from "@emotion/styled";
import { breakpoints, useSurveyForm } from "../../../../common";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

const TitleText = styled(Typography)`
  color: #2d98da;
`;

const AnswersContainer = styled.div`
  margin: 0 var(--unit);
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

const QuestionAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnswerText = styled(Typography)`
  margin-left: calc(2 * var(--unit));
`;

const AnswerFormContainer = styled.div`
  margin-left: calc(2 * var(--unit));
`;

const SummaryContainer = styled(Paper)`
  margin: 0 calc(2 * var(--unit));
  padding: var(--unit);

  @media (min-width: ${breakpoints.mobile}) {
    padding: calc(3 * var(--unit));
  }
`;

const StyledDivider = styled(Divider)`
  margin: var(--unit) 0;

  @media (min-width: ${breakpoints.mobile}) {
    margin: calc(2 * var(--unit)) 0;
  }
`;

const DisplaySummary = <T,>({
  title,
  form,
  questions,
}: {
  title: string;
  form: SurveyType[keyof SurveyType];
  questions: PartialRecord<keyof T, QuestionType>;
}) => {
  return (
    <SummaryContainer variant="outlined">
      <TitleText variant="h6" fontWeight={700}>
        {title}
      </TitleText>
      <StyledDivider />
      <AnswersContainer>
        {Object.entries(questions).map(([key, rawValue], i) => {
          const value = rawValue as QuestionType;
          const { componentType, customAnswerDisplay, optionKey } =
            value as QuestionType;
          const answer = (form as any)[key];

          if (optionKey === "states") {
            return <DisplayState stateId={answer} />;
          }

          return (
            <QuestionAnswer
              num={i + 1}
              question={value}
              answer={
                customAnswerDisplay ? customAnswerDisplay(answer) : answer
              }
              isForm={componentType === QuestionComponentType.FORM}
            />
          );
        })}
      </AnswersContainer>
    </SummaryContainer>
  );
};

const QuestionAnswer = ({
  num,
  question,
  answer,
  isForm,
}: {
  num: number;
  question: QuestionType;
  answer: string;
  isForm: boolean;
}) => {
  return (
    <QuestionAnswerContainer>
      <Typography fontWeight={600}>
        {num}. {question.display}
      </Typography>
      {isForm ? (
        <DisplayForm formKey={answer} question={question} />
      ) : (
        <AnswerText>{answer}</AnswerText>
      )}
    </QuestionAnswerContainer>
  );
};

const DisplayForm = ({
  formKey,
  question,
}: {
  formKey: any;
  question: QuestionType;
}) => {
  const { meta } = question;
  const { displayAnswer } = meta as FormMeta;

  if (!meta) {
    return <></>;
  }

  return <AnswerFormContainer>{displayAnswer(formKey)}</AnswerFormContainer>;
};

const DisplayState = ({ stateId }: { stateId: number }) => {
  const { states } = useSurveyForm();

  return (
    <Typography>
      {states.find((state) => state.id === stateId)?.name ?? ""}
    </Typography>
  );
};

export default DisplaySummary;
