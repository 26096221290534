import styled from "@emotion/styled";
import React, { useState } from "react";
import WorkExperienceModal from "./WorkExperienceModal";
import sortBy from "lodash.sortby";
import { AppDialog } from "../../../../common";
import { ExperienceType } from "../../../../common/types";
import { InfoCard } from "../../common";
import { DateTime } from "luxon";
import { computeYears, formatDate } from "../../utils";
import { Typography } from "@mui/material";

const Container = styled.div`
  width: 100%;
  overflow: auto;
`;

const DutiesContainer = styled.div`
  margin-left: var(--unit);
`;

interface Props {
  experiences: ExperienceType[];
  removeExperience: (experience: ExperienceType) => void;
  updateExperience: (experience: ExperienceType, index: number) => void;
}

const WorkExperiencesDisplay = ({
  experiences,
  removeExperience,
  updateExperience,
}: Props) => {
  const [selectedExperienceToRemove, setSelectedExperienceToRemove] =
    useState<ExperienceType>();
  const [selectedExperience, setSelectedExperience] =
    useState<ExperienceType>();
  const sortedExperiences = sortBy(experiences, "date_started");

  const handleCloseModal = () => setSelectedExperience(undefined);

  return (
    <Container>
      {sortedExperiences.map((experience) => {
        const {
          jobTitle,
          companyEmployer,
          country,
          type,
          ratePerHour,
          hoursPerWeek,
          dateStarted,
          dateCompleted,
          duties,
        } = experience;

        const startYear = dateStarted
          ? DateTime.fromISO(dateStarted).toFormat("yyyy")
          : "";
        const endYear = dateCompleted
          ? DateTime.fromISO(dateCompleted).toFormat("yyyy")
          : "Ongoing";

        return (
          <InfoCard
            key={jobTitle}
            title={`[${companyEmployer}] ${jobTitle}`}
            chipLabel={`${startYear} - ${endYear}`}
            data={[
              { label: "Job Title", value: jobTitle },
              { label: "Employer", value: companyEmployer },
              { label: "Country", value: country },
              { label: "Type", value: type },
              { label: "Rate per hour", value: `₱ ${ratePerHour}` },
              { label: "Hours per week", value: hoursPerWeek },
              {
                label: "Duties/Responsibilities",
                value: (
                  <DutiesContainer>
                    {duties.map((duty, i) => (
                      <Typography>
                        {i + 1}. {duty}
                      </Typography>
                    ))}
                  </DutiesContainer>
                ),
              },
              { label: "Date Started", value: formatDate(dateStarted) },
              {
                label: "Date Ended",
                value: dateCompleted ? formatDate(dateCompleted) : "Ongoing",
              },
              {
                label: "Years Experience",
                value: `${
                  dateStarted &&
                  computeYears({
                    startDate: dateStarted,
                    endDate: dateCompleted
                      ? dateCompleted
                      : DateTime.now().toString(),
                  })
                }`,
              },
            ]}
            onEditClicked={() => setSelectedExperience(experience)}
            onRemoveClicked={() => setSelectedExperienceToRemove(experience)}
          />
        );
      })}
      {selectedExperience && (
        <WorkExperienceModal
          initialValue={selectedExperience}
          handleCloseModal={handleCloseModal}
          variant="Edit"
          onSave={(value) => {
            const index = experiences.indexOf(selectedExperience);
            updateExperience(value, index);
          }}
        />
      )}
      {selectedExperienceToRemove && (
        <AppDialog
          title="Delete work experience"
          description={`Are you sure you want to delete ${selectedExperienceToRemove?.jobTitle}?`}
          handleClose={() => setSelectedExperienceToRemove(undefined)}
          onConfirmClick={() => removeExperience(selectedExperienceToRemove)}
        />
      )}
    </Container>
  );
};

export default WorkExperiencesDisplay;
