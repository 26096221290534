import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { AppModal } from "../../../common";
import styled from "@emotion/styled";

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

const Content = styled.div`
  padding: calc(2 * var(--unit)) var(--unit);
`;

interface Props {
  label: string;
  modalTitle: string;
  children: any;
  onSave: () => void;
  disableButton: boolean;
}

const AddItem = ({
  label,
  modalTitle,
  children,
  onSave,
  disableButton,
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => setOpenModal(false);

  return (
    <div className="add-item">
      <Button
        variant="contained"
        endIcon={<AddIcon />}
        onClick={() => setOpenModal(true)}
      >
        {label}
      </Button>
      <AppModal open={openModal} handleClose={closeModal} title={modalTitle}>
        <Content>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSave();
              closeModal();
            }}
          >
            {children}
            <Footer>
              <Button variant="outlined" onClick={closeModal}>
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={disableButton}
              >
                Save
              </Button>
            </Footer>
          </form>
        </Content>
      </AppModal>
    </div>
  );
};

export default AddItem;
