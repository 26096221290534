import { ComponentProps } from "../../components/types";
import { Container } from "../const";
import {
  QuestionCheckbox,
  QuestionDropdown,
  QuestionTextField,
} from "../../components";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { LanguageType, TakenEnglishType } from "../../../../common/types";
import { getErrorProps } from "../../utils";

const OPTIONS = ["IELTS GENERAL", "CELPIP GENERAL", "PTE SCORE"];

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(2 * var(--unit));
`;

const EnglishForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<LanguageType>) => {
  const value = rawValue as TakenEnglishType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.takenEnglish]);

  const haveTakenEnglish = value?.takenEnglish ?? null;

  return (
    <Container>
      <QuestionCheckbox<TakenEnglishType>
        value={value?.takenEnglish ?? null}
        setValue={(takenEnglish) =>
          takenEnglish && setValue({ ...value, takenEnglish })
        }
        question={{
          display: "Have you taken an English proficiency test?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.takenEnglish,
        })}
      />
      {haveTakenEnglish === "Yes" && (
        <>
          <QuestionDropdown<TakenEnglishType>
            value={value?.testTaken ?? null}
            setValue={(testTaken) => setValue({ ...value, testTaken })}
            question={{
              display: "Choose English Proficiency test",
              meta: {
                options: OPTIONS.map((option) => ({
                  label: option,
                  value: option,
                })),
              },
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.testTaken,
            })}
          />
          <ScoreContainer>
            <QuestionTextField<TakenEnglishType>
              value={value?.listening}
              setValue={(listening) => setValue({ ...value, listening })}
              question={{
                display: "Listening",
              }}
              componentVariant="form"
              type="number"
              {...getErrorProps({
                value: value?.listening,
              })}
            />
            <QuestionTextField<TakenEnglishType>
              value={value?.speaking}
              setValue={(speaking) => setValue({ ...value, speaking })}
              question={{
                display: "Speaking",
              }}
              componentVariant="form"
              type="number"
              {...getErrorProps({
                value: value?.speaking,
              })}
            />
            <QuestionTextField<TakenEnglishType>
              value={value?.reading}
              setValue={(reading) => setValue({ ...value, reading })}
              question={{
                display: "Reading",
              }}
              componentVariant="form"
              type="number"
              {...getErrorProps({
                value: value?.reading,
              })}
            />
            <QuestionTextField<TakenEnglishType>
              value={value?.writing}
              setValue={(writing) => setValue({ ...value, writing })}
              question={{
                display: "Writing",
              }}
              componentVariant="form"
              type="number"
              {...getErrorProps({
                value: value?.writing,
              })}
            />
          </ScoreContainer>
        </>
      )}
    </Container>
  );

  function isFormValid(value: TakenEnglishType) {
    if (!haveTakenEnglish) {
      return false;
    }

    if (haveTakenEnglish === "Yes") {
      const englishTest = value?.testTaken ?? null;
      const listening = value?.listening ?? "";
      const speaking = value?.speaking ?? "";
      const reading = value?.reading ?? "";
      const writing = value?.writing ?? "";

      return (
        englishTest !== null &&
        listening !== "" &&
        speaking !== "" &&
        reading !== "" &&
        writing !== ""
      );
    } else {
      return true;
    }
  }

  function reformatForm(form: TakenEnglishType) {
    if (form && haveTakenEnglish) {
      if (haveTakenEnglish === "Yes") {
        return form;
      } else {
        const { takenEnglish } = form;
        return { takenEnglish } as TakenEnglishType;
      }
    }

    return form;
  }
};

export default EnglishForm;
