import { ComponentProps } from "../../components/types";
import { DegreePrograms } from "./DegreePrograms";
import { EducationType } from "../../../../common/types";

const OPTIONS = [
  "High School Graduate",
  "College Undergraduate",
  "College Graduate",
  "Masters Graduate",
  "PhD Graduate",
  "Vocational",
];

const ProgramGraduatedForm = (props: ComponentProps<EducationType>) => {
  const showVocational = (educationAttainment: string | null) =>
    educationAttainment?.includes(`Vocational`) ?? false;
  const showBachelor = (educationAttainment: string | null) =>
    educationAttainment?.includes(`College Graduate`) ?? false;
  const showMasters = (educationAttainment: string | null) =>
    educationAttainment?.includes(`Masters Graduate`) ?? false;
  const showDoctoral = (educationAttainment: string | null) =>
    educationAttainment?.includes(`PhD Graduate`) ?? false;

  return (
    <DegreePrograms
      {...props}
      options={OPTIONS}
      showVocational={showVocational}
      showBachelor={showBachelor}
      showMasters={showMasters}
      showDoctoral={showDoctoral}
    />
  );
};

export default ProgramGraduatedForm;
