import { useState } from "react";
import QuestionContainer from "../QuestionContainer";
import { languageQuestions } from "./const";
import { LanguageType } from "../../../../common/types";
import { useSurveyForm } from "../../../../common";
import { GroupType } from "../../enum";

interface Props {
  initForm: LanguageType;
  onNextGroup: () => void;
  onPrevGroup?: () => void;
  prevGroupName: string;
}

const Language = ({
  initForm,
  onNextGroup,
  onPrevGroup,
  prevGroupName,
}: Props) => {
  const { form: surveyForm, setForm: setSurveyForm } = useSurveyForm();

  const questions = languageQuestions;

  const [languageForm, setLanguageForm] = useState<LanguageType>(initForm);
  const [questionIndex, setQuestionIndex] = useState(0);

  const currentQuestionKey = Object.keys(questions)[
    questionIndex
  ] as keyof LanguageType;
  const currentQuestion = questions[currentQuestionKey];

  const addToForm = ({
    key,
    value,
  }: {
    key: string;
    value: LanguageType[keyof LanguageType];
  }) => {
    const updatedForm = value
      ? {
          ...languageForm,
          [key]: value,
        }
      : { ...languageForm };

    setLanguageForm(updatedForm);
    setSurveyForm({
      ...surveyForm,
      language: updatedForm,
    });
  };

  const onPrevQuestion = (value: LanguageType[keyof LanguageType]) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex === 0) {
      onPrevGroup && onPrevGroup();
    }
  };

  const onNextQuestion = (value: LanguageType[keyof LanguageType]) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex !== Object.keys(questions).length - 1) {
      setQuestionIndex((prevState) => prevState + 1);
    } else {
      onNextGroup();
    }
  };

  return (
    <QuestionContainer<LanguageType>
      currentQuestion={currentQuestion}
      initValue={initForm?.[currentQuestionKey]}
      onNextQuestion={onNextQuestion}
      onPrevQuestion={onPrevQuestion}
      prevButtonText={
        questionIndex === 0 ? `Back to ${prevGroupName}` : undefined
      }
      title={GroupType.LANGUAGE}
    />
  );
};

export default Language;
