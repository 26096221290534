import React from "react";
import styled from "@emotion/styled";
import { AppTable, State, TableColumn, useStates } from "../../../common";
import TableCell from "@mui/material/TableCell";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

const TableContainer = styled.div`
  height: calc(100vh - 150px);
  margin-top: var(--unit);
`;

const COLUMNS: TableColumn[] = [
  { label: "Name" },
  { label: "Short Name" },
  { label: "Country" },
];

const States = () => {
  const { states } = useStates();

  return (
    <Container>
      <TableContainer>
        <AppTable<State>
          columns={COLUMNS}
          data={states}
          onRowRender={(row) => {
            const { name, shortName, country } = row;

            return (
              <>
                <TableCell>{name}</TableCell>
                <TableCell>{shortName}</TableCell>
                <TableCell>{country}</TableCell>
              </>
            );
          }}
        />
      </TableContainer>
    </Container>
  );
};

export default States;
