import styled from "@emotion/styled";
import {
  breakpoints,
  EBreakpoints,
  HeaderComponent,
  useSurveyForm,
} from "../../common";
import React, { useEffect, useState } from "react";
import Progress from "./Progress";
import { GroupType } from "./enum";
import { SurveyType } from "../../common/types";
import { isAdaptabilityVisaStatusValid } from "./utils";
import {
  AccountInformation,
  Adaptability,
  Education,
  Language,
  Personal,
  Summary,
  WorkExperience,
} from "./groups";
import { useMediaQuery } from "@mui/material";
import { useScreenResize } from "../../common/hooks";

const HEADER_HEIGHT = 56;
const PROGRESS_HEIGHT = 80;
const DESKTOP_ALLOWANCE_HEIGHT = 100;
const MOBILE_ALLOWANCE_HEIGHT = 150;

const Container = styled.div<{ height: number }>`
  width: 100vw;
  height: ${({ height }) => `${height}px`};
  overflow: hidden;

  @media (min-width: ${breakpoints.mobile}) {
    background-color: #e3f2fd;
  }

  .MuiFormHelperText-root,
  .helperTextCustom {
    font-size: 16px;
  }
`;

const Content = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
  overflow-y: auto;
  padding: calc(3 * var(--unit)) 0;
`;

const ProgressContainer = styled.div`
  padding: calc(2 * var(--unit));
  background-color: #f1f2f6;
`;

const Survey = () => {
  const isMobile = useMediaQuery(EBreakpoints.Mobile);

  const { form: surveyForm } = useSurveyForm();
  const [groups, setGroups] = useState<string[]>([]);
  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [allowanceHeight, setAllowanceHeight] = useState<number>(0);
  const [browserHeight, setBrowserHeight] = useState<number>(
    window.innerHeight,
  );
  const [height, setHeight] = useState<number>(
    window.innerHeight -
      HEADER_HEIGHT -
      PROGRESS_HEIGHT -
      (isMobile ? MOBILE_ALLOWANCE_HEIGHT : DESKTOP_ALLOWANCE_HEIGHT),
  );

  useEffect(() => {
    // console.log("survey", surveyForm);
    saveLocally();
    setGroups(getGroups(surveyForm));
  }, [surveyForm]);

  useScreenResize({
    handleResize: () => setBrowserHeight(window.innerHeight),
  });

  useScreenResize({
    handleResize: () =>
      setHeight(
        window.innerHeight - HEADER_HEIGHT - PROGRESS_HEIGHT - allowanceHeight,
      ),
    dependencies: [allowanceHeight],
  });

  useEffect(() => {
    setAllowanceHeight(
      isMobile ? MOBILE_ALLOWANCE_HEIGHT : DESKTOP_ALLOWANCE_HEIGHT,
    );
  }, [isMobile]);

  const currentGroup = groups[groupIndex];

  const onPrevGroup = () => {
    if (groupIndex !== 0) {
      setGroupIndex((prevState) => prevState - 1);
    }
    saveLocally();
  };

  const onNextGroup = () => {
    if (groupIndex !== groups.length - 1) {
      setGroupIndex((prevState) => prevState + 1);
    }
    saveLocally();
  };

  const commonProps = {
    onPrevGroup,
    onNextGroup,
    prevGroupName: groups[groupIndex - 1],
  };

  return height ? (
    <Container height={browserHeight}>
      <HeaderComponent pageType="survey" />
      <ProgressContainer>
        <Progress groups={groups} activeStep={groupIndex} />
      </ProgressContainer>
      <Content height={height}>
        {currentGroup === GroupType.ACCOUNT_INFORMATION && (
          <AccountInformation
            {...commonProps}
            initForm={surveyForm.accountInformation}
          />
        )}
        {currentGroup === GroupType.PERSONAL && (
          <Personal {...commonProps} initForm={surveyForm.personal} />
        )}
        {currentGroup === GroupType.EDUCATION && (
          <Education {...commonProps} initForm={surveyForm.education} />
        )}
        {currentGroup === GroupType.LANGUAGE && surveyForm.language && (
          <Language {...commonProps} initForm={surveyForm.language} />
        )}
        {currentGroup === GroupType.WORK_EXPERIENCE && (
          <WorkExperience
            {...commonProps}
            initForm={surveyForm.workExperience}
          />
        )}
        {currentGroup === GroupType.ADAPTABILITY && surveyForm.adaptability && (
          <Adaptability {...commonProps} initForm={surveyForm.adaptability} />
        )}
        {currentGroup === GroupType.SUMMARY && (
          <Summary
            editQuestions={() => setGroupIndex(0)}
            saveLocally={saveLocally}
          />
        )}
      </Content>
    </Container>
  ) : null;

  function getGroups(surveyForm: SurveyType) {
    const isSkilled = surveyForm.personal.inTradeSkill === "Yes";
    const isVisaHolderValid = isAdaptabilityVisaStatusValid(
      surveyForm.personal.statusInCanada,
    );

    const isMarried = surveyForm.personal.maritalStatus.status === "Married";

    if (isSkilled) {
      return Object.values(GroupType).filter(
        (group) =>
          group !== GroupType.LANGUAGE && group !== GroupType.ADAPTABILITY,
      );
    } else if (!isVisaHolderValid && !isMarried) {
      return Object.values(GroupType).filter(
        (group) => group !== GroupType.ADAPTABILITY,
      );
    } else {
      return Object.values(GroupType);
    }
  }

  function saveLocally() {
    localStorage.setItem("surveyForm", JSON.stringify(surveyForm));
  }
};

export default Survey;
