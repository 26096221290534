import "react-phone-number-input/style.css";
import { useState } from "react";
import MainView from "./MainView";
import { AccountInformationType } from "../../../../common/types";
import ConfirmEmail from "./ConfirmEmail";
import { useSurveyForm } from "../../../../common";

enum View {
  MAIN,
  FLOW,
}

interface Props {
  initForm: AccountInformationType;
  onNextGroup: () => void;
}

const AccountInformation = ({ initForm, onNextGroup }: Props) => {
  const { form: surveyForm, setForm: setSurveyForm } = useSurveyForm();

  const [view, setView] = useState<View>(View.MAIN);
  const [accountInformationForm, setAccountInformationForm] =
    useState<AccountInformationType>(initForm);
  const [mainInitIndex, setMainInitIndex] = useState<number>(0);

  const addToForm = ({
    key,
    value,
  }: {
    key: string;
    value: AccountInformationType[keyof AccountInformationType];
  }) => {
    const updatedForm = value
      ? {
          ...accountInformationForm,
          [key]: value,
        }
      : { ...accountInformationForm };

    setAccountInformationForm(updatedForm);
    setSurveyForm({
      ...surveyForm,
      accountInformation: updatedForm,
    });
  };

  const commonProps = {
    addToForm,
    initForm: accountInformationForm,
  };

  return (
    <>
      {view === View.MAIN && (
        <MainView
          {...commonProps}
          onNextView={() => setView(View.FLOW)}
          initIndex={mainInitIndex}
        />
      )}
      {view === View.FLOW && (
        <ConfirmEmail
          {...commonProps}
          onPrevView={() => {
            setMainInitIndex(1);
            setView(View.MAIN);
          }}
          onNextView={onNextGroup}
        />
      )}
    </>
  );
};

export default AccountInformation;
