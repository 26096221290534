import React, { useState } from "react";
import groupBy from "lodash.groupby";
import sortBy from "lodash.sortby";
import ProgramCompletedModal from "./ProgramCompletedModal";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { AppDialog } from "../../../../../common";
import { ProgramType } from "../../../../../common/types";
import { DateTime } from "luxon";
import { formatDate } from "../../../utils";
import { InfoCard } from "../../../common";

const Container = styled.div`
  padding: var(--unit);
`;

const DegreeContainer = styled.div`
  margin-bottom: calc(2 * var(--unit));
`;

const StyledTypography = styled(Typography)`
  color: #b2bec3;
`;

interface Props {
  degrees: ProgramType[];
  removeDegree: (degree: ProgramType) => void;
  updateDegreeProgram: (degree: ProgramType, index: number) => void;
  degreeOptions: string[];
}

const DegreesDisplay = ({
  degrees: rawDegrees,
  removeDegree,
  updateDegreeProgram,
  degreeOptions,
}: Props) => {
  const [selectedProgramToRemove, setSelectedProgramToRemove] =
    useState<ProgramType>();
  const [selectedProgram, setSelectedProgram] = useState<ProgramType>();
  const degrees = sortBy(rawDegrees, "dateStarted").reverse();
  const groupByDegree = groupBy(degrees, "degree");

  const handleCloseModal = () => setSelectedProgram(undefined);

  return (
    <Container>
      {Object.keys(groupByDegree).map((key) => (
        <DegreeContainer>
          <StyledTypography fontWeight={600}>{key}(s)</StyledTypography>
          {sortBy(groupByDegree[key], "dateStarted")
            .reverse()
            .map((degree, i) => {
              const { program, dateStarted, dateCompleted, school } = degree;
              const startYear = dateStarted
                ? DateTime.fromISO(dateStarted).toFormat("yyyy")
                : "";
              const endYear = dateCompleted
                ? DateTime.fromISO(dateCompleted).toFormat("yyyy")
                : "Ongoing";

              return (
                <InfoCard
                  key={program}
                  title={program}
                  chipLabel={`${startYear} - ${endYear}`}
                  data={[
                    { label: "Program", value: program },
                    { label: "School", value: school },
                    { label: "Date Started", value: formatDate(dateStarted) },
                    {
                      label: "Date Ended",
                      value: dateCompleted
                        ? formatDate(dateCompleted)
                        : "Ongoing",
                    },
                  ]}
                  onEditClicked={() => setSelectedProgram(degree)}
                  onRemoveClicked={() => setSelectedProgramToRemove(degree)}
                />
              );
            })}
        </DegreeContainer>
      ))}
      {selectedProgram && (
        <ProgramCompletedModal
          initialValue={selectedProgram}
          handleCloseModal={handleCloseModal}
          degreeOptions={degreeOptions}
          variant="Edit"
          onSave={(value) => {
            const index = degrees.indexOf(selectedProgram);
            updateDegreeProgram(value, index);
          }}
        />
      )}
      {selectedProgramToRemove && (
        <AppDialog
          title="Delete degree program"
          description={`Are you sure you want to delete ${selectedProgramToRemove?.program}?`}
          handleClose={() => setSelectedProgramToRemove(undefined)}
          onConfirmClick={() => removeDegree(selectedProgramToRemove)}
        />
      )}
    </Container>
  );
};

export default DegreesDisplay;
