import Button from "@mui/material/Button";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import {
  AppDialog,
  breakpoints,
  EBreakpoints,
  useAlert,
  useLoading,
  useSurveyForm,
} from "../../../../common";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplaySummary from "./DisplaySummary";
import {
  AccountInformationType,
  AdaptabilityType,
  EducationType,
  LanguageType,
  PersonalType,
  SurveyType,
  WorkExperienceType,
} from "../../../../common/types";
import { personalQuestions } from "../Personal";
import { accountInformationQuestions } from "../AccountInformation/const";
import { educationQuestions } from "../Education/const";
import { languageQuestions } from "../Language/const";
import { workExperienceQuestions } from "../WorkExperience/const";
import { marriedQuestions, spOrPGWPQuestions } from "../Adaptability/const";
import { applicantService } from "../../../../services/applicantService";
import { updateFormByKeys } from "../../forms/utils";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(2 * var(--unit));
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(4 * var(--unit));
  width: 100%;

  @media (min-width: ${breakpoints.mobile}) {
    max-width: calc(100 * var(--unit));
  }
`;

const FooterContainer = styled(Paper)`
  height: 180px;
  width: 100%;
  border-radius: 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: calc(2 * var(--unit));
  row-gap: calc(2 * var(--unit));
  background-color: white;

  button {
    height: calc(8 * var(--unit));
  }

  @media (min-width: ${breakpoints.mobile}) {
    height: 110px;
    flex-direction: row;
    justify-content: space-between;
    padding: calc(3 * var(--unit)) calc(2 * var(--unit));

    button {
      width: calc(35 * var(--unit));
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: calc(3 * var(--unit)) calc(25 * var(--unit));
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: calc(3 * var(--unit)) calc(40 * var(--unit));
  }
`;

interface Props {
  editQuestions: () => void;
  saveLocally: () => void;
}

const Summary = ({ editQuestions }: Props) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { form } = useSurveyForm();
  const { setShowLoading } = useLoading();
  const { setAlert } = useAlert();

  const navigate = useNavigate();
  const isMobile = useMediaQuery(EBreakpoints.Mobile);

  const {
    accountInformation,
    personal,
    education,
    language,
    workExperience,
    adaptability,
  } = form;

  const isSkilled = personal.inTradeSkill === "Yes";
  const isInCanada = personal.currentCountry === "Canada";
  const isCwpHolder = personal.statusInCanada === "Closed Work Permit Holder";

  const isSpOrPGWP =
    personal.statusInCanada === "Study Permit Holder" ||
    personal.statusInCanada === "PGWP Holder";
  const isMarried = personal.maritalStatus.status === "Married";

  const cleanupSurvey: SurveyType = {
    accountInformation: cleanupAccountInformation({ form: accountInformation }),
    personal: cleanupPersonal({ form: personal, isSkilled, isInCanada }),
    education: cleanupEducation({
      form: education,
      isSkilled,
      isInCanada,
      isCwpHolder,
    }),
    language: isSkilled ? null : language,
    workExperience,
    adaptability:
      !isSkilled && adaptability
        ? cleanupAdaptability({
            form: adaptability,
            isSpOrPGWP,
            isMarried,
          })
        : null,
  };

  return (
    <Container>
      <InnerContainer>
        <DisplaySummary<AccountInformationType>
          title="Account Information"
          form={cleanupSurvey.accountInformation}
          questions={accountInformationQuestions}
        />
        <DisplaySummary<PersonalType>
          title="Personal"
          form={cleanupSurvey.personal}
          questions={getPersonalQuestions({ isSkilled, isInCanada })}
        />
        <DisplaySummary<EducationType>
          title="Education"
          form={cleanupSurvey.education}
          questions={getEducationQuestions({
            isSkilled,
            isInCanada,
            isCwpHolder,
          })}
        />
        {cleanupSurvey.language &&
          Object.keys(cleanupSurvey.language).length > 0 && (
            <DisplaySummary<LanguageType>
              title="Language"
              form={cleanupSurvey.language}
              questions={languageQuestions}
            />
          )}
        <DisplaySummary<WorkExperienceType>
          title="Work Experiences"
          form={cleanupSurvey.workExperience}
          questions={workExperienceQuestions}
        />
        {cleanupSurvey.adaptability &&
          Object.keys(cleanupSurvey.adaptability).length > 0 && (
            <DisplaySummary<AdaptabilityType>
              title="Adaptability"
              form={cleanupSurvey.adaptability}
              questions={getAdaptabilityQuestions({ isSpOrPGWP, isMarried })}
            />
          )}
      </InnerContainer>
      <FooterContainer>
        <div>
          <Button
            size="large"
            onClick={editQuestions}
            startIcon={isMobile ? undefined : <ArrowBackIos />}
            variant="outlined"
            fullWidth
          >
            Edit Answers
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            onClick={() => setOpenConfirm(true)}
            endIcon={isMobile ? undefined : <ArrowForwardIos />}
            fullWidth
          >
            Submit Survey Form
          </Button>
        </div>
      </FooterContainer>
      {openConfirm && (
        <AppDialog
          title="Submit survey form"
          description="Are you sure you want to submit survey?"
          handleClose={() => setOpenConfirm(false)}
          onConfirmClick={onSave}
        />
      )}
    </Container>
  );

  function onSave() {
    setShowLoading(true);

    const applicant = {
      email: (accountInformation.confirmedEmail as string) ?? "",
      surveyAnswer: cleanupSurvey,
    };

    const isSkilled = cleanupSurvey.personal.inTradeSkill ?? "";

    applicantService
      .addApplicant(applicant)
      .then(() => {
        setAlert({ message: "Survey answers submitted!", type: "success" });
      })
      .catch(() => setAlert({ message: "Error occurred!", type: "error" }))
      .finally(() => {
        setShowLoading(false);
        localStorage.removeItem("surveyForm");
        localStorage.setItem("isSkilled", isSkilled);
        navigate(`/thank-you-page`);
      });
  }
};

function cleanupAccountInformation({ form }: { form: AccountInformationType }) {
  const { lookingForJob, information, confirmedEmail } = form;
  const isConfirmed = form.information.email === confirmedEmail ? "Yes" : "No";

  return {
    lookingForJob,
    information,
    confirmedEmail: isConfirmed,
  };
}

function cleanupPersonal({
  form,
  isSkilled,
  isInCanada,
}: {
  form: PersonalType;
  isSkilled: boolean;
  isInCanada: boolean;
}) {
  const questions = getPersonalQuestions({ isSkilled, isInCanada });

  return updateFormByKeys<PersonalType>(form, Object.keys(questions));
}

function cleanupEducation({
  form,
  isSkilled,
  isInCanada,
  isCwpHolder,
}: {
  form: EducationType;
  isSkilled: boolean;
  isInCanada: boolean;
  isCwpHolder: boolean;
}): EducationType {
  const questions = getEducationQuestions({
    isSkilled,
    isInCanada,
    isCwpHolder,
  });

  return updateFormByKeys<EducationType>(form, Object.keys(questions));
}

function cleanupAdaptability({
  form,
  isSpOrPGWP,
  isMarried,
}: {
  form: AdaptabilityType;
  isSpOrPGWP: boolean;
  isMarried: boolean;
}): AdaptabilityType {
  const questions = getAdaptabilityQuestions({
    isSpOrPGWP,
    isMarried,
  });

  return updateFormByKeys<AdaptabilityType>(form, Object.keys(questions));
}

function getPersonalQuestions({
  isSkilled,
  isInCanada,
}: {
  isSkilled: boolean;
  isInCanada: boolean;
}) {
  return {
    ...personalQuestions.main,
    ...getPersonalFlow({ isSkilled, isInCanada }),
  };
}

function getEducationQuestions({
  isSkilled,
  isInCanada,
  isCwpHolder,
}: {
  isSkilled: boolean;
  isInCanada: boolean;
  isCwpHolder: boolean;
}) {
  return getEducationFlow({ isSkilled, isInCanada, isCwpHolder });
}

function getAdaptabilityQuestions({
  isSpOrPGWP,
  isMarried,
}: {
  isSpOrPGWP: boolean;
  isMarried: boolean;
}) {
  return getAdaptabilityFlow({ isSpOrPGWP, isMarried });
}

function getPersonalFlow({
  isSkilled,
  isInCanada,
}: {
  isSkilled: boolean;
  isInCanada: boolean;
}) {
  const {
    inTradesAndInCanada,
    inTradesAndNotInCanada,
    notInTradesAndInCanada,
    notInTradesAndNotInCanada,
  } = personalQuestions;

  if (isSkilled && isInCanada) {
    return inTradesAndInCanada;
  } else if (isSkilled && !isInCanada) {
    return inTradesAndNotInCanada;
  } else if (!isSkilled && isInCanada) {
    return notInTradesAndInCanada;
  } else if (!isSkilled && !isInCanada) {
    return notInTradesAndNotInCanada;
  }
  return [];
}

function getEducationFlow({
  isSkilled,
  isInCanada,
  isCwpHolder,
}: {
  isSkilled: boolean;
  isInCanada: boolean;
  isCwpHolder: boolean;
}) {
  const { inTrades, notInTradesAndInCanada, notInTradesAndNotInCanada } =
    educationQuestions;

  if (!isSkilled && isInCanada) {
    if (!isCwpHolder) {
      const { highestEducationAttainment, requestedEducationalCredential } =
        notInTradesAndInCanada;
      return { highestEducationAttainment, requestedEducationalCredential };
    } else {
      return notInTradesAndInCanada;
    }
  } else if (!isSkilled && !isInCanada) {
    return notInTradesAndNotInCanada;
  } else {
    return inTrades;
  }
}

function getAdaptabilityFlow({
  isSpOrPGWP,
  isMarried,
}: {
  isSpOrPGWP: boolean;
  isMarried: boolean;
}) {
  return {
    ...(isSpOrPGWP ? spOrPGWPQuestions : {}),
    ...(isMarried ? marriedQuestions : {}),
  };
}

export default Summary;
