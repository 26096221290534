import { accountInformationQuestions } from "./const";
import { useEffect, useState } from "react";
import QuestionContainer from "../QuestionContainer";
import {
  AccountInformationType,
  PartialRecord,
} from "../../../../common/types";
import { CommonProps } from "../types";
import { QuestionType } from "../../types";
import { useAlert, useSurveyForm } from "../../../../common";
import { GroupType } from "../../enum";

const ConfirmEmail = ({
  addToForm,
  onNextView,
  onPrevView,
}: CommonProps<AccountInformationType>) => {
  const sentCode = "123456";
  const { form: surveyForm } = useSurveyForm();

  const { confirmedEmail } = accountInformationQuestions;
  const { setAlert } = useAlert();
  const questions: PartialRecord<keyof AccountInformationType, QuestionType> = {
    confirmedEmail,
  };

  const [questionIndex, setQuestionIndex] = useState(0);
  const currentQuestionKey = Object.keys(questions)[
    questionIndex
  ] as keyof AccountInformationType;
  const currentQuestion = questions[currentQuestionKey];

  const email = surveyForm.accountInformation.information.confirmEmail;
  const isEmailConfirmed =
    email === surveyForm.accountInformation.confirmedEmail;

  useEffect(() => {
    if (isEmailConfirmed) {
      onNextView();
    }
  }, [isEmailConfirmed]);

  const onPrevQuestion = (
    value: AccountInformationType[keyof AccountInformationType],
  ) => {
    if (questionIndex === 0) {
      onPrevView && onPrevView();
    } else {
      setQuestionIndex((prevState) => prevState - 1);
    }
  };

  const onNextQuestion = (
    value: AccountInformationType[keyof AccountInformationType],
  ) => {
    if (value === sentCode) {
      addToForm({
        key: currentQuestionKey,
        value: email,
      });
      onNextView();
    } else {
      setAlert({
        type: "error",
        message: "Invalid code!",
      });
    }
  };

  return currentQuestion ? (
    <QuestionContainer<AccountInformationType>
      currentQuestion={currentQuestion}
      initValue=""
      onNextQuestion={onNextQuestion}
      onPrevQuestion={onPrevQuestion}
      title={GroupType.ACCOUNT_INFORMATION}
    />
  ) : (
    <></>
  );
};

export default ConfirmEmail;
