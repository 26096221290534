import { ComponentProps } from "./types";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-number-input";
import { Container } from "./const";
import styled from "@emotion/styled";
import { ErrorText } from "../../../common";

const NumContainer = styled(Container)`
  .error {
    border: 1px solid red;
  }
`;

interface InputProps {
  error?: boolean;
  helperText?: string;
}

const QuestionNumberInput = <T,>({
  value,
  setValue,
  question,
  componentVariant = "individual",
  error,
  helperText,
}: ComponentProps<T> & InputProps) => {
  const { display } = question;

  return (
    <NumContainer variant={componentVariant}>
      <Typography variant="h5">{display}</Typography>
      <PhoneInput
        placeholder="Enter phone number"
        value={value as string}
        onChange={(value) => setValue(value as T[keyof T])}
        defaultCountry="PH"
        className={error ? "error" : undefined}
      />
      {error && <ErrorText helperText={helperText} />}
    </NumContainer>
  );
};

export default QuestionNumberInput;
