import { useState } from "react";
import MainView from "./MainView";
import FlowView from "./FlowView";
import { PersonalType } from "../../../../common/types";
import { useSurveyForm } from "../../../../common";

enum View {
  MAIN,
  FLOW,
}

interface Props {
  initForm: PersonalType;
  onPrevGroup: () => void;
  onNextGroup: () => void;
  prevGroupName: string;
}

const Personal = ({
  initForm,
  onPrevGroup,
  onNextGroup,
  prevGroupName,
}: Props) => {
  const { form: surveyForm, setForm: setSurveyForm } = useSurveyForm();

  const [view, setView] = useState<View>(View.MAIN);
  const [personalForm, setPersonalForm] = useState<PersonalType>(initForm);
  const [mainInitIndex, setMainInitIndex] = useState<number>(0);

  const addToForm = ({
    key,
    value,
  }: {
    key: string;
    value: PersonalType[keyof PersonalType];
  }) => {
    const updatedForm = value
      ? {
          ...personalForm,
          [key]: value,
        }
      : { ...personalForm };

    setPersonalForm(updatedForm);
    setSurveyForm({
      ...surveyForm,
      personal: updatedForm,
    });
  };

  const commonProps = {
    addToForm,
    initForm: personalForm,
  };

  const isSkilled = personalForm.inTradeSkill === "Yes";
  const isInCanada = personalForm.currentCountry === "Canada";

  return (
    <>
      {view === View.MAIN && (
        <MainView
          {...commonProps}
          onPrevView={onPrevGroup}
          onNextView={() => setView(View.FLOW)}
          initIndex={mainInitIndex}
          prevGroupName={prevGroupName}
        />
      )}
      {view === View.FLOW && (
        <FlowView
          {...commonProps}
          onPrevView={() => {
            setMainInitIndex(1);
            setView(View.MAIN);
          }}
          onNextView={onNextGroup}
          isSkilled={isSkilled}
          isInCanada={isInCanada}
        />
      )}
    </>
  );
};

export default Personal;
