import * as React from "react";
import { ReactElement } from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { IMAGES, UserSideMenu } from "../const";
import { logoutUser } from "../utils";
import { serviceList } from "../const/services";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { Collapse, Link } from "@mui/material";
import { officeList } from "../const/offices";
import { OfficeInformation } from "../../pages/UserProfile/officeInformation";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const HeaderContent = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "white",
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type SideList = {
  label: UserSideMenu | string;
  icon: ReactElement;
  onClick?: () => void;
};

interface Props {
  children: any;
  selectedUserMenu?: UserSideMenu;
  setSelectedMenu: (menu: UserSideMenu) => void;
}

const ProfileHeader = ({
  children,
  selectedUserMenu,
  setSelectedMenu,
}: Props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [subNavServices, setSubNavServices] = React.useState(false);
  const [subNavOffices, setSubNavOffices] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSubNavServices(false);
    setSubNavOffices(false);
  };

  const handleDrawerSubNav = (label: UserSideMenu) => {
    setSelectedMenu(label);
    if (label === UserSideMenu.SERVICES) {
      setSubNavServices(!subNavServices);
      setOpen(true);
    }
    if (label === UserSideMenu.OFFICES) {
      setSubNavOffices(!subNavOffices);
      setOpen(true);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ color: "#0d163e" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderContent>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "#94aebc" }}
            >
              <div className="skillsev-logo">
                <img
                  className="skillsev"
                  style={{ width: "25vh", height: "5vh" }}
                  src={IMAGES.ev_logo}
                  alt="about"
                />
              </div>
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "#94aebc" }}
            >
              Hello, {getCurrentUser()}
            </Typography>
          </HeaderContent>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon fontSize="large" />
            ) : (
              <ChevronLeftIcon fontSize="large" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              selected={selectedUserMenu === UserSideMenu.PROFILE}
              onClick={() => setSelectedMenu(UserSideMenu.PROFILE)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountCircleOutlinedIcon sx={{ fontSize: 25 }} />
              </ListItemIcon>
              <ListItemText
                primary={UserSideMenu.PROFILE}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              selected={selectedUserMenu === UserSideMenu.SERVICES}
              onClick={() => handleDrawerSubNav(UserSideMenu.SERVICES)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <SettingsOutlinedIcon sx={{ fontSize: 25 }} />
              </ListItemIcon>
              <ListItemText
                primary={UserSideMenu.SERVICES}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open && <>{subNavServices ? <ExpandLess /> : <ExpandMore />}</>}
            </ListItemButton>
            <Collapse in={subNavServices} timeout="auto" unmountOnExit>
              {serviceList.map((service) => (
                <List component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }}>
                    <Link
                      href="/"
                      underline="hover"
                      variant="body2"
                      sx={{ marginLeft: "4vh" }}
                    >
                      {service.label}
                    </Link>
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              selected={selectedUserMenu === UserSideMenu.OFFICES}
              onClick={() => handleDrawerSubNav(UserSideMenu.OFFICES)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BusinessOutlinedIcon sx={{ fontSize: 25 }} />
              </ListItemIcon>
              <ListItemText
                primary={UserSideMenu.OFFICES}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open && <>{subNavOffices ? <ExpandLess /> : <ExpandMore />}</>}
            </ListItemButton>

            <Collapse in={subNavOffices} timeout="auto" unmountOnExit>
              {officeList.map((office) => (
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() =>
                      handleDrawerSubNav(office.label as UserSideMenu)
                    }
                  >
                    <ListItemText
                      primary={office.name}
                      sx={{ marginLeft: "4vh" }}
                    />
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </ListItem>
        </List>
        <Divider />
        <List>
          {LOWER_LIST.map(({ label, icon, onClick }) => (
            <ListItem key={label} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={onClick}
              >
                <ListItemIcon
                  sx={{
                    fontSize: 25,
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background: "#F4FCFE",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <DrawerHeader />
        <div>{children}</div>
      </Box>
    </Box>
  );
};

const LOWER_LIST: SideList[] = [
  { label: "Logout", icon: <LogoutIcon />, onClick: logoutUser },
];

function getCurrentUser() {
  const email = localStorage.getItem("currentUser");

  if (email !== null) {
    return email.split("@")?.[0] ?? "Unknown";
  }

  return "Unknown";
}

export default ProfileHeader;
