import { ComponentProps } from "../../components/types";
import { QuestionCheckbox, QuestionTextField } from "../../components";
import { useEffect } from "react";
import { Container } from "../const";
import {
  EducationalCredentialType,
  EducationType,
} from "../../../../common/types";
import { getErrorProps } from "../../utils";

const EducationalCredentialForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<EducationType>) => {
  const value = rawValue as EducationalCredentialType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.isAccredited]);

  const isAccredited = value?.isAccredited ?? null;

  return (
    <Container>
      <QuestionCheckbox<EducationalCredentialType>
        value={value?.isAccredited ?? null}
        setValue={(isAccredited) =>
          isAccredited && setValue({ ...value, isAccredited })
        }
        question={{
          display:
            "Have you requested for an Educational Credential Assessment from an accredited organization such as WES and ICAS?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.isAccredited,
        })}
      />
      {isAccredited === "Yes" && (
        <QuestionTextField<EducationalCredentialType>
          value={value?.equivalent}
          setValue={(equivalent) => setValue({ ...value, equivalent })}
          question={{
            display:
              "What is your education's equivalent in Canada per the Educational Credential Assessment Report?",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.equivalent,
          })}
        />
      )}
    </Container>
  );

  function isFormValid(value: EducationalCredentialType) {
    if (!isAccredited) {
      return false;
    }

    if (isAccredited === "Yes") {
      const completedDate = value?.equivalent ?? null;
      return completedDate !== null && completedDate !== "";
    } else {
      return true;
    }
  }

  function reformatForm(
    form: EducationalCredentialType,
  ): EducationalCredentialType {
    if (form && isAccredited) {
      const { isAccredited } = form;
      if (isAccredited === "Yes") {
        return form;
      } else {
        return { isAccredited } as EducationalCredentialType;
      }
    }

    return form;
  }
};

export default EducationalCredentialForm;
