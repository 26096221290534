import styled from "@emotion/styled";
import { breakpoints, HeaderComponent } from "../../../../common";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useScreenResize } from "../../../../common/hooks";

const HEADER_HEIGHT = 56;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media (min-width: ${breakpoints.mobile}) {
    background-color: #e3f2fd;
  }
`;

const Content = styled.div<{ height: number }>`
  max-height: ${({ height }) => `${height}px`};
  width: 100%;
  overflow-y: auto;
`;

const InnerContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: calc(5 * var(--unit));
  height: 100%;
  padding: calc(5 * var(--unit)) var(--unit);
  overflow-y: auto;

  @media (min-width: ${breakpoints.mobile}) {
    margin: calc(5 * var(--unit)) auto;
    padding: calc(10 * var(--unit));
    max-width: 800px;
    overflow-y: hidden;
  }
`;

const BackButton = styled(Button)`
  height: calc(10 * var(--unit));
  font-size: 24px;
  margin-top: calc(3 * var(--unit));
`;

const Overview = () => {
  const [height, setHeight] = useState<number>(
    window.innerHeight - HEADER_HEIGHT,
  );

  const navigate = useNavigate();

  useScreenResize({
    handleResize: () => setHeight(window.innerHeight - HEADER_HEIGHT),
  });

  return (
    <Container>
      <HeaderComponent pageType="survey" />
      <Content height={height}>
        <InnerContainer variant="outlined">
          <Typography textAlign="center">
            Purpose: We want to understand if you have the trade skills and
            qualifications needed for migration to Canada.
          </Typography>
          <Typography>Survey Summary</Typography>
          <Typography>
            The survey consists of several sections designed to gather essential
            information about you:
          </Typography>
          <Typography>
            Account Information: We'll start with your basic details, like your
            name and contact information.
          </Typography>
          <Typography>
            Personal: We'll ask about your current trade skills, where you're
            living, and any certifications you may have.
          </Typography>
          <Typography>
            Work Experience: We'll look at your experience in your trade,
            including your job title, years of experience, and key
            responsibilities.
          </Typography>
          <Typography>
            Education: This section covers your educational background,
            including any trade-specific training.
          </Typography>
          <Typography>
            Language Proficiency: We'll assess your English language skills and
            any relevant test scores.
          </Typography>
          <Typography>
            Adaptability: Finally, we'll explore how well you might adapt to
            life in Canada, including your familiarity with Canadian culture and
            your willingness to relocate.
          </Typography>
          <Typography>Instructions:</Typography>
          <Typography>
            Time: The survey should take about 10-15 minutes.
          </Typography>
          <Typography>
            Confidentiality: Your responses are confidential and will only be
            used for assessing your application.
          </Typography>
          <Typography>
            Submission: Please complete all questions before submitting the
            survey
          </Typography>
          <BackButton
            className="button"
            onClick={() => {
              navigate("/survey");
              localStorage.removeItem("isSkilled");
            }}
            variant="contained"
            size="large"
          >
            Start Survey
          </BackButton>
        </InnerContainer>
      </Content>
    </Container>
  );
};

export default Overview;
