import React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import styled from "@emotion/styled";
import { breakpoints } from "../const";

const StyledModal = styled(Modal)`
  .MuiModalDialog-root {
    width: 100%;
  }

  @media (min-width: ${breakpoints.mobile}) {
    .MuiModalDialog-root {
      width: 800px;
    }
  }
`;

interface Props {
  open: boolean;
  handleClose: () => void;
  children: any;
  title: string;
}

const AppModal = ({ open, handleClose, children, title }: Props) => {
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalOverflow>
        <ModalDialog aria-labelledby="modal-dialog-overflow">
          <ModalClose />
          <Typography
            id="modal-modal-title"
            variant="h5"
            color="primary"
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Divider />
          {children}
        </ModalDialog>
      </ModalOverflow>
    </StyledModal>
  );
};

export default AppModal;
