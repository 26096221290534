import { createContext, useContext, useState } from "react";
import { State } from "../const";

export interface StatesType {
  states: State[] | undefined;
  setStates: (states: State[]) => void;
}

export const StatesContext = createContext<StatesType>({
  states: [],
  setStates: () => {},
});

export const StatesProvider = ({ children }: { children: any }) => {
  const [states, setStates] = useState<State[]>();

  return (
    <StatesContext.Provider
      value={{
        states,
        setStates,
      }}
    >
      {children}
    </StatesContext.Provider>
  );
};

export function useStates() {
  const context = useContext(StatesContext);

  if (context === null) {
    throw new Error("useStates must be used within a StatesContext.Provider");
  }

  return context;
}
