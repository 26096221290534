import styled from "@emotion/styled";
import { css } from "@emotion/react";

const individualCss = css`
  align-items: center;
  row-gap: calc(2 * var(--unit));
`;

const formCss = css`
  align-items: flex-start;
`;

export const Container = styled.div<{ variant: "individual" | "form" }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 var(--unit);

  ${({ variant }) => (variant === "individual" ? individualCss : formCss)}

  .PhoneInput {
    width: 100%;
  }

  .PhoneInputInput {
    height: calc(8 * var(--unit));
    width: 100%;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0 var(--unit);
  }
`;
