import { ComponentProps } from "./types";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Container } from "./const";
import styled from "@emotion/styled";
import { DatePickerProps } from "@mui/x-date-pickers";
import { ErrorText } from "../../../common";

const StyledContainer = styled(Container)`
  .MuiInputBase-input {
    font-size: 20px;
  }
`;

interface Props {
  error?: boolean;
  helperText?: string;
}

const QuestionDate = <T,>({
  value,
  setValue,
  question,
  componentVariant = "individual",
  error,
  helperText,
  ...props
}: ComponentProps<T> & DatePickerProps<any> & Props) => {
  const { display } = question;

  return (
    <StyledContainer variant={componentVariant}>
      <Typography variant="h5">{display}</Typography>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          sx={{
            width: "100%",
            fontSize: "24px",
            border: error ? "1px solid red" : undefined,
          }}
          value={value ? DateTime.fromISO(value as string) : null}
          onChange={(e) => setValue((e?.toISODate() ?? null) as T[keyof T])}
          maxDate={DateTime.now()}
          {...props}
        />
      </LocalizationProvider>
      {error && <ErrorText helperText={helperText} />}
    </StyledContainer>
  );
};

export default QuestionDate;
