import React, { useEffect, useState } from "react";
import { AppDialog, AppTable, TableColumn, User } from "../../../../common";
import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";
import { setDateToCurrentTimezone } from "../../../../common/utils";
import sortBy from "lodash.sortby";
import { userService } from "../../../../services";
import AddUser from "./AddUser";
import UserDetail from "./UserDetail";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import InventoryIcon from "@mui/icons-material/Inventory";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);

  .add-item {
    align-self: flex-end;
  }
`;

const TableContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: var(--unit);
`;

const COLUMNS: TableColumn[] = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Created at", key: "createdAt" },
  { label: "Updated at", key: "updatedAt" },
];

const SystemUsers = () => {
  const [users, setUsers] = useState<User[]>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [showActionId, setShowActionId] = useState<User>();
  const [userToArchive, setUserToArchive] = useState<User>();

  const sortedData = users ? sortBy(users, "createdAt").reverse() : undefined;

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Container>
      <AddUser fetchUsers={fetchUsers} />
      {selectedUser && (
        <UserDetail
          user={selectedUser}
          closeModal={() => setSelectedUser(undefined)}
          fetchUsers={fetchUsers}
        />
      )}
      <TableContainer>
        <AppTable<User>
          columns={COLUMNS}
          data={sortedData}
          onRowRender={(row) => {
            const { firstName, lastName, email, createdAt, updatedAt } = row;

            return (
              <>
                <TableCell>{firstName}</TableCell>
                <TableCell>{lastName}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>
                  {createdAt ? setDateToCurrentTimezone(createdAt) : ""}
                </TableCell>
                <TableCell size="small" width="200">
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {updatedAt ? setDateToCurrentTimezone(updatedAt) : ""}
                    {showActionId === row ? (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        color="warning"
                        onClick={(e) => {
                          setUserToArchive(showActionId);
                          e.stopPropagation();
                        }}
                      >
                        <InventoryIcon fontSize="small" />
                      </IconButton>
                    ) : null}
                  </Grid>
                </TableCell>
              </>
            );
          }}
          onRowClick={(row) => setSelectedUser(row)}
          setShowActionId={setShowActionId}
        />
      </TableContainer>
      {userToArchive && (
        <AppDialog
          title="Archive User"
          description={`Are you sure you want to archive ${userToArchive.email}?`}
          handleClose={() => setUserToArchive(undefined)}
          onConfirmClick={() => {}}
        />
      )}
    </Container>
  );

  function fetchUsers() {
    userService.getAllUsers().then(setUsers);
  }
};

export default SystemUsers;
