import { useSurveyForm } from "../../../../common";
import { ComponentProps } from "../../components/types";
import { DegreePrograms } from "./DegreePrograms";
import { EducationType } from "../../../../common/types";

const STUDENT_LISTS = [
  "None, or less than secondary high school",
  "Secondary diploma (high school graduation)",
  "Undergraduate",
  "Bachelor's Graduate",
  "Masters Graduate",
  "Doctoral Graduate",
];

const NON_STUDENT_LIST = [
  "None, or less than secondary high school",
  "Secondary diploma (high school graduation)",
  "One-year program at a university, college, trade, or technical school, or other institute",
  "Two-year program at a university, college, trade, or technical school, or other institute",
  "Bachelor's Degree (three or more year program at a university, college, trade or technical school, or other institute",
  "Two or more certificates, diplomas or degrees. One must be for a program of three or more years",
  "Master's degree, or professional degree needed to practice in a licensed profession",
  "Doctoral level university degree (PhD)",
];

const HighestEducationAttainmentForm = (
  props: ComponentProps<EducationType>,
) => {
  const { form } = useSurveyForm();
  const statusInCanada = form.personal?.statusInCanada ?? null;
  const isStudent = statusInCanada === "Study Permit Holder";

  const rawOptions = isStudent ? STUDENT_LISTS : NON_STUDENT_LIST;

  const showBachelor = (educationAttainment: string | null) =>
    educationAttainment?.includes(`Bachelor's`) ?? false;
  const showMasters = (educationAttainment: string | null) =>
    educationAttainment?.includes(`Master`) ?? false;
  const showDoctoral = (educationAttainment: string | null) =>
    educationAttainment?.includes(`Doctoral`) ?? false;

  return (
    <DegreePrograms
      {...props}
      options={rawOptions}
      showVocational={null}
      showBachelor={showBachelor}
      showMasters={showMasters}
      showDoctoral={showDoctoral}
    />
  );
};

export default HighestEducationAttainmentForm;
