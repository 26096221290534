import { DateTime } from "luxon";

export function logoutUser() {
  localStorage.clear();
  window.location.href = "/login";
}

export function setDateToCurrentTimezone(date: string) {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return DateTime.fromISO(date)
    .setZone(browserTimezone)
    .toFormat("MM/dd/yyyy HH:mm");
}
