import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import { Container } from "../const";
import { QuestionCheckbox } from "../../components";
import { AdaptabilityType, JobOfferLmiaType } from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const JobOfferForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<AdaptabilityType>) => {
  const value = rawValue as JobOfferLmiaType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.haveOffer]);

  const haveOffer = value?.haveOffer ?? null;

  return (
    <Container>
      <QuestionCheckbox<JobOfferLmiaType>
        value={value?.haveOffer}
        setValue={(haveOffer) => haveOffer && setValue({ ...value, haveOffer })}
        question={{
          display: "Do you have a valid job offer with an LMIA?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.haveOffer,
        })}
      />
      {haveOffer === "Yes" && (
        <QuestionCheckbox<JobOfferLmiaType>
          value={value?.isCurrentEmployment ?? null}
          setValue={(isCurrentEmployment) =>
            setValue({ ...value, isCurrentEmployment })
          }
          question={{
            display: "Is this your current employment?",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.isCurrentEmployment,
          })}
        />
      )}
    </Container>
  );

  function isFormValid(value: JobOfferLmiaType) {
    if (!haveOffer) {
      return false;
    }

    if (haveOffer === "Yes") {
      return !isFieldEmpty(value?.isCurrentEmployment);
    } else {
      return true;
    }
  }

  function reformatForm(form: JobOfferLmiaType) {
    if (form && haveOffer) {
      if (haveOffer === "Yes") {
        return form;
      } else {
        const { haveOffer } = form;
        return { haveOffer } as JobOfferLmiaType;
      }
    }

    return form;
  }
};

export default JobOfferForm;
