import { ComponentProps } from "./types";
import { DropdownMeta } from "../types";
import Typography from "@mui/material/Typography";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  ErrorText,
  ItemOption,
  useLoading,
  useSurveyForm,
} from "../../../common";
import { useEffect, useState } from "react";
import { Container } from "./const";
import sortBy from "lodash.sortby";
import styled from "@emotion/styled";

const StyledContainer = styled(Container)`
  .MuiInputBase-input {
    font-size: 20px;
  }
`;

const initialOption: ItemOption = {
  label: "Select Option",
  value: -1,
  disabled: true,
};

interface Props {
  error?: boolean;
  helperText?: string;
}

const QuestionAutoComplete = <T,>({
  value,
  setValue,
  question,
  componentVariant = "individual",
  error,
  helperText,
}: ComponentProps<T> & Props) => {
  const { display, meta, optionKey } = question;
  const { options, getOptions } = meta as DropdownMeta;
  const [dropdownOptions, setDropdownOptions] = useState<ItemOption[]>();

  const { showLoading } = useLoading();
  const { states } = useSurveyForm();

  const optionQuestionKey: Record<string, any> = {
    states,
  };

  useEffect(() => {
    setDropdownOptions(undefined);
  }, [question]);

  useEffect(() => {
    if (options) {
      setDropdownOptions(options);
    } else if (optionKey && getOptions) {
      const options = getOptions(optionQuestionKey[optionKey]);
      setDropdownOptions(options);
    }
  }, [options, getOptions]);

  const selectedValue =
    dropdownOptions?.find(
      (option) => option.value.toString() === String(value),
    ) ?? initialOption;

  const sortedOptions = sortBy(dropdownOptions, (option) => option.label);

  useEffect(() => {
    if (!showLoading) {
      if (selectedValue?.value === -1) {
        setValue(null as T[keyof T]);
      }
    }
  }, [selectedValue, showLoading]);

  return (
    <StyledContainer variant={componentVariant}>
      <Typography variant="h5">{display}</Typography>
      {sortedOptions && (
        <Autocomplete
          fullWidth
          value={selectedValue}
          renderInput={(params) => <TextField error={error} {...params} />}
          options={[initialOption, ...sortedOptions]}
          getOptionDisabled={(option) => option.disabled === true}
          onChange={(_, option) => {
            if (option?.value !== -1) {
              setValue(option?.value?.toString() as T[keyof T]);
            } else {
              setValue(null as T[keyof T]);
            }
          }}
        />
      )}
      {error && <ErrorText helperText={helperText} />}
    </StyledContainer>
  );
};

export default QuestionAutoComplete;
