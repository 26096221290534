import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useAlert } from "../contexts";
import Alert from "@mui/material/Alert";

const Container = styled.div`
  position: absolute;
  top: calc(10 * var(--unit));
  left: 50%;
  right: 50%;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  height: auto;

  > div {
    text-wrap: nowrap;
  }
`;

interface Props {
  timeout?: number;
}

const AppAlert = ({ timeout = 3000 }: Props) => {
  const [open, setOpen] = useState(true);
  const { alertType, alertMessage, setAlert } = useAlert();

  const onClose = useCallback(() => {
    setOpen(false);
    setAlert({ message: undefined, type: undefined });
  }, [setOpen, setAlert]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, onClose]);

  return open ? (
    <Container>
      <Content>
        <AlertContent
          type={alertType ?? "error"}
          message={alertMessage ?? ""}
          onClose={onClose}
        />
      </Content>
    </Container>
  ) : null;
};

const AlertContent = ({
  type,
  message,
  onClose,
}: {
  type: string;
  message: string;
  onClose: () => void;
}) => {
  if (type === "success") {
    return (
      <Alert severity="success" onClose={onClose}>
        {message}
      </Alert>
    );
  } else if (type === "error") {
    return (
      <Alert severity="error" onClose={onClose}>
        {message}
      </Alert>
    );
  }

  return null;
};

export default AppAlert;
