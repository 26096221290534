import { doGet } from "../helpers/request-helper";
import { State } from "../common";

const baseURL = "api/states";

const getAll = async (): Promise<State[]> => {
  return doGet(baseURL);
};

export const stateService = {
  getAll,
};
