import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { AppTable, TableColumn, TradeSkill } from "../../../../common";
import TableCell from "@mui/material/TableCell";
import { Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { tradeSkillService } from "../../../../services";
import TradeSkillDetail from "./TradeSkillDetail";
import AddTradeSkill from "./AddTradeSkill";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

const TableContainer = styled.div`
  height: calc(100vh - 200px);
  margin-top: var(--unit);
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--unit);
  max-width: calc(40 * var(--unit));
`;

const COLUMNS: TableColumn[] = [
  { key: "name", label: "Name" },
  { key: "classification", label: "Classification" },
  { key: "exam", label: "Exam" },
  { key: "redSeal", label: "Red Seal" },
  { key: "state.name", label: "State" },
];

const TradeSkills = () => {
  const [allTrades, setAllTrades] = useState<TradeSkill[]>();
  const [filterTrades, setFilterTrades] = useState<TradeSkill[]>();
  const [search, setSearch] = useState<string>("");
  const [selectedTradeSkill, setSelectedTradeSkill] = useState<TradeSkill>();

  useEffect(() => {
    fetchTrades();
  }, []);

  useEffect(() => {
    if (allTrades !== undefined) {
      if (search === "") {
        setFilterTrades(allTrades);
      } else {
        const filter = allTrades.filter((trade) =>
          trade.name.toLowerCase().includes(search.toLowerCase()),
        );
        setFilterTrades(filter);
      }
    }
  }, [search, allTrades]);

  return (
    <Container>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchContainer>
          <Typography>Search</Typography>
          <TextField
            type="search"
            variant="outlined"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </SearchContainer>
        <AddTradeSkill fetchTradeSkills={fetchTrades} />
      </Grid>
      {selectedTradeSkill && (
        <TradeSkillDetail
          tradeSkill={selectedTradeSkill}
          closeModal={() => setSelectedTradeSkill(undefined)}
          fetch={fetchTrades}
        />
      )}
      <TableContainer>
        <AppTable<TradeSkill>
          columns={COLUMNS}
          data={filterTrades}
          onRowRender={(row) => {
            const { id, name, classification, exam, redSeal, state } = row;
            return (
              <React.Fragment key={id}>
                <TableCell>{name}</TableCell>
                <TableCell>{classification}</TableCell>
                <TableCell>{exam ? "Yes" : "No"}</TableCell>
                <TableCell>{redSeal ? "Yes" : "No"}</TableCell>
                <TableCell size="small">
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {state.name}
                  </Grid>
                </TableCell>
              </React.Fragment>
            );
          }}
          onRowClick={(row) => setSelectedTradeSkill(row)}
        />
      </TableContainer>
    </Container>
  );

  function fetchTrades() {
    tradeSkillService.getAll().then(setAllTrades);
  }
};

export default TradeSkills;
