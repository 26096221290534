import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

interface Props {
  activeStep: number;
  groups: string[];
}

const Progress = ({ activeStep, groups }: Props) => {
  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {groups.map((step, i) => (
          <Step key={step}>
            <StepLabel>
              <Typography color={i === activeStep ? "primary" : undefined}>
                {isSmallScreen ? (i === activeStep ? step : "") : step}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Progress;
