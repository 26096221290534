import styled from "@emotion/styled";
import "./UserInformation.scss";
import { IMAGES } from "../../../common";
import { UserBoard } from "../components";
import { Button } from "@mui/material";

const UserInformation = () => {
  return (
    <div className="user-flex-container">
      <div className="user-info-flex-child-container">
        <div className="user-info-header">
          <div className="profile-pic">
            <img
              className="profile-pic-image"
              src={IMAGES.profile_pic}
              alt="about"
            />
          </div>
          <div className="user">
            <div className="user-name">
              Maria Cristina Quipanes Rondina
              <Button>
                <img
                  className="edit-icon-image"
                  src={IMAGES.edit_icon}
                  alt="about"
                />
              </Button>
            </div>
            <div className="user-skill">Welder Technician</div>
          </div>
        </div>
        <div className="user-info">
          <UserBoard />
        </div>
      </div>
      {/* Will just retain during progress-bar implementattion
      <div className="user-progress-flex-child-container">
        <div className="user-progress-content">
          <Typography>Status Progress Board Coming Soon..</Typography>
        </div>
      </div> */}
    </div>
  );
};

export default UserInformation;
