import styled from "@emotion/styled";
import { HeaderComponent, HomeFooter, IMAGES } from "../../common";
import "./AllProfessionals.scss";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
`;

const ImageContainer = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  height: 80vh;
  width: 100%;
`;

const DescriptionContainer = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 70%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    flex: 100%;
    font-size: 5vh;
    font-weight: bold;
    color: #0d163e;
  }

  .description-container {
    display: flex;
    max-width: 50%;
    justify-content: space-evenly;
    gap: 1rem;
    text-align: justify;
    flex-wrap: wrap;

    .description {
      font-size: 3vh;
      color: #94aebc;
    }
  }
`;

const ProceedButton = styled(Button)`
  margin: 5vh auto 3vh auto;
  display: flex;
  align-items: center;
`;

const AllProfessionals = () => {
  const navigate = useNavigate();
  const moveToSurvey = () => {
    navigate("/survey/overview");
  };

  return (
    <Container>
      <HeaderComponent pageType="survey" />
      <ImageContainer>
        <div className="all-professionals-center-container">
          <div className="image-conatianer">
            <div className="image-backgroud">
              <img alt="" className="image" src={IMAGES.all_professionals} />
            </div>
            <div className="image-description">
              <Typography className="description">
                All professions are welcome in Canada
              </Typography>
            </div>
          </div>
        </div>
      </ImageContainer>
      <DescriptionContainer>
        <Typography className="title">Building A Better Future</Typography>
        <div className="description-container">
          <Typography className="description">
            Whether you are a tradesman, a professional, or have experience in
            your field, you can definitely apply to go to Canada. If you are not
            in skilled trades, you can still do these things: Student and PR
            pathway.
          </Typography>
          <Typography className="description">
            Skills EV will equip you to get to your desired destination. Just
            click the button below to get started.
          </Typography>
        </div>
      </DescriptionContainer>
      <ProceedButton size="large" variant="contained" onClick={moveToSurvey}>
        Get Hired
      </ProceedButton>
      {/* <HomeFooter /> */}
    </Container>
  );
};

export default AllProfessionals;
