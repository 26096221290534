import { ServiceType } from "../types";

export const serviceList: ServiceType[] = [
  {
    id: 0,
    link: "/",
    label: "Test Your English",
  },
  {
    id: 1,
    link: "/",
    label: "Get Online Tutoring",
  },
];
