import { QuestionTextField } from "../../components";
import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Container } from "../const";
import styled from "@emotion/styled";
import { AddressType, PersonalType } from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(2 * var(--unit));
`;

const AddressForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
  question,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as AddressType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  return (
    <Container>
      <Typography variant="h5">{question.display}</Typography>
      <QuestionTextField<AddressType>
        question={{ display: "Address 1" }}
        value={value?.address1}
        setValue={(address1) =>
          setValue({ ...value, address1: address1 ?? "" })
        }
        componentVariant="form"
        {...getErrorProps({
          value: value?.address1,
        })}
      />
      <QuestionTextField<AddressType>
        question={{ display: "Address 2" }}
        value={value?.address2}
        setValue={(address2) =>
          setValue({ ...value, address2: address2 ?? "" })
        }
        componentVariant="form"
      />
      <InnerContainer>
        <QuestionTextField<AddressType>
          question={{ display: "City" }}
          value={value?.city}
          setValue={(city) => setValue({ ...value, city: city ?? "" })}
          componentVariant="form"
          {...getErrorProps({
            value: value?.city,
          })}
        />
        <QuestionTextField<AddressType>
          question={{ display: "State" }}
          value={value?.state}
          setValue={(state) => setValue({ ...value, state: state ?? "" })}
          componentVariant="form"
          {...getErrorProps({
            value: value?.state,
          })}
        />
        <QuestionTextField<AddressType>
          question={{ display: "Postal" }}
          value={value?.postal}
          setValue={(postal) => setValue({ ...value, postal: postal ?? "" })}
          componentVariant="form"
          {...getErrorProps({
            value: value?.postal,
          })}
        />
      </InnerContainer>
    </Container>
  );

  function isFormValid(form: AddressType) {
    const { address1, city, state, postal } = form;

    return (
      !isFieldEmpty(address1) &&
      !isFieldEmpty(city) &&
      !isFieldEmpty(state) &&
      !isFieldEmpty(postal)
    );
  }
};

export default AddressForm;
