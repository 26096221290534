import React, { useState } from "react";
import { useAlert, useLoading, useStates } from "../../../../common";
import { AddItem } from "../../common";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import { Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import { tradeSkillService } from "../../../../services";

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

const AddTradeSkill = ({
  fetchTradeSkills,
}: {
  fetchTradeSkills: () => void;
}) => {
  const [name, setName] = useState<string>("");
  const [classification, setClassification] = useState<string>("");
  const [isExam, setIsExam] = useState<boolean>(false);
  const [isRedSeal, setIsRedSeal] = useState<boolean>(false);
  const [state, setState] = useState<number>();

  const { setShowLoading } = useLoading();
  const { setAlert } = useAlert();
  const { states } = useStates();

  return (
    <AddItem
      label="Add Trade Skill"
      modalTitle="Add Trade Skill"
      onSave={onSave}
      disableButton={!isValid()}
    >
      <FormContent>
        <FormControl>
          <Typography>Name</Typography>
          <TextField
            size="small"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Typography>Classification</Typography>
          <Select
            value={classification}
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setClassification(e.target.value)}
          >
            <MenuItem value="Compulsory">Compulsory</MenuItem>
            <MenuItem value="Non Compulsory">Non Compulsory</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox onChange={(e) => setIsExam(e.target.checked)} />}
          label="Examination"
        />
        <FormControlLabel
          control={
            <Checkbox onChange={(e) => setIsRedSeal(e.target.checked)} />
          }
          label="Red Seal"
        />
        <FormControl>
          <Typography>States</Typography>
          <Select
            value={state}
            variant="outlined"
            fullWidth
            size="small"
            onChange={(e) => setState(Number(e.target.value))}
          >
            {states?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormContent>
    </AddItem>
  );

  function isValid() {
    return name !== "" && classification !== "" && state !== undefined;
  }

  function clearAll() {
    setName("");
    setClassification("");
    setIsExam(false);
    setIsRedSeal(false);
    setState(undefined);
  }

  function onSave() {
    setShowLoading(true);

    const tradeSkill = {
      name,
      classification,
      exam: isExam,
      redSeal: isRedSeal,
      stateId: state ?? 0,
    };

    tradeSkillService
      .addTradeSkill(tradeSkill)
      .then(() => {
        fetchTradeSkills();
        clearAll();
        setAlert({ message: "Trade Skill Saved!", type: "success" });
      })
      .catch(() => setAlert({ message: "Error occurred!", type: "error" }))
      .finally(() => setShowLoading(false));
  }
};

export default AddTradeSkill;
