import { Link, Typography } from "@mui/material";
import { IMAGES } from "../../../../common";
import "./about.scss";

interface Props {
  divRef: React.Ref<HTMLDivElement>;
}
  
const AboutSection = ({ divRef }: Props) => {

  return (
    <div className="about-container">
      <div className="about-center-flex-container" ref={divRef}>
        <div className="about-content">
          <Typography className="about-title">Get To Know Us</Typography>
          <Typography className="about-sub-title">
            Complete Consultation Solutions in One Place
          </Typography>
          <p className="about-description">
            SkillsEV is a platform designed to match Canadian employers with
            skilled individuals globally. This is made publicly available to
            provide boundless opportunities for skilled tradespeople who have
            worked or currently working in one of the following sectors:
          </p>
          <ul className="about-description-option">
            <li>
              Construction. Promotes welders, carpenters, electricians,
              steamfitters/pipefitters, heavy equipment operators, plumbers, and
              many more
            </li>
            <li>
              Transportation. Highlights automotive, motorcycle and heady-duty
              equipment technicians, etc.
            </li>
            <li>
              Manufacturing and Industrial. Includes millwrights, metal
              fabricators and more
            </li>
            <li>
              Services. Features bakers, cooks, hairstylists, landscape
              horticulturists, estheticians, massage therapists and more
            </li>
            <li>
              Information and Digital Technology. Focuses on instrumentation,
              machinists, and control technicians, etc.
            </li>
            <li>
              Is your occupation/profession not included in the list? There are
              multiple options for you! Just
              <Link
                className="home-learn-more"
                href={"allProfessionals"}
                variant="body2"
                underline="hover"
              >
                {" "}
                click here.
              </Link>
            </li>
          </ul>
        </div>
        <div className="about-image-container">
          <img className="aboutImage" src={IMAGES.ev_staff} alt="about" />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;

  