import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { EBreakpoints } from "../../../common";

const HeaderTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 60%;
`;

interface Props {
  key: string | null;
  title: string | null;
  chipLabel: string;
  data: { label: string; value: string | null | ReactElement }[];
  onEditClicked: () => void;
  onRemoveClicked: () => void;
}

const InfoCard = ({
  key,
  title,
  chipLabel,
  data,
  onEditClicked,
  onRemoveClicked,
}: Props) => {
  const isMobile = useMediaQuery(EBreakpoints.Mobile);

  return (
    <Accordion key={key} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HeaderTypography
            fontWeight={700}
            fontSize={isMobile ? undefined : 20}
          >
            {title}
          </HeaderTypography>
          <Chip
            label={chipLabel}
            variant="outlined"
            color="primary"
            size="small"
          />
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {data.map(({ label, value }) => (
          <Typography>
            <b>{label}</b>: {value}
          </Typography>
        ))}
        <Grid container display="flex" justifyContent="flex-end" columnGap={1}>
          <Button size="small" onClick={onEditClicked} variant="contained">
            Edit
          </Button>
          <Button
            size="small"
            color="error"
            onClick={onRemoveClicked}
            variant="outlined"
          >
            Remove
          </Button>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default InfoCard;
