import { Button, Typography } from "@mui/material";
import { IMAGES, provincesList } from "../../../../common";
import "./home.scss";
import { useNavigate } from "react-router-dom";
import { HomeCard } from "../../components";
import Slider from "react-slick";
interface Props {
    divRef: React.Ref<HTMLDivElement>;
}

const HomeSection = ({ divRef }: Props) => {
    const navigate = useNavigate();
    const moveToSurvey = () => {
      navigate("survey/overview");
    };
    const backgroundImage = { backgroundImage: `url(${IMAGES.imageOne})` };
    var settings = {
        arrows: true,
        className: "card-home-center",
        dots: true,
        infinite: false,
        speed: 300,
        responsive: [
            {
            breakpoint: 2001,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
            },
            },
            {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 3,
            },
            },
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
            },
            {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
            },
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                dots: false,
            },
            },
        ],
    };
    

  return (
    <><div className="home-container" style={backgroundImage}>
          <div className="home-center" ref={divRef}>
              <div className="home-title">
                  <Typography className="home-section-title">
                      Opportunity awaits
                  </Typography>
              </div>
              <div className="home-description">
                  <Typography className="home-section-title">
                      Strive in a Skilled Trade Profession
                  </Typography>
              </div>
              <div className="home-button">
                  <Button
                      className="home-section-button"
                      size="large"
                      onClick={moveToSurvey}
                      variant="contained"
                  >
                      Get Hired
                  </Button>
              </div>
          </div>
      </div><div className="card">
              <div className="card-description">
                  <Typography className="card-title">
                      Skill trades you may what to know in Canada
                  </Typography>
                  <Typography className="sub-card-title">Provinces</Typography>
              </div>
              <Slider {...settings}>
                  {provincesList.map((province) => (
                      <HomeCard type="home" value={province} key={province.id} />
                  ))}
              </Slider>
          </div></>
  );
};

export default HomeSection;