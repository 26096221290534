import { QuestionComponentType } from "../../enum";
import { QuestionType } from "../../types";
import countries from "../../jsons/countries.json";
import { ItemOption, State, useSurveyForm } from "../../../../common";
import {
  AddressForm,
  MaritalStatusForm,
  OtherCountryTradeForm,
  ProfessionalLicensingForm,
  RedSealForm,
  TradeCertificationForm,
  TradeSkillForm,
} from "../../forms";
import {
  AddressType,
  MaritalStatusType,
  PartialRecord,
  PersonalType,
  ProfessionalLicensingType,
  RedSealCertType,
  SkillCountryTradeType,
  TradeCertificationType,
  TradeSkillType,
} from "../../../../common/types";
import { Typography } from "@mui/material";
import { formatDate } from "../../utils";
import groupBy from "lodash.groupby";

type PersonalQuestionType = {
  main: PartialRecord<keyof PersonalType, QuestionType>;
  inTradesAndInCanada: PartialRecord<keyof PersonalType, QuestionType>;
  inTradesAndNotInCanada: PartialRecord<keyof PersonalType, QuestionType>;
  notInTradesAndInCanada: PartialRecord<keyof PersonalType, QuestionType>;
  notInTradesAndNotInCanada: PartialRecord<keyof PersonalType, QuestionType>;
};

const commonQuestions: PartialRecord<keyof PersonalType, QuestionType> = {
  birthDate: {
    display: "Birthdate",
    customAnswerDisplay: (answer: string) => formatDate(answer),
    componentType: QuestionComponentType.DATE,
  },
  currentAddress: {
    display: "Complete Home Address",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: AddressForm,
      displayAnswer: (form: AddressType) => {
        const { address1, address2, state, city, postal } = form;
        return (
          <Typography>{`${address1} ${address2 ?? ""}, ${city} ${state}, ${postal}`}</Typography>
        );
      },
    },
  },
  maritalStatus: {
    display: "Marital Status",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: MaritalStatusForm,
      displayAnswer: (form: MaritalStatusType) => {
        const { status, separationProof, isPartnerInCanada } = form;
        return (
          <>
            <Typography>Status: {status}</Typography>
            {!!separationProof && (
              <Typography>Proof of separation: {separationProof}</Typography>
            )}
            {!!isPartnerInCanada && (
              <Typography>
                Will your spouse or common-law partner come with you to Canada?:{" "}
                {isPartnerInCanada}
              </Typography>
            )}
          </>
        );
      },
    },
  },
};

const notInTradesCommonQuestions: PartialRecord<
  keyof PersonalType,
  QuestionType
> = {
  professionalLicensing: {
    display: "Professional Licensing",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: ProfessionalLicensingForm,
      displayAnswer: (form: ProfessionalLicensingType) => {
        const { isProfessional, wantToBeLicensed, isCareGiver } = form;
        return (
          <>
            <Typography>
              Are you an Engineer, Nurse, or Teacher?: {isProfessional}
            </Typography>
            {!!wantToBeLicensed && (
              <Typography>Want to be licensed: {wantToBeLicensed}</Typography>
            )}
            {!!isCareGiver && (
              <Typography>Is care giver: {isCareGiver}</Typography>
            )}
          </>
        );
      },
    },
  },
  wantToBeAssessedForPr: {
    display:
      "Do you want your qualifications to be assessed for permanent residency (free).",
    componentType: QuestionComponentType.CHECKBOX,
  },
};

const statusInCanada = {
  display: "What's your current status in Canada?",
  componentType: QuestionComponentType.DROPDOWN,
  meta: {
    options: [
      "Study Permit Holder",
      "PGWP Holder",
      "Open to Work Permit Holder",
      "Tourist Visa Holder",
      "Closed Work Permit Holder",
    ].map((option) => ({ label: option, value: option })),
  },
};

export const personalQuestions: PersonalQuestionType = {
  main: {
    inTradeSkill: {
      display: "Are you skilled trades?",
      componentType: QuestionComponentType.CHECKBOX,
    },
    currentCountry: {
      display: "What country are you currently in?",
      componentType: QuestionComponentType.AUTO_COMPLETE,
      meta: {
        options: (countries as string[]).map((country) => ({
          label: country,
          value: country,
        })),
      },
    },
  },
  inTradesAndInCanada: {
    canadaProvince: {
      display: "What province are you in?",
      componentType: QuestionComponentType.AUTO_COMPLETE,
      optionKey: "states",
      meta: {
        getOptions: (states: State[]) =>
          states.map(
            (state) =>
              ({
                label: state.name,
                value: state.id ?? -1,
              }) as ItemOption,
          ),
        options: null,
      },
    },
    tradeSkill: {
      display: "What skilled trades are you in?",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: TradeSkillForm,
        displayAnswer: (form: TradeSkillType) => (
          <DisplayTradeSkills form={form} />
        ),
      },
    },
    redSealCert: {
      display: "Red Seal Certification",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: RedSealForm,
        displayAnswer: (form: RedSealCertType) => {
          const { isHolder, completedDate } = form;
          return (
            <>
              <Typography>
                Are you a Red Seal Certificate Holder?: {isHolder}
              </Typography>
              {!!isHolder && (
                <Typography>
                  When did you complete your certificate?:{" "}
                  {formatDate(completedDate)}
                </Typography>
              )}
            </>
          );
        },
      },
    },
    statusInCanada,
    ...commonQuestions,
  },
  inTradesAndNotInCanada: {
    skillCountryTrade: {
      display: "Other country skill trade",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: OtherCountryTradeForm,
        displayAnswer: (form: SkillCountryTradeType) => {
          const { tradeSkill, specified } = form;

          return (
            <>
              <Typography>
                What skilled trades are you in?: {tradeSkill}
              </Typography>
              {!!specified && (
                <Typography>Please specify trade skill: {specified}</Typography>
              )}
            </>
          );
        },
      },
    },
    tradeCertification: {
      display: "Skill Trade Certification",
      componentType: QuestionComponentType.FORM,
      meta: {
        component: TradeCertificationForm,
        displayAnswer: (form: TradeCertificationType) => {
          const { haveCertification, certification } = form;
          return (
            <>
              <Typography>
                Do you have any trades certifications?: {haveCertification}
              </Typography>
              {!!certification && (
                <Typography>
                  Identify your certification: {certification}
                </Typography>
              )}
            </>
          );
        },
      },
    },
    ...commonQuestions,
  },
  notInTradesAndInCanada: {
    ...notInTradesCommonQuestions,
    statusInCanada,
    ...commonQuestions,
  },
  notInTradesAndNotInCanada: {
    ...notInTradesCommonQuestions,
    ...commonQuestions,
    activeContactNumber: {
      display: "Active contact Number",
      componentType: QuestionComponentType.NUMBER_INPUT,
    },
  },
};

const DisplayTradeSkills = ({ form }: { form: TradeSkillType }) => {
  const { states: allStates, tradeSkills: allTradeSkills } = useSurveyForm();

  const { skills, otherSkills } = form;
  const groupSkillsByState = groupBy(skills, "stateId");

  return (
    <>
      {Object.entries(groupSkillsByState).map(([stateGiven, tradeSkills]) => {
        const stateName =
          allStates.find((state) => {
            return state.id === Number(stateGiven);
          })?.name ?? "";

        return (
          <div>
            <Typography fontWeight={700}>{stateName}</Typography>
            <div>
              {tradeSkills.map((trade) => {
                const tradeSkill = allTradeSkills.find(
                  (tradeSkill) => tradeSkill.id === trade.tradeSkillId,
                );

                return tradeSkill ? (
                  <Typography>{tradeSkill.name}</Typography>
                ) : null;
              })}
            </div>
          </div>
        );
      })}
      {otherSkills && otherSkills.length !== 0 && (
        <div>
          <Typography fontWeight={700}>Others</Typography>
          <div>
            {otherSkills.map((skill) => (
              <Typography>{skill}</Typography>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
