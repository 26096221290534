import * as React from "react";
import { ReactElement, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { TableColumn } from "../const";
import sortBy from "lodash.sortby";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Grid from "@mui/material/Grid";

const StyledPaper = styled.div`
  border: 1px solid #a4b0be;
  background: white;
  height: 100%;
`;

const CountContainer = styled.div`
  margin-top: var(--unit);
  display: flex;
`;

const tableRowStyle = {
  "&:hover": {
    "> td": {
      color: "var(--color-text-primary)",
    },
  },
};

interface Props<T> {
  columns: TableColumn[];
  data: T[] | undefined;
  onRowRender: (row: T) => ReactElement;
  onRowClick?: (row: T) => void;
  setShowActionId?: (row: T | undefined) => void;
  initialSort?: keyof T;
}

const AppTable = <T,>({
  columns,
  data,
  onRowRender,
  onRowClick,
  setShowActionId,
  initialSort,
}: Props<T>) => {
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [sortKey, setSortKey] = useState<keyof T | undefined>(
    initialSort ? initialSort : (columns[0]?.key as keyof T),
  );

  return (
    <>
      <TableContainer component={StyledPaper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map(({ label, key, disableSorting }) => (
                <TableCell
                  key={key}
                  onClick={() => {
                    if (key && !disableSorting) {
                      if (sortKey === key) {
                        setSortAsc(!sortAsc);
                      } else {
                        setSortKey(key as keyof T);
                      }
                    }
                  }}
                >
                  <Grid container display="flex" columnGap="var(--unit)">
                    <Typography>
                      <b>{label}</b>
                    </Typography>
                    {sortKey !== undefined && sortKey === key && (
                      <>
                        {sortAsc ? (
                          <ArrowDownwardIcon
                            fontSize="small"
                            color="disabled"
                          />
                        ) : (
                          <ArrowUpwardIcon fontSize="small" color="disabled" />
                        )}
                      </>
                    )}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data === undefined ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              sort(data, sortKey, sortAsc)?.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  sx={tableRowStyle}
                  onClick={() => onRowClick && onRowClick(row)}
                  onMouseEnter={() => setShowActionId && setShowActionId(row)}
                  onMouseLeave={() =>
                    setShowActionId && setShowActionId(undefined)
                  }
                >
                  {onRowRender(row)}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CountContainer>
        <Typography>Data count: {data?.length ?? "-"}</Typography>
      </CountContainer>
    </>
  );

  function sort(data: T[], key: keyof T | undefined, asc: boolean) {
    if (!key) {
      return data;
    }

    const sorted = sortBy(
      data,
      [
        (obj) => {
          if (typeof obj[key] === "string") {
            return String(obj[key]).toLowerCase();
          } else {
            return obj[key];
          }
        },
      ],
      key,
    );
    return asc ? sorted : sorted.reverse();
  }
};

export default AppTable;
