import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "../../style/common.scss";
import IMAGES from "../const/images";
import { useNavigate } from "react-router-dom";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  onMenuClicked?: (menu: string) => void;
  pageType: string;
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ["Home", "Goals", "About Us", "Contact", "Login"];

export default function DrawerAppBar({
  onMenuClicked,
  pageType,
  window,
}: Props) {
  const navigate = useNavigate();
  const moveToHome = () => {
    navigate("/");
  };
  const moveToLogin = () => {
    navigate("login");
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <div className="skillsev-logo">
          <img
            className="skillsev"
            style={{ maxWidth: "25vh", maxHeight: "5vh" }}
            src={IMAGES.ev_logo}
            alt="about"
          />
        </div>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item}
            disablePadding
            onClick={() => onMenuClicked && onMenuClicked(item)}
          >
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        className="header"
        component="nav"
        sx={{ backgroundColor: "white", color: "black", position: "unset" }}
      >
        <Toolbar className="header-style">
          {pageType === "home" && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            className="logo"
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { sm: "block", color: "black" } }}
          >
            <Button className="skillsev-logo" onClick={moveToHome}>
              <img className="skillsev" src={IMAGES.ev_logo} alt="about" />
            </Button>
          </Typography>
          {pageType === "home" && (
            <div className="navMenu">
              <Box
                className="navButton"
                sx={{ display: { xs: "none", sm: "flex" } }}
              >
                {navItems.map((item) => (
                  <>
                    {item === "Login" && (
                      <>
                        <Button
                          key={item}
                          sx={{
                            mx: { sm: 1, md: 1 },
                            flexShrink: 0,
                            color: "black",
                          }}
                          onClick={moveToLogin}
                        >
                          <div className="login-logo">
                            <img
                              className="login"
                              style={{ width: "5vh", height: "5vh" }}
                              src={IMAGES.login_logo}
                              alt="login"
                            />
                          </div>
                        </Button>
                      </>
                    )}

                    {item !== "Login" && (
                      <Button
                        key={item}
                        sx={{
                          mx: { sm: 1, md: 1 },
                          flexShrink: 0,
                          color: "black",
                        }}
                        onClick={() => onMenuClicked && onMenuClicked(item)}
                      >
                        <div className="item">{item}</div>
                      </Button>
                    )}
                  </>
                ))}
              </Box>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none", color: "black" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              color: "black",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
