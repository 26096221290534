import { ComponentProps } from "./types";
import { FormMeta } from "../types";
import { Container } from "./const";
import styled from "@emotion/styled";

const StyledContainer = styled(Container)`
  padding: 0;
`;

const QuestionForm = <T,>({
  value,
  setValue,
  question,
  setIsInvalid,
  componentVariant = "individual",
}: ComponentProps<T>) => {
  const { meta } = question;
  const { component: Component } = meta as FormMeta;

  return (
    <StyledContainer variant={componentVariant}>
      <Component
        value={value}
        setValue={setValue}
        question={question}
        setIsInvalid={setIsInvalid}
      />
    </StyledContainer>
  );
};

export default QuestionForm;
