import { DateTime } from "luxon";

const ADAPTABILITY_VISA_STATUS = [
  "Study Permit Holder",
  "PGWP Holder",
  "Closed Work Permit Holder",
];

export function isAdaptabilityVisaStatusValid(statusInCanada: string | null) {
  if (statusInCanada) {
    return ADAPTABILITY_VISA_STATUS.includes(statusInCanada);
  }
  return false;
}

export function formatDate(date: string | null) {
  if (!date) {
    return "";
  }

  return DateTime.fromISO(date).toFormat("MMM dd, yyyy");
}

export function computeYears({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  return Math.floor(end.diff(start, "years").years);
}

export function isFieldEmpty(value: string | undefined | null) {
  return (value?.trim() ?? "") === "";
}

export function getErrorProps({
  value,
  onErrorCheck,
  errorMessage = "This is required.",
}: {
  value: string | undefined | null | number;
  onErrorCheck?: () => boolean;
  errorMessage?: string;
}) {
  if (onErrorCheck) {
    return {
      error: onErrorCheck(),
      helperText: onErrorCheck() ? errorMessage : undefined,
    };
  }

  const isError: boolean =
    isFieldEmpty(value?.toString()) || value === "-1" || value === -1 || !value;

  return {
    error: isError,
    helperText: isError ? errorMessage : undefined,
  };
}
