import { Applicant, AppModal } from "../../../../common";
import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const Content = styled.div`
  padding: calc(2 * var(--unit)) var(--unit);
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

interface Props {
  applicant: Applicant;
  closeModal: () => void;
}

const ApplicantDetail = ({ applicant, closeModal }: Props) => {
  const { id, email, surveyAnswer } = applicant;

  return (
    <AppModal
      open={true}
      handleClose={closeModal}
      title="Applicant Survey Form"
    >
      <Content>
        <Typography>{JSON.stringify(surveyAnswer)}</Typography>
        <Footer>
          <Button variant="outlined" onClick={closeModal}>
            Close
          </Button>
        </Footer>
      </Content>
    </AppModal>
  );
};

export default ApplicantDetail;
