import { CardType } from "../types/types";
import IMAGES from "../../common/const/images";

export const provincesList: CardType[] = [
  {
    id: 1,
    name: "Ontario",
    subName: "ON",
    description:
      "There are over 140 skilled trades offering career opportunities across key industries including mechanical, electrical, automotive, manufacturing, building, and service.",
    image: IMAGES.icon_skill_one,
    url: "https://www.skilledtradesontario.ca/about-trades/trades-information/",
  },
  {
    id: 2,
    name: "Alberta",
    subName: "AB",
    description:
      "It is located in the west-central part of Canada with over 50 designated trades spanning industries like mechanical, electrical, automotive, manufacturing, building, and service.",
    image: IMAGES.icon_skill_two,
    url: "https://tradesecrets.alberta.ca/trades-in-alberta/designated-trades-profiles/",
  },
  {
    id: 3,
    name: "British Columbia",
    subName: "BC",
    description:
      "There are more than 80 skilled trade occupations and in-demand trades include cooks, automotive service technicians, and electricians, with 83,000 job openings expected in the next decade.",
    image: IMAGES.icon_skill_three,
    url: "https://skilledtradesbc.ca/find-your-trade",
  },
  {
    id: 4,
    name: "New Brunswick",
    subName: "NB",
    description:
      "There is a high demand for skilled trades such as Automotive Service Technician, Carpenter, Hair Stylist, Industrial Mechanic (Millwright), and Truck and Transport Mechanic. A large shortage of skilled trades professionals is projected over the next ten years.",
    image: IMAGES.icon_skill_four,
    url: "https://nstrades.ca/",
  },
  {
    id: 5,
    name: "Nova Scotia",
    subName: "NS",
    description:
      "There are 70 skilled trades organized into four industry sectors: Construction, Industrial/Manufacturing, Motive Power, and Service.",
    image: IMAGES.icon_skill_five,
    url: "https://www.skillscanadanb.com/skilledtrades",
  },
  {
    id: 6,
    name: "Prince Edward Island",
    subName: "PEI",
    description:
      "The smallest and most densely populated province with three counties: Prince, Queens, and Kings. The in-demand trades include Automotive Service Technician, Carpenter, Construction Electrician, Plumber, and Welder.",
    image: IMAGES.icon_skill_six,
    url: "https://www.gov.nl.ca/atcd/designated-trades/list-of-designated-trades/",
  },
  {
    id: 7,
    name: "Newfoundland and Labrador",
    subName: "NL",
    description:
      "The in-demand trades include Automotive Service Technician, Carpenter, Construction Electrician, Heavy-Duty Equipment Technician, and Industrial Mechanic (Millwright).",
    image: IMAGES.icon_skill_seven,
    url: "https://www.princeedwardisland.ca/en/information/workforce-advanced-learning-and-population/designated-trades-in-pei",
  },
  {
    id: 8,
    name: "Saskatchewan",
    subName: "SK",
    description:
      "The in-demand trades include Carpenter, Construction Electrician, Refrigeration and Air Conditioning Mechanic, Sheet Metal Worker, and Welder.",
    image: IMAGES.icon_skill_eight,
    url: "https://www.gov.mb.ca/aesi/apprenticeship/discover/mbtrades/index.html",
  },
  {
    id: 9,
    name: "Manitoba",
    subName: "MB",
    description:
      "Eighth largest province by area (650,000 km²). The in-demand trades include Cook, Industrial Electrician, Industrial Mechanic (Millwright), Painter and Decorator, and Welder.",
    image: IMAGES.icon_skill_nine,
    url: "https://saskapprenticeship.ca/designated-trades-searchable/",
  },
  {
    id: 10,
    name: "Quebec",
    subName: "QC",
    description:
      "The largest Canadian province in the east, Quebec is bilingual with French as the official language and significant English presence. Key trades include Cook, Hair Stylist, Heavy-Duty Equipment Technician, Industrial Mechanic (Millwright), and Plumber.",
    image: IMAGES.icon_skill_ten,
    url: "https://www.quebec.ca/en/employment/learn-trade-occupation/practising-regulated-trade-occupation/learn-regulated-trades-occupations",
  },
];
