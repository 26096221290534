import {
  QuestionAutoComplete,
  QuestionCheckbox,
  QuestionDate,
  QuestionDropdown,
  QuestionTextField,
} from "../../components";
import { ComponentProps } from "../../components/types";
import { QuestionComponentType } from "../../enum";
import { Container } from "../const";
import { stateService } from "../../../../services";
import { ItemOption, State, useLoading } from "../../../../common";
import { useEffect, useState } from "react";
import {
  CommonLawEducationType,
  EducationType,
} from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const DURATION_OPTIONS = ["1 year", "2 years", "3 years", "4 years"];

const LEVEL_STUDIES = [
  "Diploma",
  "Graduate Certificate",
  "Advanced Diploma",
  "Post-graduate Certificate",
  "Masters",
  "Doctoral",
];

const CommonLawEducationForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<EducationType>) => {
  const value = rawValue as CommonLawEducationType;
  const [states, setStates] = useState<State[]>([]);

  const { setShowLoading } = useLoading();

  useEffect(() => {
    setShowLoading(true);
    stateService
      .getAll()
      .then(setStates)
      .finally(() => setShowLoading(false));
  }, []);

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.studiedInCanada]);

  const studiedInCanada = value?.studiedInCanada ?? null;

  return (
    <Container>
      <QuestionCheckbox<CommonLawEducationType>
        value={value?.studiedInCanada}
        setValue={(studiedInCanada) =>
          studiedInCanada && setValue({ ...value, studiedInCanada })
        }
        question={{
          display: "Have you studied in Canada?",
          componentType: QuestionComponentType.CHECKBOX,
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.studiedInCanada,
        })}
      />
      {studiedInCanada === "Yes" && (
        <>
          <QuestionAutoComplete<CommonLawEducationType>
            value={value?.currentProvince}
            setValue={(currentProvince) =>
              setValue({ ...value, currentProvince })
            }
            question={{
              display: "What current province are you in?",
              meta: {
                options: states.map(
                  (state) =>
                    ({
                      label: state.name,
                      value: state.id?.toString(),
                    }) as ItemOption,
                ),
              },
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.currentProvince,
            })}
          />
          <QuestionTextField<CommonLawEducationType>
            value={value?.canadianSchool}
            setValue={(canadianSchool) =>
              setValue({ ...value, canadianSchool })
            }
            question={{ display: "Canadian School Name" }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.canadianSchool,
            })}
          />
          <QuestionTextField<CommonLawEducationType>
            value={value?.programGraduated}
            setValue={(programGraduated) =>
              setValue({ ...value, programGraduated })
            }
            question={{ display: "Program/Course graduated" }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.programGraduated,
            })}
          />
          <QuestionDropdown<CommonLawEducationType>
            value={value?.durationOfStudy}
            setValue={(durationOfStudy) =>
              setValue({ ...value, durationOfStudy })
            }
            question={{
              display: "Duration of Study",
              meta: {
                options: DURATION_OPTIONS.map((option) => ({
                  label: option,
                  value: option,
                })),
              },
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.durationOfStudy,
            })}
          />
          <QuestionDropdown<CommonLawEducationType>
            value={value?.levelOfStudy}
            setValue={(levelOfStudy) => setValue({ ...value, levelOfStudy })}
            question={{
              display: "Level of study",
              meta: {
                options: LEVEL_STUDIES.map((option) => ({
                  label: option,
                  value: option,
                })),
              },
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.levelOfStudy,
            })}
          />
          <QuestionDate<CommonLawEducationType>
            value={value?.completionDateOfProgram}
            setValue={(completionDateOfProgram) =>
              setValue({ ...value, completionDateOfProgram })
            }
            question={{
              display: "Completion Date of Program",
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.completionDateOfProgram,
            })}
          />
        </>
      )}
    </Container>
  );

  function isFormValid(value: CommonLawEducationType) {
    if (!studiedInCanada) {
      return false;
    }

    if (studiedInCanada === "Yes") {
      const {
        currentProvince,
        canadianSchool,
        programGraduated,
        durationOfStudy,
        levelOfStudy,
        completionDateOfProgram,
      } = value;

      return (
        !isFieldEmpty(currentProvince) &&
        !isFieldEmpty(canadianSchool) &&
        !isFieldEmpty(programGraduated) &&
        !isFieldEmpty(durationOfStudy) &&
        !isFieldEmpty(levelOfStudy) &&
        completionDateOfProgram !== null
      );
    } else {
      return true;
    }
  }

  function reformatForm(form: CommonLawEducationType): CommonLawEducationType {
    if (form && studiedInCanada) {
      if (studiedInCanada === "No") {
        const { studiedInCanada } = form;
        return {
          studiedInCanada,
        } as CommonLawEducationType;
      } else {
        return form;
      }
    }

    return form;
  }
};

export default CommonLawEducationForm;
