import React, { useEffect } from "react";
import { AppHeader, SideMenu, useStates } from "../../common";
import { Applicants, States, SystemUsers, TradeSkills } from "./components";
import { useNavigate, useParams } from "react-router-dom";
import { stateService } from "../../services";
import sortBy from "lodash.sortby";

const Dashboard = () => {
  const { menu } = useParams();
  const sideMenu = menu as SideMenu;
  const navigate = useNavigate();

  const { setStates } = useStates();

  useEffect(() => {
    stateService.getAll().then((states) => setStates(sortBy(states, "name")));
  }, [setStates]);

  return (
    <AppHeader
      selectedMenu={sideMenu}
      setSelectedMenu={(menu) => navigate(`/dashboard/${menu}`)}
    >
      {renderMenu(sideMenu)}
    </AppHeader>
  );
};

function renderMenu(selectedMenu: SideMenu) {
  switch (selectedMenu) {
    case SideMenu.SYSTEM_USERS:
      return <SystemUsers />;
    case SideMenu.APPLICANTS:
      return <Applicants />;
    case SideMenu.STATES:
      return <States />;
    case SideMenu.TRADE_SKILLS:
      return <TradeSkills />;
    default:
      return <SystemUsers />;
  }
}

export default Dashboard;
