import { QuestionType } from "../../types";
import { QuestionComponentType } from "../../enum";
import { LanguageType, TakenEnglishType } from "../../../../common/types";
import { Typography } from "@mui/material";
import { EnglishForm } from "../../forms";

export const languageQuestions: Record<keyof LanguageType, QuestionType> = {
  haveTakenEnglish: {
    display: "English proficiency",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: EnglishForm,
      displayAnswer: (form: TakenEnglishType) => {
        const {
          takenEnglish,
          testTaken,
          listening,
          writing,
          speaking,
          reading,
        } = form;

        return (
          <>
            <Typography>
              Have you taken an English proficiency test?: {takenEnglish}
            </Typography>
            {takenEnglish === "Yes" && (
              <>
                <Typography>Test taken: {testTaken}</Typography>
                <Typography>Speaking: {speaking}</Typography>
                <Typography>Listening: {listening}</Typography>
                <Typography>Writing: {writing}</Typography>
                <Typography>Reading: {reading}</Typography>
              </>
            )}
          </>
        );
      },
    },
  },
};
