type Breakpoint = "smallMobile" | "mobile" | "tablet" | "desktop";

export const breakpoints: Record<Breakpoint, string> = {
  smallMobile: "576px",
  mobile: "768px",
  tablet: "992px",
  desktop: "1280px",
};

export const EBreakpoints = {
  SmallMobile: "(max-width: 576px)",
  Mobile: "(max-width: 768px)",
  Tablet: "(max-width: 992px)",
  Desktop: "(max-width: 1280px)",
};
