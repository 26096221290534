import { QuestionComponentType } from "../../enum";
import styled from "@emotion/styled";
import {
  AccountInformationType,
  ApplicantInformationType,
} from "../../../../common/types";
import { QuestionType } from "../../types";
import { Typography } from "@mui/material";
import { ApplicantInformation, ConfirmEmailForm } from "../../forms";

export const accountInformationQuestions: Record<
  keyof AccountInformationType,
  QuestionType
> = {
  lookingForJob: {
    display: "Are you looking for a job?",
    componentType: QuestionComponentType.CHECKBOX,
  },
  information: {
    display: "Applicant Information",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: ApplicantInformation,
      displayAnswer: (form: ApplicantInformationType) => {
        const {
          firstName,
          middleName,
          lastName,
          citizenship,
          phoneNumber,
          email,
        } = form;
        return (
          <div>
            <Typography>
              Full name: {`${lastName}, ${firstName} ${middleName ?? ""}`}
            </Typography>
            <Typography>Citizenship: {citizenship}</Typography>
            <Typography>Phone number: {phoneNumber}</Typography>
            <Typography>Email: {email}</Typography>
          </div>
        );
      },
    },
  },
  confirmedEmail: {
    display: "Confirmed Email",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: ConfirmEmailForm,
      displayAnswer: (isConfirmed: string) => {
        return <Typography>{isConfirmed}</Typography>;
      },
    },
  },
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: calc(2 * var(--unit));
  justify-content: space-between;
  height: 100%;
`;
