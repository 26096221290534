import React, { useEffect, useState } from "react";
import { AppModal } from "../../../../../common";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { QuestionTextField } from "../../../components";
import { getErrorProps } from "../../../utils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

const OtherTradeSkillModal = ({
  handleCloseModal,
  onSave,
  variant,
  initialValue,
}: {
  handleCloseModal: () => void;
  onSave: (value: string) => void;
  variant: "Add" | "Edit";
  initialValue: "";
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      const isValid = isFormValid(value);

      setIsValid(isValid);
    }
  }, [value]);

  return (
    <AppModal
      open={true}
      handleClose={handleCloseModal}
      title={`${variant} Trade Skill`}
    >
      <>
        <Content>
          <QuestionTextField<string>
            question={{ display: "Other skill" }}
            value={value ?? ""}
            setValue={(value) => setValue(value?.toString() ?? "")}
            componentVariant="form"
            {...getErrorProps({
              value,
            })}
          />
        </Content>
        <Footer>
          <Button variant="outlined" onClick={handleCloseModal} size="large">
            Close
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid}
            onClick={() => {
              onSave(value);
              handleCloseModal();
              setValue(initialValue);
            }}
            size="large"
          >
            {variant}
          </Button>
        </Footer>
      </>
    </AppModal>
  );

  function isFormValid(value: string) {
    return value.trim() !== "";
  }
};

export default OtherTradeSkillModal;
