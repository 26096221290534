import { ComponentProps } from "../../components/types";
import React, { useEffect, useRef, useState } from "react";
import {
  AccountInformationType,
  ApplicantInformationType,
} from "../../../../common/types";
import { isValidEmail } from "../../../dashboard/components/systemUsers/utils";
import styled from "@emotion/styled";
import countries from "../../jsons/countries.json";
import { QuestionDropdown, QuestionTextField } from "../../components";
import QuestionNumberInput from "../../components/QuestionNumberInput";
import { getErrorProps, isFieldEmpty } from "../../utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(2 * var(--unit));
  width: 100%;
  overflow: auto;
`;

const ApplicantInformationForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<AccountInformationType>) => {
  const value = rawValue as ApplicantInformationType;
  const parentRef = useRef<HTMLDivElement>(null);

  const [emailError, setEmailError] = useState<string | undefined>();
  const [confirmEmailError, setConfirmEmailError] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  return (
    <Container ref={parentRef}>
      <QuestionTextField<ApplicantInformationType>
        value={value?.firstName}
        setValue={(firstName) =>
          setValue({ ...value, firstName: firstName ?? "" })
        }
        question={{ display: "First Name" }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.firstName,
        })}
      />
      <QuestionTextField<ApplicantInformationType>
        value={value?.middleName}
        setValue={(middleName) =>
          setValue({ ...value, middleName: middleName ?? "" })
        }
        question={{ display: "Middle Name" }}
        componentVariant="form"
      />
      <QuestionTextField<ApplicantInformationType>
        value={value?.lastName}
        setValue={(lastName) =>
          setValue({ ...value, lastName: lastName ?? "" })
        }
        question={{ display: "Last Name" }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.lastName,
        })}
      />
      <QuestionDropdown<ApplicantInformationType>
        value={value?.citizenship ?? "-1"}
        setValue={(citizenship) =>
          setValue({ ...value, citizenship: citizenship ?? "-1" })
        }
        question={{
          display: "Country of citizenship",
          meta: {
            options:
              countries.map((country) => ({
                label: country,
                value: country,
              })) ?? [],
          },
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.citizenship,
        })}
      />
      <QuestionNumberInput<ApplicantInformationType>
        value={value?.phoneNumber}
        setValue={(phoneNumber) =>
          setValue({ ...value, phoneNumber: phoneNumber ?? "" })
        }
        question={{
          display: "Phone number",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.phoneNumber,
        })}
      />
      <QuestionTextField<ApplicantInformationType>
        value={value?.email}
        setValue={(email) => setValue({ ...value, email: email ?? "" })}
        question={{ display: "Email" }}
        componentVariant="form"
        {...getErrorProps({
          onErrorCheck: () => emailError !== undefined,
          value: value?.email,
          errorMessage: emailError,
        })}
      />
      <QuestionTextField<ApplicantInformationType>
        value={value?.confirmEmail}
        setValue={(confirmEmail) =>
          setValue({ ...value, confirmEmail: confirmEmail ?? "" })
        }
        question={{ display: " Confirm Email" }}
        onPaste={(e) => e.preventDefault()}
        componentVariant="form"
        {...getErrorProps({
          onErrorCheck: () => confirmEmailError !== undefined,
          value: value?.confirmEmail,
          errorMessage: confirmEmailError,
        })}
      />
    </Container>
  );

  function isFormValid(form: ApplicantInformationType) {
    const {
      firstName,
      lastName,
      citizenship,
      phoneNumber,
      email,
      confirmEmail,
    } = form;

    const isEmailSame = email === confirmEmail;
    const isEmailValid = isValidEmail(email);

    setEmailError(!isEmailValid ? "Invalid email." : undefined);
    setConfirmEmailError(
      !isEmailSame ? "Email confirmation mismatch." : undefined,
    );

    return (
      !isFieldEmpty(firstName) &&
      !isFieldEmpty(lastName) &&
      !isFieldEmpty(citizenship) &&
      !isFieldEmpty(phoneNumber) &&
      !isFieldEmpty(email) &&
      !isFieldEmpty(confirmEmail) &&
      isEmailSame &&
      isEmailValid
    );
  }
};

export default ApplicantInformationForm;
