import { useState } from "react";
import {
  AccountInformationType,
  PartialRecord,
} from "../../../../common/types";
import { CommonProps } from "../types";
import QuestionContainer from "../QuestionContainer";
import { accountInformationQuestions } from "./const";
import { QuestionType } from "../../types";
import { GroupType } from "../../enum";

interface Props {
  initIndex: number;
}

const MainView = ({
  initForm,
  addToForm,
  onNextView,
  onPrevView,
  initIndex,
}: CommonProps<AccountInformationType> & Props) => {
  const { lookingForJob, information } = accountInformationQuestions;
  const questions: PartialRecord<keyof AccountInformationType, QuestionType> = {
    lookingForJob,
    information,
  };
  const [questionIndex, setQuestionIndex] = useState(initIndex);

  const currentQuestionKey = Object.keys(questions)[
    questionIndex
  ] as keyof AccountInformationType;
  const currentQuestion = questions[currentQuestionKey];

  const onPrevQuestion = (
    value: AccountInformationType[keyof AccountInformationType],
  ) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex === 0) {
      onPrevView && onPrevView();
    } else {
      setQuestionIndex(0);
    }
  };

  const onNextQuestion = (
    value: AccountInformationType[keyof AccountInformationType],
  ) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex !== Object.keys(questions).length - 1) {
      setQuestionIndex((prevState) => prevState + 1);
    } else {
      onNextView();
    }
  };

  return currentQuestion ? (
    <QuestionContainer<AccountInformationType>
      currentQuestion={currentQuestion}
      initValue={initForm?.[currentQuestionKey]}
      onNextQuestion={onNextQuestion}
      onPrevQuestion={onPrevQuestion}
      showPrevButton={questionIndex !== 0}
      title={GroupType.ACCOUNT_INFORMATION}
    />
  ) : (
    <></>
  );
};
export default MainView;
