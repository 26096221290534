import React, { useEffect, useState } from "react";
import { ProfileHeader, UserSideMenu, useStates } from "../../common";
import { useNavigate, useParams } from "react-router-dom";
import { stateService } from "../../services";
import sortBy from "lodash.sortby";
import { UserInformation } from "./UserInformation";
import { OfficeInformation } from "./officeInformation";

const UserProfile = () => {
  const { menu } = useParams();
  const sideMenu = menu as UserSideMenu;

  const navigate = useNavigate();

  const { setStates } = useStates();

  useEffect(() => {
    stateService.getAll().then((states) => setStates(sortBy(states, "name")));
  }, [setStates]);

  return (
    <ProfileHeader
      selectedUserMenu={sideMenu}
      setSelectedMenu={(menu) => navigate(`/UserProfile/${menu}`)}
    >
      {renderMenu(sideMenu)}
    </ProfileHeader>
  );
};

function renderMenu(selectedMenu: UserSideMenu) {
  switch (selectedMenu) {
    case UserSideMenu.PROFILE:
    case UserSideMenu.SERVICES:
    case UserSideMenu.OFFICES:
      return <UserInformation />;
    case UserSideMenu.CEBU:
    case UserSideMenu.MAKATI:
    case UserSideMenu.DUMAGUETE:
    case UserSideMenu.ILOILO:
    case UserSideMenu.THAILAND:
    case UserSideMenu.CANADA:
      return <OfficeInformation selectedMenu={selectedMenu} />;
  }
}

export default UserProfile;
