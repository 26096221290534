import { Box, Button, Link, TextField, Typography } from "@mui/material";
import { IMAGES } from "../../../../common";
import "./contact.scss";
import { Textarea } from "@mui/joy";

interface Props {
  divRef: React.Ref<HTMLDivElement>;
}
  
const ContactSection = ({ divRef }: Props) => {

  return (
    <div className="contact-us">
    <div className="contact" ref={divRef}>
      <div className="contact-section">
        <Typography className="sub-contact-section-title">
          Your presence matters
        </Typography>
        <Typography className="contact-section-title">Contact Us</Typography>
        <div className="contact-flex-container">
          <div className="contact-flex-child">
            <div className="contact-icon-container">
              <img
                className="contact-icon"
                src={IMAGES.location}
                alt="location"
              />
            </div>
            <div className="contact-title-container">
              <Typography className="contact-title">Address</Typography>
            </div>
            <div className="contact-description-container">
              <Typography className="contact-description">
                138 King street West,
              </Typography>
              <Typography className="contact-description">
                Hamilton, Ontario L8G 1J6
              </Typography>
            </div>
          </div>
          <div className="contact-flex-child">
            <div className="contact-icon-container">
              <img className="contact-icon" src={IMAGES.phone} alt="phone" />
            </div>
            <div className="contact-title-container">
              <Typography className="contact-title">Phone</Typography>
            </div>
            <div className="contact-description-container">
              <Typography className="contact-description">
                +1 (365) 888 5076
              </Typography>
            </div>
          </div>
          <div className="contact-flex-child">
            <div className="contact-icon-container">
              <img className="contact-icon" src={IMAGES.email} alt="email" />
            </div>
            <div className="contact-title-container">
              <Typography className="contact-title">Email</Typography>
            </div>
            <div className="contact-description-container">
              <Typography className="contact-sub-title">
                info.skillsev@gmail.com
              </Typography>
            </div>
          </div>
        </div>
        <Typography className="sub-contact-section-title">
          SkillsEV is open from Monday to Friday, 9am to 6pm GMT+8.
        </Typography>
        <Typography className="contact-section-title">
          Feel free to ask us about your job opportunities in the skilled trades
          profession.
        </Typography>
      </div>
    </div>
    <div className="message-us">
      <div className="message-table-flex-container">
        <div className="message-description-flex-child">
          <img
            className="message-image"
            src={IMAGES.message_us_image}
            alt="about"
          />
        </div>
        <div className="message-box-flex-child">
          <Box className="message-box" component="form">
            <p className="message-box-description">Send Your Inquiry Here</p>
            <div className="message-form">
              <TextField
                className="label"
                margin="normal"
                name="name"
                placeholder="Name*"
                type="name"
                id="name"
                autoComplete="current-name"
                required
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                className="label"
                margin="normal"
                id="email"
                placeholder="Email Address*"
                name="email"
                autoComplete="email"
                required
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
              <Textarea
                className="message-label"
                placeholder="Message Us*"
                sx={{
                  width: "100%",
                  height: "20vh",
                  resize: "both",
                  marginTop: 2,
                }}
                minRows={3}
                maxRows={5}
                required
              />
              <Button
                className="message-submit-button"
                type="submit"
                fullWidth
                variant="contained"
              >
                Send
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ContactSection;