import { doGet, doPost, doPut } from "../helpers/request-helper";
import { User } from "../common";

const baseURL = "api/users";

const getAllUsers = async (): Promise<User[]> => {
  return doGet(baseURL);
};

const addUser = async (user: { password: string } & User): Promise<User[]> => {
  return doPost(baseURL, user);
};

const updateUser = async (
  user: { password: string } & User,
): Promise<User[]> => {
  return doPut(baseURL, user);
};

export const userService = {
  addUser,
  getAllUsers,
  updateUser,
};
