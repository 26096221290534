import { personalQuestions } from "./const";
import { useState } from "react";
import QuestionContainer from "../QuestionContainer";
import { PersonalType } from "../../../../common/types";
import { CommonProps } from "../types";
import { GroupType } from "../../enum";

interface Props {
  isSkilled: boolean;
  isInCanada: boolean;
}

const FlowView = ({
  initForm,
  addToForm,
  onNextView,
  onPrevView,
  isSkilled,
  isInCanada,
}: CommonProps<PersonalType> & Props) => {
  const questions = getQuestions({ isSkilled, isInCanada });

  const [questionIndex, setQuestionIndex] = useState(0);
  const currentQuestionKey = Object.keys(questions)[
    questionIndex
  ] as keyof PersonalType;
  const currentQuestion = questions[currentQuestionKey];

  const onPrevQuestion = (value: PersonalType[keyof PersonalType]) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex === 0) {
      onPrevView && onPrevView();
    } else {
      setQuestionIndex((prevState) => prevState - 1);
    }
  };

  const onNextQuestion = (value: PersonalType[keyof PersonalType]) => {
    addToForm({
      key: currentQuestionKey,
      value,
    });

    if (questionIndex !== Object.keys(questions).length - 1) {
      setQuestionIndex((prevState) => prevState + 1);
    } else {
      onNextView();
    }
  };

  return currentQuestion ? (
    <QuestionContainer<PersonalType>
      currentQuestion={currentQuestion}
      initValue={initForm?.[currentQuestionKey]}
      onNextQuestion={onNextQuestion}
      onPrevQuestion={onPrevQuestion}
      title={GroupType.PERSONAL}
    />
  ) : (
    <></>
  );
};

function getQuestions({
  isSkilled,
  isInCanada,
}: {
  isSkilled: boolean;
  isInCanada: boolean;
}) {
  if (isSkilled && isInCanada) {
    return personalQuestions.inTradesAndInCanada;
  } else if (isSkilled && !isInCanada) {
    return personalQuestions.inTradesAndNotInCanada;
  } else if (!isSkilled && isInCanada) {
    return personalQuestions.notInTradesAndInCanada;
  } else {
    return personalQuestions.notInTradesAndNotInCanada;
  }
}

export default FlowView;
