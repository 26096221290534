export enum GroupType {
  ACCOUNT_INFORMATION = "Account Information",
  PERSONAL = "Personal",
  EDUCATION = "Education",
  LANGUAGE = "Language",
  WORK_EXPERIENCE = "Work Experience",
  ADAPTABILITY = "Adaptability",
  SUMMARY = "Summary",
}

export enum QuestionComponentType {
  CHECKBOX = "checkbox",
  DROPDOWN = "dropdown",
  DATE = "date",
  TEXT_INPUT = "textInput",
  AUTO_COMPLETE = "autoComplete",
  FORM = "form",
  NUMBER_INPUT = "numberInput",
}
