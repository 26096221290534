export const IMAGES = {
  imageOne: require("../../images/homepage.jpg"),
  location: require("../../images/location.png"),
  phone: require("../../images/phone-call.png"),
  email: require("../../images/email.png"),
  ev_staff: require("../../images/ev_staff.jpg"),
  ev_logo: require("../../images/ev_logo.png"),
  facebook: require("../../images/facebook_message_icon.png"),
  whatsapp: require("../../images/whatsapp_message_icon.png"),
  atheneum: require("../../images/Atheneum International_Red.PNG"),
  eith: require("../../images/eith logo.jpeg"),
  enhance_visa: require("../../images/enhance_visa.jpg"),
  ev_immigration: require("../../images/ev-immigration-logo.png"),
  facebook_footer: require("../../images/facebook_footer.png"),
  instagram_footer: require("../../images/instagram_footer.png"),
  twitter_footer: require("../../images/twitter_footer.png"),
  linkedin_footer: require("../../images/linkedin_footer.png"),
  certificate: require("../../images/certificate.png"),
  skills: require("../../images/skills.png"),
  paper: require("../../images/paper.png"),
  icon_skill_one: require("../../images/icon_skill_1.png"),
  icon_skill_two: require("../../images/icon_skill_2.png"),
  icon_skill_three: require("../../images/icon_skill_3.png"),
  icon_skill_four: require("../../images/icon_skill_4.png"),
  icon_skill_five: require("../../images/icon_skill_5.png"),
  icon_skill_six: require("../../images/icon_skill_6.png"),
  icon_skill_seven: require("../../images/icon_skill_7.png"),
  icon_skill_eight: require("../../images/icon_skill_8.png"),
  icon_skill_nine: require("../../images/icon_skill_9.png"),
  icon_skill_ten: require("../../images/icon_skill_10.png"),
  login_logo: require("../../images/login_logo.png"),
  edit_icon: require("../../images/edit.png"),
  profile_pic: require("../../images/woman.png"),
  canada: require("../../images/CANADA.jpg"),
  cebu: require("../../images/CEBU.jpg"),
  makati: require("../../images/MAKATI.jpg"),
  dumaguete: require("../../images/DUMAGUETE.jpg"),
  iloilo: require("../../images/ILOILO.jpg"),
  thailand: require("../../images/THAILAND.jpg"),
  all_professionals: require("../../images/all_professionals.jpg"),
  message_us_image: require("../../images/message-us-image.jpg"),
};

export default IMAGES;
