import { useState } from "react";
import { EducationType } from "../../../../common/types";
import MainView from "./MainView";
import { useSurveyForm } from "../../../../common";

interface Props {
  initForm: EducationType;
  onPrevGroup: () => void;
  onNextGroup: () => void;
  prevGroupName: string;
}

const Education = ({
  initForm,
  onPrevGroup,
  onNextGroup,
  prevGroupName,
}: Props) => {
  const { form: surveyForm, setForm: setSurveyForm } = useSurveyForm();

  const [educationForm, setEducationForm] = useState<EducationType>(initForm);

  const addToForm = ({
    key,
    value,
  }: {
    key: string;
    value: EducationType[keyof EducationType];
  }) => {
    const updatedForm = value
      ? {
          ...educationForm,
          [key]: value,
        }
      : { ...educationForm };

    setEducationForm(updatedForm);
    setSurveyForm({
      ...surveyForm,
      education: updatedForm,
    });
  };

  return (
    <MainView
      addToForm={addToForm}
      initForm={educationForm}
      onPrevView={onPrevGroup}
      onNextView={onNextGroup}
      prevGroupName={prevGroupName}
    />
  );
};

export default Education;
