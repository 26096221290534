import { QuestionDropdown, QuestionTextField } from "../../components";
import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import otherTradeSkills from "../../jsons/other_country_trade_skills.json";
import { Container } from "../const";
import { PersonalType, SkillCountryTradeType } from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const OPTIONS = otherTradeSkills as string[];

const OtherCountryTradeForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as SkillCountryTradeType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.tradeSkill]);

  const tradeSkill = value?.tradeSkill ?? null;

  const isOthers = tradeSkill === "Other skill" ?? null;

  return (
    <Container>
      <QuestionDropdown<SkillCountryTradeType>
        question={{
          display: "What skilled trades are you in?",
          meta: {
            options: OPTIONS.map((option) => ({
              label: option,
              value: option,
            })),
          },
        }}
        value={value?.tradeSkill}
        setValue={(tradeSkill) =>
          tradeSkill && setValue({ ...value, tradeSkill })
        }
        componentVariant="form"
        {...getErrorProps({
          value: value?.tradeSkill,
        })}
      />
      {isOthers && (
        <QuestionTextField<SkillCountryTradeType>
          value={value?.specified}
          setValue={(specified) => setValue({ ...value, specified })}
          question={{
            display: "Please specify trade skill",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.specified,
          })}
        />
      )}
    </Container>
  );

  function isFormValid(value: SkillCountryTradeType) {
    if (!tradeSkill) {
      return false;
    }

    if (isOthers) {
      return !isFieldEmpty(value?.specified);
    }

    return true;
  }

  function reformatForm(form: SkillCountryTradeType) {
    if (form && tradeSkill) {
      const { tradeSkill } = form;
      if (isOthers) {
        return form;
      } else {
        return { tradeSkill } as SkillCountryTradeType;
      }
    }

    return form;
  }
};

export default OtherCountryTradeForm;
