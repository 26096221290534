import { doGet, doPost } from "../helpers/request-helper";
import { Applicant } from "../common";

const baseURL = "api/applicants";

const addApplicant = async (applicant: Applicant): Promise<Applicant> => {
  return doPost(baseURL, applicant);
};

const getAll = async (): Promise<Applicant[]> => {
  return doGet(baseURL);
};

export const applicantService = {
  addApplicant,
  getAll,
};
