import { AppModal, ErrorText } from "../../../../common";
import styled from "@emotion/styled";
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import Button from "@mui/material/Button";
import countries from "../../jsons/countries.json";
import { ExperienceType, ProgramType } from "../../../../common/types";
import {
  QuestionAutoComplete,
  QuestionDate,
  QuestionDropdown,
  QuestionTextField,
} from "../../components";
import { Container } from "../../components/const";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { getErrorProps, isFieldEmpty } from "../../utils";

const JOB_TYPE_OPTIONS = ["Full time", "Part time"];

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 0 var(--unit);
`;

const StyledContainer = styled(Container)`
  .MuiInputBase-input {
    font-size: 20px;
  }
`;

const AddMoreButton = styled(Button)`
  margin: auto;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

interface Props {
  handleCloseModal: () => void;
  onSave: (value: ExperienceType) => void;
  variant: "Add" | "Edit";
  initialValue: ExperienceType;
}

const WorkExperienceModal = ({
  handleCloseModal,
  variant,
  initialValue,
  onSave,
}: Props) => {
  const [value, setValue] = useState<ExperienceType>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isOngoing, setIsOngoing] = useState<boolean>(
    variant === "Add"
      ? false
      : initialValue
        ? initialValue.dateCompleted === null
        : false,
  );

  const dateStarted = value?.dateStarted ?? null;
  const dateCompleted = value?.dateCompleted ?? null;

  useEffect(() => {
    if (dateStarted && dateCompleted) {
      if (DateTime.fromISO(dateCompleted) < DateTime.fromISO(dateStarted)) {
        setValue({ ...value, dateCompleted: dateStarted });
      }
    }
  }, [dateStarted]);

  useEffect(() => {
    if (value) {
      const isValid = isFormValid(value);

      setIsValid(isValid);
    }
  }, [value, isOngoing]);

  return (
    <AppModal
      open={true}
      handleClose={handleCloseModal}
      title={`${variant} Work Experience`}
    >
      <>
        <QuestionTextField<ExperienceType>
          value={value?.jobTitle}
          setValue={(jobTitle) =>
            setValue({ ...value, jobTitle: jobTitle?.toString() ?? "" })
          }
          question={{
            display: "Job Title",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.jobTitle,
          })}
        />
        <QuestionTextField<ExperienceType>
          value={value?.companyEmployer}
          setValue={(companyEmployer) =>
            setValue({
              ...value,
              companyEmployer: companyEmployer?.toString() ?? "",
            })
          }
          question={{
            display: "Company/Employer",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.companyEmployer,
          })}
        />
        <QuestionAutoComplete<ExperienceType>
          value={value?.country ?? "-1"}
          setValue={(country) =>
            setValue({ ...value, country: country?.toString() ?? "" })
          }
          question={{
            display: "Country",
            meta: {
              options: countries.map((option) => ({
                label: option,
                value: option,
              })),
            },
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.country,
          })}
        />
        <QuestionDropdown<ExperienceType>
          value={value?.type ?? "-1"}
          setValue={(type) =>
            setValue({ ...value, type: type?.toString() ?? "" })
          }
          question={{
            display: "Type",
            meta: {
              options: JOB_TYPE_OPTIONS.map((option) => ({
                label: option,
                value: option,
              })),
            },
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.type,
          })}
        />
        <Duties
          initialDuties={value?.duties}
          setDuties={(duties) => setValue({ ...value, duties })}
        />
        <StyledContainer variant="form">
          <Typography variant="h5">Rate per hour in PHP</Typography>
          <OutlinedInput
            id="outlined-adornment-amount"
            fullWidth
            startAdornment={<InputAdornment position="start">₱</InputAdornment>}
            type="number"
            value={value?.ratePerHour}
            onChange={(e) =>
              setValue({
                ...value,
                ratePerHour: e.target.value,
              })
            }
            {...getErrorProps({
              value: value?.ratePerHour,
            })}
          />
          {isFieldEmpty(value?.ratePerHour) && <ErrorText />}
        </StyledContainer>
        <QuestionTextField<ExperienceType>
          value={value?.hoursPerWeek}
          setValue={(hoursPerWeek) =>
            setValue({
              ...value,
              hoursPerWeek: hoursPerWeek?.toString() ?? "",
            })
          }
          question={{
            display: "No. of hours per week",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.hoursPerWeek,
          })}
        />
        <QuestionDate<ProgramType>
          value={value?.dateStarted}
          setValue={(dateStarted) =>
            setValue({
              ...value,
              dateStarted,
            })
          }
          question={{
            display: "Date Started",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.dateStarted,
          })}
        />
        <StyledFormControlLabel
          value="end"
          control={
            <Checkbox
              checked={isOngoing}
              onChange={() => setIsOngoing(!isOngoing)}
            />
          }
          label="I am currently employed in this position."
          labelPlacement="end"
        />
        {!isOngoing && (
          <QuestionDate<ProgramType>
            value={value?.dateCompleted}
            setValue={(dateCompleted) =>
              setValue({
                ...value,
                dateCompleted,
              })
            }
            question={{
              display: "Date Completed",
            }}
            componentVariant="form"
            minDate={dateStarted ? DateTime.fromISO(dateStarted) : undefined}
            maxDate={DateTime.now()}
            {...getErrorProps({
              value: value?.dateCompleted,
            })}
          />
        )}
        <Footer>
          <Button variant="outlined" onClick={handleCloseModal} size="large">
            Close
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid}
            onClick={() => {
              onSave(value);
              handleCloseModal();
              setValue(initialValue);
            }}
            size="large"
          >
            {variant}
          </Button>
        </Footer>
      </>
    </AppModal>
  );

  function isFormValid(value: ExperienceType) {
    return (
      (value?.jobTitle?.trim() ?? "") !== "" &&
      (value?.companyEmployer?.trim() ?? "") !== "" &&
      (value?.country?.trim() ?? "-1") !== "-1" &&
      (value?.type?.trim() ?? "-1") !== "-1" &&
      value?.duties?.filter((duty) => duty.trim() !== "").length >= 3 &&
      (value?.ratePerHour?.trim() ?? "") !== "" &&
      (value?.hoursPerWeek?.trim() ?? "") !== "" &&
      dateStarted !== "" &&
      dateStarted !== null &&
      (isOngoing || (dateCompleted !== "" && dateCompleted !== null))
    );
  }
};

const Duties = ({
  initialDuties,
  setDuties: setValueDuties,
}: {
  initialDuties: string[];
  setDuties: (value: string[]) => void;
}) => {
  const [duties, setDuties] = useState<string[]>(initialDuties);

  useEffect(() => {
    setValueDuties(duties.filter((duty) => duty.trim() !== ""));
  }, [duties]);

  const addMoreDuty = () => {
    setDuties([...duties, ""]);
  };

  const deleteDuty = () => {
    setDuties(duties.slice(0, -1));
  };

  const onDutyChanged = (value: string, index: number) => {
    const updatedDuties = [...duties];
    updatedDuties[index] = value;

    setDuties(updatedDuties);
  };

  return (
    <StyledContainer variant="form">
      <Typography variant="h5">Duties & responsibilities</Typography>
      <Grid container display="flex" flexDirection="column" rowGap={1}>
        {duties.map((duty, i) => (
          <Grid display="flex" alignItems="center" columnGap={1} key={i}>
            <Typography>{i + 1}.</Typography>
            <TextField
              value={duty}
              multiline
              fullWidth
              onChange={(e) => onDutyChanged(e.target.value, i)}
            />
            {i === duties.length - 1 && (
              <IconButton
                aria-label="delete"
                size="medium"
                color="error"
                onClick={deleteDuty}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        ))}
      </Grid>
      {duties?.filter((duty) => duty.trim() !== "").length < 3 && (
        <ErrorText helperText="Add atleast 3 duties/responsibilities." />
      )}
      <AddMoreButton size="large" onClick={addMoreDuty}>
        Add more duties
      </AddMoreButton>
    </StyledContainer>
  );
};

export default WorkExperienceModal;
