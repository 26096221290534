import React, { createContext, useContext, useState } from "react";
import { AppLoading } from "../components";

interface LoadingType {
  showLoading: boolean;
  setShowLoading: (loading: boolean) => void;
}

export const LoadingContext = createContext<LoadingType>({
  showLoading: false,
  setShowLoading: () => {},
});

export const LoadingProvider = ({ children }: { children: any }) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);

  return (
    <LoadingContext.Provider
      value={{
        showLoading,
        setShowLoading,
      }}
    >
      {children}
      {showLoading && <AppLoading />}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);

  if (context === null) {
    throw new Error("useLoading must be used within a LoadingContext.Provider");
  }

  return context;
}
