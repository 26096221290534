import axios, { HttpStatusCode } from "axios";
import { handleResponse } from "./handle-response";
import { logoutUser } from "../common/utils";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:8080",
});

axiosInstance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error?.response?.status ?? error.status;

    if (status === HttpStatusCode.Forbidden) {
      alert("Session expired!");
      logoutUser();
    } else if (error.code === "ERR_NETWORK") {
      alert("Internet connection failed!");
    }
  },
);

export const doGet = async (endpoint: string) => {
  return await axiosInstance
    .get(endpoint)
    .then(handleResponse)
    .catch(() => []);
};

export const doPost = async (endpoint: string, data: any) => {
  return await axiosInstance.post(endpoint, data).then(handleResponse);
};

export const doPut = async (endpoint: string, data: any) => {
  return await axiosInstance.put(endpoint, data).then(handleResponse);
};

export const doDelete = async (endpoint: string) => {
  return await axiosInstance.delete(endpoint);
};
