import { createContext, useContext, useState } from "react";
import { AppAlert } from "../components";

type Type = "success" | "error";

export interface AlertType {
  alertType: "success" | "error" | undefined;
  alertMessage: string | undefined;
  setAlert: ({
    message,
    type,
  }: {
    message: string | undefined;
    type?: Type;
  }) => void;
}

export const AlertContext = createContext<AlertType>({
  alertType: undefined,
  alertMessage: "",
  setAlert: () => {},
});

export const AlertProvider = ({ children }: { children: any }) => {
  const [alertType, setAlertType] = useState<Type>("error");
  const [alertMessage, setAlertMessage] = useState<string>();

  const setAlert = ({
    message,
    type,
  }: {
    message: string | undefined;
    type?: Type;
  }) => {
    setAlertMessage(message);
    setAlertType(type ?? "error");
  };

  return (
    <AlertContext.Provider
      value={{
        alertType,
        alertMessage,
        setAlert,
      }}
    >
      {children}
      {alertMessage && <AppAlert />}
    </AlertContext.Provider>
  );
};

export function useAlert() {
  const context = useContext(AlertContext);

  if (context === null) {
    throw new Error("useAlert must be used within a AlertContext.Provider");
  }

  return context;
}
