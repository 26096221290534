import React, { useState } from "react";
import styled from "@emotion/styled";
import { authenticationService } from "../../services";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Grid, Link, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import { useAlert, useLoading } from "../../common";
import "./Login.scss";

const StyledPaper = styled(Paper)`
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: calc(4 * var(--unit));

  display: flex;
  flex-direction: column;
  row-gap: calc(2 * var(--unit));
  border: 1px solid #a4a6a6; /* Adds a border */
  border-radius: 10px; /* Rounds the corners */
  height: 380px;
  flex-direction: column;

  /*Phones*/
  @media (min-width: 375px) and (max-width: 425px) {
    text-align: center;
    margin-bottom: 10%;
    width: calc(40 * var(--unit));
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 426px) and (max-width: 767px) {
    text-align: center;
    margin-bottom: 10%;
    width: calc(50 * var(--unit));
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    text-align: center;
    margin-bottom: 10%;
    width: calc(50 * var(--unit));
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    width: calc(50 * var(--unit));
    margin-bottom: 10%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    text-align: center;
    width: calc(50 * var(--unit));
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: calc(50 * var(--unit));
  }

  /*Phones*/
  @media (min-width: 375px) and (max-width: 425px) {
    text-align: center;
    margin-bottom: 10%;
    width: calc(40 * var(--unit));
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 426px) and (max-width: 767px) {
    width: calc(50 * var(--unit));
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    width: calc(50 * var(--unit));
    text-align: center;
    margin-bottom: 10%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    text-align: center;
    width: calc(50 * var(--unit));
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: calc(50 * var(--unit));
  }
`;

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4396c3", // Custom background color
  color: "#ffffff", // Custom text color
  fontWeight: "bold", // Make text bold
  padding: "10px 24px", // Make text bold
  "&:hover": {
    backgroundColor: "#white", // Custom hover color
  },
}));

const CustomButtonCreateAccount = styled(Button)`
  background-color: #008080; // Custom background color
  color: #ffffff; // Custom text color

  padding: 10px 24px;
  &:hover {
    background-color: #white; // Custom hover color
  }

  /*Phones*/
  @media (min-width: 375px) and (max-width: 425px) {
    width: calc(10 * var(--unit));
    font-weight: regular; // Make text bold
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    font-weight: bold; // Make text bold
    width: calc(30 * var(--unit));
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically, if needed */
  width: 100%; /* Ensure it takes up the full width of its parent */
`;

const PaddedTypography = styled(Typography)`
  padding-bottom: 20px; /* Adjust the padding as needed */
`;

const ContainerDiv = styled.div`
  /*Phones*/
  @media (min-width: 375px) and (max-width: 425px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 10%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 426px) and (max-width: 599px) {
    flex-direction: column;
    margin-bottom: 10%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 10%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 10%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 10%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-direction: row;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    flex-direction: row;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    text-align: center;
    margin-bottom: 10%;
  

  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-direction: row;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const LoginContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f4fcfe;
  justify-content: space-between; /* Ensures even distribution of flex items */
  align-items: center;
  height: 100vh;
  margin: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  position: relative;

  /*Phones*/
  @media (min-width: 375px) and (max-width: 425px) {
    flex-direction: column;
    text-align: center;
    padding-top: 10%;
    padding-left: 15%;
  }

  /* Extra small devices (phones, less than 600px) */
  @media (min-width: 426px) and (max-width: 599px) {
    flex-direction: column;
    text-align: center;
    padding-top: 10%;
    padding-left: 15%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    padding-top: 10%;
    padding-left: 20%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    height: 100vh;
    text-align: center;
    flex-direction: column;
    padding-top: 10%;
    padding-left: 30%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    padding: 0 5%; /* Add padding to center the content */
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    padding-left: 13%;
    padding-right: 0;
  }
`;

const CenteredLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const BoldTypography = styled(Typography)`
  color: #4396c3;
  font-size: 50px;
  font-weight: bold;

  /*Phones*/
  @media (min-width: 375px) and (max-width: 425px) {
    padding-left: 25%;
  }

  /* Extra small devices (phones, less than 600px) */
  @media (min-width: 426px) and (max-width: 599px) {
    flex-direction: column;
    text-align: center;
    padding-left: 100px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    text-align: center;
    padding-left: 100px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    padding-left: 75px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-direction: row;
    display: center;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    flex-direction: row;
    display: center;
  }
`;

const RegTypography = styled(Typography)`
  color: #a4a6a6;
  font-size: 5vh;
  font-weight: 400;

  width: calc(50 * var(--unit));

  /* Extra small devices (phones, less than 600px) */
  @media (max-width: 599px) {
    flex-direction: column;
    margin: 10px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    flex-direction: column;
    margin: 10px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    margin: 10px 0;
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-direction: row;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const SubRegTypography = styled(Typography)`
  color: #a4a6a6;
  font-size: 3vh;
  font-weight: 400;

  width: calc(50 * var(--unit));

  /* Extra small devices (phones, less than 600px) */
  @media (max-width: 599px) {
    flex-direction: column;
    margin: 10px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media (min-width: 600px) and (max-width: 767px) {
    flex-direction: column;
    margin: 10px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
    margin: 10px 0;
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199px) {
    flex-direction: row;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const StyledHr = styled.div`
  border: none;
  height: 1px;
  background-color: #ddd;
  margin: 20px 0; /* Add margin to create space */
`;

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const navigate = useNavigate();
  const { setShowLoading } = useLoading();
  const { setAlert } = useAlert();

  const isMobile = useMediaQuery("(max-width: 425px)");

  return (
    <LoginContainer>
      <Grid container spacing={2}>
        <Grid className="regular-container" item xs={6}>
          <ContainerDiv>
            <BoldTypography>SkillsEV</BoldTypography>
            {!isMobile && (
              <>
                <RegTypography>Canada Needs You</RegTypography>
                <SubRegTypography>
                  Get hired in a Skilled Trade Profession
                </SubRegTypography>
              </>
            )}
          </ContainerDiv>
        </Grid>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onLogin();
          }}
        >
          <StyledPaper variant="outlined">
            <FormControl>
              <Typography color="primary"></Typography>
              <TextField
                label="Email"
                size="small"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <Typography color="primary"></Typography>
              <TextField
                label="Password"
                size="small"
                fullWidth
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            {/* <FormControlLabel
            control={<Checkbox />}
            label="Remember me"
          /> */}
            <CustomButton type="submit" disabled={!isValid()} fullWidth>
              <Typography color="white" fontWeight="bold">
                SIGN IN
              </Typography>
            </CustomButton>
            <PaddedTypography align="left" variant="body2" color="primary">
              <CenteredLinkContainer>
                <Link href="#">Forgot password?</Link>
              </CenteredLinkContainer>
              <StyledHr>
                <hr />
              </StyledHr>
            </PaddedTypography>
            {/* <CenteredContainer>
            <CustomButtonCreateAccount
              type="submit"
              disabled={!isValid()}
            >
              <Typography color="white" fontWeight="bold">Create Account</Typography>
            </CustomButtonCreateAccount>
          </CenteredContainer> */}
            <Typography align="center" variant="body2">
              Can’t have an account? <Link href="#">Create Now.</Link>
            </Typography>
          </StyledPaper>
        </form>
      </Grid>
    </LoginContainer>
  );

  function isValid() {
    return email.trim() !== "" && password.trim() !== "";
  }

  function onLogin() {
    setShowLoading(true);
    authenticationService
      .login({
        email,
        password,
      })
      .then(onSuccess)
      .catch(() => setAlert({ message: "Email or password is incorrect!" }))
      .finally(() => setShowLoading(false));
  }

  function onSuccess(result: any) {
    const { token, user } = result;
    localStorage.setItem("authToken", token);
    localStorage.setItem("currentUser", user);
    navigate("/dashboard");
  }
};

export default Login;
