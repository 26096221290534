import WorkExperienceModal from "./WorkExperienceModal";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { ComponentProps } from "../../components/types";
import WorkExperiencesDisplay from "./WorkExperiencesDisplay";
import { ExperienceType, WorkExperienceType } from "../../../../common/types";
import styled from "@emotion/styled";
import { ErrorText } from "../../../../common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const AddExperienceButton = styled(Button)`
  margin-bottom: calc(2 * var(--unit));
`;

const initialValue: ExperienceType = {
  jobTitle: "",
  companyEmployer: "",
  country: "-1",
  type: "-1",
  ratePerHour: "",
  hoursPerWeek: "",
  duties: ["", "", ""],
  dateStarted: null,
  dateCompleted: null,
};

const WorkExperiencesForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<WorkExperienceType>) => {
  const value = rawValue as ExperienceType[];

  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const addWorkExperience = (addedValue: ExperienceType) => {
    setValue([...value, addedValue]);
  };

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  return (
    <Container>
      <AddExperienceButton
        onClick={() => setOpenAdd(true)}
        variant="contained"
        size="large"
      >
        Add Experience
      </AddExperienceButton>
      {value?.length === 0 && (
        <ErrorText helperText="Add atleast one work experience." />
      )}
      {openAdd && (
        <WorkExperienceModal
          handleCloseModal={() => setOpenAdd(false)}
          variant="Add"
          onSave={addWorkExperience}
          initialValue={initialValue}
        />
      )}
      {value && value.length > 0 && (
        <WorkExperiencesDisplay
          experiences={value}
          removeExperience={removeExperience}
          updateExperience={updateWorkExperience}
        />
      )}
    </Container>
  );

  function isFormValid(value: ExperienceType[]) {
    return value && value.length > 0;
  }

  function removeExperience(toRemove: ExperienceType) {
    const workExperiences = [...value] ?? [];
    const updated = workExperiences.filter(
      (workExperience) => workExperience !== toRemove,
    );

    setValue(updated);
  }

  function updateWorkExperience(updated: ExperienceType, index: number) {
    const workExperiences = value ?? [];
    workExperiences[index] = updated;

    setValue(workExperiences);
  }
};

export default WorkExperiencesForm;
