import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import Fade from "@mui/material/Fade/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledPaper = styled(Paper)`
  padding: calc(2 * var(--unit));
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: calc(2 * var(--unit));
`;

const AppLoading = () => {
  return (
    <Modal open={true}>
      <Fade in={true}>
        <StyledBox>
          <StyledPaper>
            <CircularProgress />
            <Typography>Loading...</Typography>
          </StyledPaper>
        </StyledBox>
      </Fade>
    </Modal>
  );
};

export default AppLoading;
