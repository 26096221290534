import { QuestionType } from "../../types";
import { QuestionComponentType } from "../../enum";
import { ExperienceType, WorkExperienceType } from "../../../../common/types";
import { Typography } from "@mui/material";
import { computeYears, formatDate } from "../../utils";
import styled from "@emotion/styled";
import WorkExperiencesForm from "../../forms/WorkExperience";
import { DateTime } from "luxon";

const ExperienceContainer = styled.div`
  margin-top: calc(2 * var(--unit));
`;

const DutiesContainer = styled.div`
  margin-left: var(--unit);
`;

export const workExperienceQuestions: Record<
  keyof WorkExperienceType,
  QuestionType
> = {
  experiences: {
    display: "Work experiences",
    componentType: QuestionComponentType.FORM,
    meta: {
      component: WorkExperiencesForm,
      displayAnswer: (experiences: ExperienceType[]) => {
        return (
          <>
            {experiences.map((experience, i) => {
              const {
                jobTitle,
                companyEmployer,
                ratePerHour,
                country,
                duties,
                dateStarted,
                dateCompleted,
              } = experience;

              return (
                <ExperienceContainer>
                  <Typography fontWeight={700}>
                    <em>Experience {i + 1}</em>
                  </Typography>
                  <Typography>Job Title: {jobTitle}</Typography>
                  <Typography>Employer: {companyEmployer}</Typography>
                  <Typography>Country: {country}</Typography>
                  <Typography>Rate: {ratePerHour}</Typography>
                  <Typography>Duties & Responsibilities:</Typography>
                  <DutiesContainer>
                    {duties.map((duty, i) => (
                      <Typography>
                        {i + 1}. {duty}
                      </Typography>
                    ))}
                  </DutiesContainer>
                  <Typography>Started: {formatDate(dateStarted)}</Typography>
                  <Typography>
                    Completed:{" "}
                    {dateCompleted ? formatDate(dateCompleted) : "Ongoing"}
                  </Typography>
                  <Typography>
                    Years Experience:{" "}
                    {dateStarted &&
                      computeYears({
                        startDate: dateStarted,
                        endDate: dateCompleted
                          ? dateCompleted
                          : DateTime.now().toString(),
                      })}
                  </Typography>
                </ExperienceContainer>
              );
            })}
          </>
        );
      },
    },
  },
};
