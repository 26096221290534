export function updateFormByKeys<T extends any>(form: T, keys: string[]) {
  return Object.entries(form as any)
    .filter(([key, _]) => keys.includes(key))
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    ) as T;
}
