import React, { FC, ReactElement } from "react";
import { Box, Container, Typography } from "@mui/material";
import IMAGES from "../../const/images";
import "./footer.scss";

export const HomeFooter: FC = (): ReactElement => {
  return (
    <Box className="footer">
      <Container className="footer-flex-container" maxWidth="lg">
        <div className="footer-title-flex-container">
          <Typography className="footer-title">In Partnership With</Typography>
        </div>
        <div className="footer-image-flex-container">
          <div className="footer-flex-child">
            <img
              className="footer-image-enhance"
              src={IMAGES.enhance_visa}
              alt="atheneum"
            />
          </div>
          <div className="footer-flex-child">
            <img
              className="footer-image-atheneum"
              src={IMAGES.atheneum}
              alt="atheneum"
            />
          </div>
          <div className="footer-flex-child">
            <img className="footer-image-eith" src={IMAGES.eith} alt="eith" />
          </div>
          <div className="footer-flex-child">
            <img
              className="footer-image-ev"
              src={IMAGES.ev_immigration}
              alt="ev-immigration"
            />
          </div>
        </div>
        <div className="footer-sub-title-flex-container">
          <div className="footer-sub-title-flex-child-right">
            <div className="skillsev-logo">
              <img className="skillsev" src={IMAGES.ev_logo} alt="about" />
            </div>
            <Typography className="skillsev-sub-section" variant="subtitle2">
              Your go-to solution for trade work.
            </Typography>
          </div>
          <div className="footer-sub-title-flex-child-left">
            <Typography className="footer-third-layer" variant="subtitle2">
              Privacy Policy
            </Typography>
            <Typography className="footer-third-layer" variant="subtitle2">
              Terms of Services
            </Typography>
            <Typography className="footer-third-layer" variant="subtitle2">
              Copyright 2024
            </Typography>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default HomeFooter;
