import { Typography } from "@mui/material";
import { goalList } from "../../../../common";
import "./goal.scss";
import { HomeCard } from "../../components";

interface Props {
  divRef: React.Ref<HTMLDivElement>;
}
  
const GoalSection = ({ divRef }: Props) => {

  return (
    <div className="goal" ref={divRef}>
        <div className="goal-section">
            <div className="goal-center">
                <Typography className="sub-goal-title">
                We create meaningful impact in what we do
                </Typography>
                <Typography className="goal-title">Our Offerings</Typography>
                <div className="parent-flex-goal-container">
                {goalList.map((goal) => (
                    <HomeCard value={goal} type="goal" key={goal.id} />
                ))}
                </div>
            </div>
        </div>
    </div>
  );
};

export default GoalSection;