import { doPost } from "../helpers/request-helper";

const baseURL = "/authentication";

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const url = baseURL + "/login";
  return doPost(url, {
    email,
    password,
  });
};

export const authenticationService = {
  login,
};
