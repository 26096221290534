import { createContext, useContext, useEffect, useState } from "react";
import { State, SurveyType, TradeSkill } from "../types";
import { initialSurveyForm } from "../const";
import { useLoading } from "./LoadingContext";
import { stateService, tradeSkillService } from "../../services";

export interface SurveyFormContextType {
  form: SurveyType;
  setForm: (form: SurveyType) => void;
  states: State[];
  tradeSkills: TradeSkill[];
}

export const SurveyFormContext = createContext<SurveyFormContextType>({
  form: initialSurveyForm,
  setForm: () => {},
  states: [],
  tradeSkills: [],
});

export const SurveyFormProvider = ({ children }: { children: any }) => {
  const [form, setForm] = useState<SurveyType>(
    getLocallySavedForm() ?? initialSurveyForm,
  );
  const { setShowLoading } = useLoading();
  const [states, setStates] = useState<State[]>([]);
  const [tradeSkills, setTradeSkills] = useState<TradeSkill[]>([]);

  useEffect(() => {
    setShowLoading(true);
    Promise.all([stateService.getAll(), tradeSkillService.getAll()])
      .then(([resStates, resTradeSkills]) => {
        setStates(resStates);
        setTradeSkills(resTradeSkills);
      })
      .finally(() => setShowLoading(false));
  }, []);

  return (
    <SurveyFormContext.Provider
      value={{
        form,
        setForm,
        states,
        tradeSkills,
      }}
    >
      {children}
    </SurveyFormContext.Provider>
  );

  function getLocallySavedForm() {
    const formSaved = localStorage.getItem("surveyForm");
    return formSaved ? (JSON.parse(formSaved) as SurveyType) : undefined;
  }
};

export function useSurveyForm() {
  const context = useContext(SurveyFormContext);

  if (context === null) {
    throw new Error("useForm must be used within a FormProvider");
  }

  return context;
}
