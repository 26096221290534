import React, { useState } from "react";
import { useAlert, useLoading } from "../../../../common";
import { AddItem } from "../../common";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { userService } from "../../../../services";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import { isValidEmail } from "./utils";

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

const AddUser = ({ fetchUsers }: { fetchUsers: () => void }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const { setShowLoading } = useLoading();
  const { setAlert } = useAlert();

  return (
    <AddItem
      label="Add User"
      modalTitle="Add User"
      onSave={onSave}
      disableButton={!isValid()}
    >
      <FormContent>
        <FormControl>
          <Typography>First Name</Typography>
          <TextField
            size="small"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Typography>Last Name</Typography>
          <TextField
            size="small"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Typography>Email</Typography>
          <TextField
            size="small"
            fullWidth
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
      </FormContent>
    </AddItem>
  );

  function isValid() {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      isValidEmail(email)
    );
  }

  function clearAll() {
    setFirstName("");
    setLastName("");
    setEmail("");
  }

  function onSave() {
    setShowLoading(true);
    const user = {
      firstName,
      lastName,
      email,
      password: "password",
    };
    userService
      .addUser(user)
      .then(() => {
        fetchUsers();
        clearAll();
        setAlert({ message: "User Saved!", type: "success" });
      })
      .catch(() =>
        setAlert({ message: "Email already exists!", type: "error" }),
      )
      .finally(() => setShowLoading(false));
  }
};

export default AddUser;
