import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

const StyledText = styled(Typography)`
  margin: 3px 14px 0 14px;
  font-size: 0.75rem;
  font-weight: 400;
  align-self: flex-start;
`;

const ErrorText = ({
  helperText = "This is required.",
}: {
  helperText?: string;
}) => (
  <StyledText className="helperTextCustom" color="error">
    {helperText}
  </StyledText>
);

export default ErrorText;
