import MainView from "./MainView";
import { useState } from "react";
import { marriedQuestions, spOrPGWPQuestions } from "./const";
import { AdaptabilityType } from "../../../../common/types";
import { useSurveyForm } from "../../../../common";
import { isAdaptabilityVisaStatusValid } from "../../utils";

interface Props {
  initForm: AdaptabilityType;
  onPrevGroup: () => void;
  onNextGroup: () => void;
  prevGroupName: string;
}

const Adaptability = ({
  initForm,
  onNextGroup,
  onPrevGroup,
  prevGroupName,
}: Props) => {
  const { form: surveyForm, setForm: setSurveyForm } = useSurveyForm();

  const [adaptabilityForm, setAdaptabilityForm] =
    useState<AdaptabilityType>(initForm);

  const addToAdaptabilityForm = ({
    key,
    value,
  }: {
    key: string;
    value: AdaptabilityType[keyof AdaptabilityType];
  }) => {
    const updatedForm = value
      ? {
          ...adaptabilityForm,
          [key]: value,
        }
      : { ...adaptabilityForm };

    setAdaptabilityForm(updatedForm);
    setSurveyForm({
      ...surveyForm,
      adaptability: updatedForm,
    });
  };

  const personalForm = surveyForm.personal;

  const isVisaHolderValid = isAdaptabilityVisaStatusValid(
    surveyForm.personal.statusInCanada,
  );

  const isMarried = personalForm?.maritalStatus?.status === "Married";

  return (
    <MainView
      addToForm={addToAdaptabilityForm}
      initForm={adaptabilityForm}
      onPrevView={onPrevGroup}
      onNextView={onNextGroup}
      questions={getQuestions({ isVisaHolderValid, isMarried })}
      prevGroupName={prevGroupName}
    />
  );
};

function getQuestions({
  isVisaHolderValid,
  isMarried,
}: {
  isVisaHolderValid: boolean;
  isMarried: boolean;
}) {
  return {
    ...(isVisaHolderValid ? spOrPGWPQuestions : {}),
    ...(isMarried ? marriedQuestions : {}),
  };
}

export default Adaptability;
