import { QuestionType } from "../types";
import { QuestionComponentType } from "../enum";
import {
  QuestionAutoComplete,
  QuestionCheckbox,
  QuestionDate,
  QuestionDropdown,
  QuestionForm,
  QuestionNumberInput,
  QuestionTextField,
} from "../components";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { breakpoints, EBreakpoints } from "../../../common";
import { Typography, useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Tooltip } from "@mui/joy";

const Container = styled(Paper)`
  padding: calc(3 * var(--unit)) var(--unit);
  background-color: white;
  box-shadow: unset;

  @media (min-width: ${breakpoints.mobile}) {
    padding: calc(8 * var(--unit)) calc(5 * var(--unit));
    max-width: calc(100 * var(--unit));
    min-height: calc(50 * var(--unit));
    margin: auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FooterContainer = styled(Paper)`
  height: 180px;
  width: 100%;
  border-radius: 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: calc(2 * var(--unit));
  row-gap: calc(2 * var(--unit));
  background-color: white;

  button {
    height: calc(8 * var(--unit));
  }

  @media (min-width: ${breakpoints.mobile}) {
    height: 110px;
    flex-direction: row;
    justify-content: space-between;
    padding: calc(3 * var(--unit)) calc(2 * var(--unit));
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: calc(3 * var(--unit)) calc(30 * var(--unit));
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: calc(3 * var(--unit)) calc(40 * var(--unit));
  }
`;

const StyledDivider = styled(Divider)`
  margin: calc(3 * var(--unit)) 0;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: calc(2 * var(--unit));
`;

interface Props<T> {
  initValue?: T[keyof T];
  currentQuestion: QuestionType;
  onNextQuestion: (value: T[keyof T]) => void;
  title: string;
  onPrevQuestion?: (value: T[keyof T] | null) => void;
  prevButtonText?: string;
  showPrevButton?: boolean;
}

const QuestionContainer = <T,>({
  initValue,
  currentQuestion,
  onNextQuestion,
  onPrevQuestion,
  prevButtonText = "Previous Question",
  showPrevButton = true,
  title,
}: Props<T>) => {
  const { componentType } = currentQuestion;
  const [value, setValue] = useState<T[keyof T] | null>(initValue ?? null);
  const [isInvalid, setIsInvalid] = useState<boolean | null>(null);

  const isMobile = useMediaQuery(EBreakpoints.Mobile);

  useEffect(() => {
    setValue(initValue ?? null);
  }, [initValue, currentQuestion]);

  const QuestionComponent = componentType ? getComponent(componentType) : null;

  const isError = !value || isInvalid === true;

  return (
    <>
      <Container variant={isMobile ? undefined : "outlined"}>
        <TitleContainer>
          <Typography color="#1b3b6f" variant="h4" fontWeight={600}>
            {title}
          </Typography>
        </TitleContainer>
        <StyledDivider />
        {QuestionComponent && (
          <QuestionComponent<T>
            question={currentQuestion}
            value={value}
            setValue={setValue}
            setIsInvalid={setIsInvalid}
            error={isError}
          />
        )}
      </Container>
      <FooterContainer elevation={3}>
        <div>
          {showPrevButton && (
            <Button
              size="large"
              onClick={() => {
                if (onPrevQuestion) {
                  onPrevQuestion(value);
                  setIsInvalid(null);
                }
              }}
              startIcon={isMobile ? undefined : <ArrowBackIos />}
              variant="outlined"
              fullWidth
            >
              {prevButtonText}
            </Button>
          )}
        </div>
        <InfoContainer>
          {isError && (
            <Tooltip
              title="Please complete all required fields."
              placement="top-start"
              enterTouchDelay={0}
              size="lg"
              variant="outlined"
              color="warning"
            >
              <ErrorOutlineIcon color="error" fontSize="large" />
            </Tooltip>
          )}
          <Button
            variant="contained"
            size="large"
            disabled={isError}
            onClick={() => {
              if (value) {
                onNextQuestion(value);
                setValue(null);
                setIsInvalid(null);
              }
            }}
            endIcon={isMobile ? undefined : <ArrowForwardIos />}
            fullWidth
          >
            Next Question
          </Button>
        </InfoContainer>
      </FooterContainer>
    </>
  );
};

function getComponent(type: QuestionComponentType) {
  switch (type) {
    case QuestionComponentType.AUTO_COMPLETE:
      return QuestionAutoComplete;
    case QuestionComponentType.CHECKBOX:
      return QuestionCheckbox;
    case QuestionComponentType.DATE:
      return QuestionDate;
    case QuestionComponentType.DROPDOWN:
      return QuestionDropdown;
    case QuestionComponentType.FORM:
      return QuestionForm;
    case QuestionComponentType.TEXT_INPUT:
      return QuestionTextField;
    case QuestionComponentType.NUMBER_INPUT:
      return QuestionNumberInput;
    default:
      return null;
  }
}

export default QuestionContainer;
