import { Card, CardContent, Link, Typography } from "@mui/material";
import { CardType } from "../../../common";

interface Props {
  type: string;
  value: CardType;
  key: number;
}

const HomeCard = ({ type, value }: Props) => {
  return (
    <Card className={type === "home" ? "home-card" : "goal-card"}>
      <CardContent>
        {type === "home" && (
          <div className="home-card-style">
            <img
              className="home-card-style-image"
              src={value.image}
              alt={value.subName}
            />
          </div>
        )}
        {type === "goal" && (
          <img
            src={value.image}
            className="goal-card-style"
            alt={value.subName}
          />
        )}
        <Typography className="card-title">{value.name}</Typography>
        <p className="card-description">{value.description}</p>
        {type === "home" && (
          <Link
            className="home-learn-more"
            href={value.url}
            variant="body2"
            underline="hover"
            target="_blank"
          >
            Learn More...
          </Link>
        )}
      </CardContent>
    </Card>
  );
};

export default HomeCard;
