import Button from "@mui/material/Button";
import React from "react";
import { AppModal } from "../../../common";
import styled from "@emotion/styled";

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

const Content = styled.div`
  padding: calc(2 * var(--unit)) var(--unit);
`;

interface Props {
  modalTitle: string;
  children: any;
  closeModal: () => void;
  onSave: () => void;
  disableButton: boolean;
}

const ItemDetails = ({
  modalTitle,
  children,
  closeModal,
  onSave,
  disableButton,
}: Props) => {
  return (
    <AppModal open={true} handleClose={closeModal} title={modalTitle}>
      <Content>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSave();
            closeModal();
          }}
        >
          {children}
          <Footer>
            <Button variant="outlined" onClick={closeModal}>
              Close
            </Button>
            <Button variant="contained" type="submit" disabled={disableButton}>
              Save
            </Button>
          </Footer>
        </form>
      </Content>
    </AppModal>
  );
};

export default ItemDetails;
