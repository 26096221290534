import { QuestionCheckbox, QuestionDate } from "../../components";
import { ComponentProps } from "../../components/types";
import { useEffect } from "react";
import { Container } from "../const";
import { PersonalType, RedSealCertType } from "../../../../common/types";
import { getErrorProps, isFieldEmpty } from "../../utils";

const RedSealForm = ({
  value: rawValue,
  setValue,
  setIsInvalid,
}: ComponentProps<PersonalType>) => {
  const value = rawValue as RedSealCertType;

  useEffect(() => {
    if (setIsInvalid && value) {
      const isValid = isFormValid(value);

      setIsInvalid(!isValid);
    }
  }, [value]);

  useEffect(() => {
    setValue(reformatForm(value));
  }, [value?.isHolder]);

  const isHolder = value?.isHolder ?? null;

  return (
    <Container>
      <QuestionCheckbox<RedSealCertType>
        value={value?.isHolder ?? null}
        setValue={(isHolder) =>
          setValue({ ...value, isHolder: isHolder as string })
        }
        question={{
          display: "Are you a Red Seal Certificate Holder?",
        }}
        componentVariant="form"
        {...getErrorProps({
          value: value?.isHolder,
        })}
      />
      {isHolder === "Yes" && (
        <QuestionDate<RedSealCertType>
          value={value?.completedDate}
          setValue={(completedDate) => setValue({ ...value, completedDate })}
          question={{
            display: "When did you complete your certificate?",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.completedDate,
          })}
        />
      )}
    </Container>
  );

  function isFormValid(value: RedSealCertType) {
    if (!isHolder) {
      return false;
    }

    if (isHolder === "Yes") {
      return !isFieldEmpty(value?.completedDate);
    } else {
      return true;
    }
  }

  function reformatForm(form: RedSealCertType) {
    if (form && isHolder) {
      const { isHolder } = form;
      if (isHolder === "Yes") {
        return form;
      } else {
        return { isHolder } as RedSealCertType;
      }
    }

    return form;
  }
};

export default RedSealForm;
