import { doGet, doPost, doPut } from "../helpers/request-helper";
import { TradeSkill } from "../common";

const baseURL = "api/trade-skills";

const addTradeSkill = async (
  tradeSkill: Omit<TradeSkill, "state"> & { stateId: number },
): Promise<TradeSkill> => {
  return doPost(baseURL, tradeSkill);
};

const getAll = async (): Promise<TradeSkill[]> => {
  return doGet(baseURL);
};

const getTradeByState = async (stateId: string): Promise<TradeSkill[]> => {
  return doGet(baseURL + "/state/" + stateId);
};

const updateTradeSkill = async (
  tradeSkill: Omit<TradeSkill, "state"> & { stateId: number },
): Promise<TradeSkill> => {
  return doPut(baseURL, tradeSkill);
};

export const tradeSkillService = {
  addTradeSkill,
  getAll,
  getTradeByState,
  updateTradeSkill,
};
