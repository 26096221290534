import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AppModal } from "../../../../../common";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { ProgramType } from "../../../../../common/types";
import {
  QuestionDate,
  QuestionDropdown,
  QuestionTextField,
} from "../../../components";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getErrorProps, isFieldEmpty } from "../../../utils";

const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 0 var(--unit);
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: calc(2 * var(--unit));
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: calc(3 * var(--unit));
`;

const ProgramCompletedModal = ({
  degreeOptions,
  handleCloseModal,
  onSave,
  variant,
  initialValue,
}: {
  degreeOptions: string[];
  handleCloseModal: () => void;
  onSave: (value: ProgramType) => void;
  variant: "Add" | "Edit";
  initialValue: ProgramType;
}) => {
  const [value, setValue] = useState<ProgramType>(initialValue);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isOngoing, setIsOngoing] = useState<boolean>(
    variant === "Add"
      ? false
      : initialValue
        ? initialValue.dateCompleted === null
        : false,
  );

  const dateStarted = value?.dateStarted ?? null;
  const dateCompleted = value?.dateCompleted ?? null;

  useEffect(() => {
    if (dateStarted && dateCompleted) {
      if (DateTime.fromISO(dateCompleted) < DateTime.fromISO(dateStarted)) {
        setValue({ ...value, dateCompleted: dateStarted });
      }
    }
  }, [dateStarted]);

  useEffect(() => {
    if (value) {
      const isValid = isFormValid(value);

      setIsValid(isValid);
    }
  }, [value, isOngoing]);

  return (
    <AppModal
      open={true}
      handleClose={handleCloseModal}
      title={`${variant} Degree`}
    >
      <>
        <QuestionDropdown<ProgramType>
          value={value?.degree ?? "-1"}
          setValue={(degree) => setValue({ ...value, degree })}
          question={{
            display: "Degree Type",
            meta: {
              options: degreeOptions.map((option) => ({
                label: option,
                value: option,
              })),
            },
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.degree,
          })}
        />
        <QuestionTextField<ProgramType>
          value={value?.program}
          setValue={(program) => setValue({ ...value, program })}
          question={{
            display: "Program/Course Name",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.program,
          })}
        />
        <QuestionTextField<ProgramType>
          value={value?.school}
          setValue={(school) => setValue({ ...value, school })}
          question={{
            display: "School Name",
          }}
          componentVariant="form"
          {...getErrorProps({
            value: value?.school,
          })}
        />
        <DateContainer>
          <QuestionDate<ProgramType>
            value={value?.dateStarted}
            setValue={(dateStarted) =>
              setValue({
                ...value,
                dateStarted,
              })
            }
            question={{
              display: "Date Started",
            }}
            componentVariant="form"
            {...getErrorProps({
              value: value?.dateStarted,
            })}
          />
          <StyledFormControlLabel
            value="end"
            control={
              <Checkbox
                checked={isOngoing}
                onChange={() => setIsOngoing(!isOngoing)}
              />
            }
            label="I am currently enrolled in this degree program"
            labelPlacement="end"
          />
          {!isOngoing && (
            <QuestionDate<ProgramType>
              value={value?.dateCompleted}
              setValue={(dateCompleted) =>
                setValue({
                  ...value,
                  dateCompleted,
                })
              }
              question={{
                display: "Date Completed",
              }}
              componentVariant="form"
              minDate={dateStarted ? DateTime.fromISO(dateStarted) : undefined}
              maxDate={DateTime.now()}
              {...getErrorProps({
                value: value?.dateCompleted,
              })}
            />
          )}
        </DateContainer>
      </>
      <Footer>
        <Button variant="outlined" onClick={handleCloseModal} size="large">
          Close
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={!isValid}
          onClick={() => {
            onSave({
              ...value,
              dateCompleted: isOngoing ? null : value.dateCompleted,
            });
            handleCloseModal();
            setValue(initialValue);
          }}
          size="large"
        >
          {variant}
        </Button>
      </Footer>
    </AppModal>
  );

  function isFormValid(value: ProgramType) {
    return (
      (value?.degree?.trim() ?? "") !== "-1" &&
      !isFieldEmpty(value?.program) &&
      !isFieldEmpty(value?.school) &&
      dateStarted !== "" &&
      dateStarted !== null &&
      (isOngoing || (dateCompleted !== "" && dateCompleted !== null))
    );
  }
};

export default ProgramCompletedModal;
