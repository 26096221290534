import React, { useEffect, useState } from "react";
import { Applicant, AppTable, TableColumn } from "../../../../common";
import { applicantService } from "../../../../services/applicantService";
import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";
import { DateTime } from "luxon";
import ApplicantDetail from "./ApplicantDetail";
import { ApplicantInformationType } from "../../../../common/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

const TableContainer = styled.div`
  height: calc(100vh - 150px);
  margin-top: var(--unit);
`;

const COLUMNS: TableColumn[] = [
  { key: "email", label: "Email" },
  { key: "fullName", label: "Full Name" },
  { key: "inTradeSkill", label: "In trade Skill" },
  { key: "currentCountry", label: "Current Country" },
  { key: "age", label: "Age" },
];

const Applicants = () => {
  const [applicants, setApplicants] = useState<Applicant[]>();
  const [selectedApplicant, setSelectedApplicant] = useState<Applicant>();

  useEffect(() => {
    fetchApplicants();
  }, []);

  return (
    <Container>
      {selectedApplicant && (
        <ApplicantDetail
          applicant={selectedApplicant}
          closeModal={() => setSelectedApplicant(undefined)}
        />
      )}
      <TableContainer>
        <AppTable<Applicant>
          columns={COLUMNS}
          data={applicants}
          onRowRender={(row) => {
            const { id, email, surveyAnswer } = row;
            return (
              <React.Fragment key={id}>
                <TableCell>{email}</TableCell>
                <TableCell>
                  {getFullName(surveyAnswer.accountInformation.information)}
                </TableCell>
                <TableCell>{surveyAnswer.personal.inTradeSkill}</TableCell>
                <TableCell>{surveyAnswer.personal.currentCountry}</TableCell>
                <TableCell>
                  {computeAge(surveyAnswer.personal.birthDate)}
                </TableCell>
              </React.Fragment>
            );
          }}
          onRowClick={(row) => setSelectedApplicant(row)}
        />
      </TableContainer>
    </Container>
  );

  function fetchApplicants() {
    applicantService.getAll().then(setApplicants);
  }
};

function getFullName(applicantInfo: ApplicantInformationType) {
  const { firstName, lastName, middleName } = applicantInfo;
  return `${lastName}, ${firstName} ${middleName ?? ""}`;
}

function computeAge(birthDateStr: string) {
  const birthDate = DateTime.fromISO(birthDateStr);
  const now = DateTime.now();
  const age = now.diff(birthDate, "years").years;

  return Math.floor(age);
}

export default Applicants;
