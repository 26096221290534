import React, { useState } from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import ProgramCompletedModal from "./ProgramCompletedModal";
import { ProgramType } from "../../../../../common/types";
import { ErrorText } from "../../../../../common";

const Container = styled.div`
  display: flex;
  column-gap: var(--unit);
`;

const AddDegreeButton = styled(Button)`
  margin: calc(2 * var(--unit)) auto auto auto;
`;

const initialValue = {
  degree: "-1",
  program: null,
  school: null,
  dateStarted: null,
  dateCompleted: null,
};

interface Props {
  value: ProgramType[];
  setValue: (value: ProgramType[]) => void;
  degreeOptions: string[];
}

const DegreeProgram = ({
  value: degrees,
  setValue: setDegrees,
  degreeOptions,
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const addDegreeProgram = (addedValue: ProgramType) => {
    setDegrees([...degrees, addedValue]);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div>
      <Container>
        <AddDegreeButton
          onClick={handleOpenModal}
          size="large"
          variant="contained"
        >
          Add degree
        </AddDegreeButton>
        {openModal && (
          <ProgramCompletedModal
            initialValue={initialValue}
            handleCloseModal={handleCloseModal}
            degreeOptions={degreeOptions}
            variant="Add"
            onSave={addDegreeProgram}
          />
        )}
      </Container>
      {degrees.length === 0 && (
        <ErrorText helperText="Add atleast one degree program." />
      )}
    </div>
  );
};

export default DegreeProgram;
