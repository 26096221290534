import { useAlert, useLoading, User } from "../../../../common";
import React, { useState } from "react";
import ItemDetails from "../../common/ItemDetails";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { isValidEmail } from "./utils";
import { userService } from "../../../../services";
import { setDateToCurrentTimezone } from "../../../../common/utils";

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: var(--unit);
`;

interface Props {
  user: User;
  closeModal: () => void;
  fetchUsers: () => void;
}

const UserDetail = ({ user, closeModal, fetchUsers }: Props) => {
  const {
    id,
    firstName: initFirstName,
    lastName: initLastName,
    email: initEmail,
    createdAt,
    updatedAt,
  } = user;

  const [firstName, setFirstName] = useState<string>(initFirstName);
  const [lastName, setLastName] = useState<string>(initLastName);
  const [email, setEmail] = useState<string>(initEmail);

  const { setShowLoading } = useLoading();
  const { setAlert } = useAlert();

  return (
    <ItemDetails
      modalTitle="User Details"
      closeModal={closeModal}
      onSave={onSave}
      disableButton={!isValid()}
    >
      <FormContent>
        <FormControl>
          <Typography>First Name</Typography>
          <TextField
            size="small"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Typography>Last Name</Typography>
          <TextField
            size="small"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Typography>Email</Typography>
          <TextField
            size="small"
            fullWidth
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={initEmail === localStorage.getItem("currentUser")}
          />
        </FormControl>
        <FormControl>
          <Typography>Created at</Typography>
          <Typography>
            {createdAt ? setDateToCurrentTimezone(createdAt) : "-"}
          </Typography>
        </FormControl>
        <FormControl>
          <Typography>Updated at</Typography>
          <Typography>
            {updatedAt ? setDateToCurrentTimezone(updatedAt) : "-"}
          </Typography>
        </FormControl>
      </FormContent>
    </ItemDetails>
  );

  function isValid() {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      isValidEmail(email)
    );
  }

  function clearAll() {
    setFirstName("");
    setLastName("");
    setEmail("");
  }

  function onSave() {
    setShowLoading(true);
    const user = {
      id,
      firstName,
      lastName,
      email,
      password: "password",
    };
    userService
      .updateUser(user)
      .then(() => {
        fetchUsers();
        clearAll();
        setAlert({ message: "User Updated!", type: "success" });
      })
      .catch(() =>
        setAlert({ message: "Email already exists!", type: "error" }),
      )
      .finally(() => setShowLoading(false));
  }
};

export default UserDetail;
