import { SurveyType } from "../types";

export const initialSurveyForm: SurveyType = {
  accountInformation: {
    lookingForJob: null,
    information: {
      firstName: "",
      middleName: null,
      lastName: "",
      citizenship: "-1",
      phoneNumber: "",
      email: "",
      confirmEmail: "",
    },
    confirmedEmail: "",
  },
  personal: {
    inTradeSkill: null,
    currentCountry: null,
    canadaProvince: null,
    tradeSkill: null,
    redSealCert: null,
    statusInCanada: null,
    skillCountryTrade: {
      tradeSkill: "",
      specified: null,
    },
    tradeCertification: null,
    professionalLicensing: null,
    wantToBeAssessedForPr: null,
    activeContactNumber: null,
    birthDate: "",
    currentAddress: {
      address1: "",
      address2: null,
      city: "",
      state: "",
      postal: "",
    },
    maritalStatus: {
      status: "",
      separationProof: null,
      isPartnerInCanada: null,
    },
  },
  education: {
    programGraduated: null,
    highestEducationAttainment: null,
    cwpStudy: null,
    requestedEducationalCredential: null,
  },
  language: {
    haveTakenEnglish: null,
  },
  workExperience: {
    experiences: [],
  },
  adaptability: {
    jobOfferLmia: null,
    spouseJobOfferLmia: null,
    spouseHaveRelativeInCanada: null,
  },
};
